const withAddToGoogleCalender = (props) => {
  return function EnhancedComponent({ className }) {
    const { title, startTimeISO, WrapperComponent } = props;

    function formatISOToGoogleCalendar(isoString) {
      const d = new Date(isoString);
      return d.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
    }

    function generateGoogleCalendarURL({ title, startTimeISO, endTimeISO }) {
      const baseUrl = "https://www.google.com/calendar/render?action=TEMPLATE";

      const params = new URLSearchParams();
      params.append("text", title);
      params.append(
        "dates",
        `${formatISOToGoogleCalendar(startTimeISO)}/${formatISOToGoogleCalendar(
          endTimeISO
        )}`
      );
      params.append("details", "Live Event At Coding Shuttle");
      params.append("location", window.location.host);
      params.append("trp", true);
      params.append("sf", false);
      params.append("sprop", `name:Coding Shuttle`);
      params.append("sprop", `website:${window.location.host}`);

      return `${baseUrl}&${params.toString()}`;
    }

    function handleClick() {
      const startTime = new Date(startTimeISO);
      const endTime = new Date(startTimeISO);
      endTime.setHours(startTime.getHours() + 2);
      const endTimeISO = endTime.toISOString();
      window
        .open(
          generateGoogleCalendarURL({ title, startTimeISO, endTimeISO }),
          "_blank"
        )
        .focus();
    }

    return (
      <WrapperComponent
        className={className}
        onAddToCalenderButtonClick={handleClick}
      />
    );
  };
};

export default withAddToGoogleCalender;
