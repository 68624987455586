export const tableHeader = [
  {
    label: "ID",
    dataIndex: "id",
    key: "id",
    id: 1,
  },
  {
    label: "Name",
    dataIndex: "name",
    key: "name",
    id: 2,
    withImage: true,
  },
  {
    label: "referral Code",
    dataIndex: "referalCode",
    key: "referalCode",
    id: 3,
  },
  {
    label: "Status",
    dataIndex: "status",
    key: "status",
    id: 4,
  },
  {
    label: "Created At",
    id: 5,
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    label: "More",
    dataIndex: "action",
    id: 6,
  },
];

export const dropdownDataset = [
  {
    id: 1,
    index: "name",
    title: "Status",
    label: "referralStatus",
    options: null,
  },
  {
    id: 2,
    title: "Time period",
    isDate: true,
    filterBasis: "timePeriod",
    options: [
      {
        id: "startDate#1",
        label: "From :",
        name: "startDate",
        dataLabel: "timePeriod",
      },
      {
        id: "endDate#1",
        label: "To :",
        name: "endDate",
        dataLabel: "timePeriod",
      },
    ],
  },
];

export const actionModelData = [
  {
    id: 1,
    label: "Refferee Details",
    onClick: (setState, key) =>
      setState((prev) => {
        return { ...prev, [key]: true };
      }),
    key: "userModel",
  },
  {
    id: 2,
    label: "Edit Status",
    onClick: (setState, key) =>
      setState((prev) => {
        return { ...prev, [key]: true };
      }),
    key: "statusModel",
  },
];

export const dateFormatOptions = {
  weekday: "short",
  day: "numeric",
  year: "numeric",
  hour: "2-digit",
  hour12: true,
  minute: "2-digit",
  month: "long",
};
