import React from "react";
import { Title } from "../../user-profile/imports";
import practiceModeImage from "../../../img/practicemode.png";
const ContestPracticeModeBanner = () => {
  return (
    <div className="items-center justify-center min-h-[40px] gap-4 mt-8 mb-12 rounded bg-primium-400 t-flex">
      <div className="relative h-full w-28 shrink-0">
        <div className="absolute left-0 z-0 -top-12">
          <img
            className="w-28 max-w-none drop-shadow-[0px_0px_7px_rgba(255,_220,_39,_0.5)]"
            src={practiceModeImage}
            alt="practice mode"
          />
        </div>
      </div>
      <div className="ml-50">
        <Title size="md" weight="semibold" className="text-neutral-950">
          This contest is running in Practice Mode and the Submissions will not
          affect the Leaderboard.
        </Title>
      </div>
    </div>
  );
};

export default ContestPracticeModeBanner;
