import React from "react";
import { Link, useLocation } from "react-router-dom";
import IconWrapper from "../components/ui/IconWrapper";
import { InfoTooltip } from "../components/ui/Tooltip";
import { cn } from "../helpers/utils";

export const MiniSidebarPanelLink = ({ tab }) => {
  const { pathname } = useLocation();
  return (
    <InfoTooltip placement="right" key={tab.id} tooltipText={tab.text}>
      <Link
        to={tab.href}
        target={tab.target}
        aria-disabled={tab.isLocked}
        className={cn(
          "inline-block py-2 relative aria-disabled:pointer-events-none aria-disabled:cursor-auto px-2.5 rounded group w-max [&.active]:bg-primary-500",
          `[&:not(.active)]:hover:bg-navbar-exception-hover [&.active]:pointer-events-none ${
            pathname.includes(tab.activeHref) ? "active" : ""
          }`
        )}
      >
        <IconWrapper
          icon={tab.icon}
          size="25"
          strokeWidth="1.5"
          className={cn(
            `group-[.active]:stroke-light text-primaryText`,
            tab.isLocked ? "opacity-20" : ""
          )}
        />
        {tab.isLocked && (
          <IconWrapper
            icon="locked"
            size="13"
            className="absolute right-1 top-2 text-danger-400"
          />
        )}
      </Link>
    </InfoTooltip>
  );
};
const MiniSidebar = (props) => {
  const { sidebarConfig, toggleSidebar } = props;
  return (
    <div className="flex-col w-[61px] gap-8 py-4 t-flex">
      <div className="items-center justify-center t-flex">
        <button onClick={() => toggleSidebar(true)}>
          <IconWrapper icon={"menu"} size={25} className={"text-primaryText"} />
        </button>
      </div>
      <div className="flex-col gap-4 p-2 t-flex">
        {sidebarConfig.length > 0 &&
          sidebarConfig.map((data, i) => (
            <div key={data.id}>
              {i !== 0 && (
                <div className="mb-4 border-t border-border-500"></div>
              )}
              <div className="flex-col gap-2 t-flex">
                {data?.tabs?.map((tab) => (
                  <MiniSidebarPanelLink tab={tab} key={tab.id} />
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MiniSidebar;
