import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUserEnrolledBatches } from "../../../../redux/slices/adminSlice";
import { axiosClient } from "../../../../utils/axiosClient";
import { toast } from "react-hot-toast";

export function useFetchEnrolledBatches(userId) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserEnrolledBatches({ userId: userId }));
  }, []);
}
export async function enrollUserInBatch(setLoading, body) {
  try {
    setLoading(true);
    const { status } = await axiosClient.post("/admin/enroll", body);
    return status;
  } catch (error) {
    return toast.error(error.message);
  } finally {
    setLoading(false);
  }
}
