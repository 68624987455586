import { cva } from "class-variance-authority";
import React from "react";
import { cn } from "../../helpers/utils";

const spinnerVariants = cva(
  [
    "border-2 border-borderColor border-r-primary-500 block rounded-full animate-spinner",
  ],
  {
    variants: {
      variant: {},
      size: {
        sm: ["w-5 h-5"],
      },
    },
  }
);
const SpinnerLoader = (props) => {
  const { size, className, ...restProps } = props;
  const DEFAULT_LOADER_SIZE = 18;
  return (
    <span
      {...restProps}
      className={cn(spinnerVariants(), className)}
      style={{
        width: `${size || DEFAULT_LOADER_SIZE}px`,
        height: `${size || DEFAULT_LOADER_SIZE}px`,
      }}
    ></span>
  );
};
export const ExpendLoader = () => {
  return (
    <div className="items-center justify-center gap-2 t-flex">
      <div className="w-3 h-3 [animation-delay:.3s] bg-blue-600 dark:bg-blue-500 rounded-full animate-bounce"></div>
      <div className="w-3 h-3 [animation-delay:.5s] bg-blue-600 dark:bg-blue-500 rounded-full animate-bounce"></div>
      <div className="w-3 h-3 [animation-delay:.7s] bg-blue-600 dark:bg-blue-500 rounded-full animate-bounce"></div>
    </div>
  );
};
export default SpinnerLoader;
