import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchAllUsers } from "../../../../redux/slices/adminSlice";
import IconButton from "../../components/button/Button";
import { CHAT_STATUS } from "../utils/constantData";

export function restructureData(data, totalEntries) {
  if (data && data.length > 0) {
    const restructureUserData = [];
    data.forEach((user) => {
      const userDetails = {
        id: user.id,
        name: user.name,
        photo: user.photo,
        email: user.email,
        chatStatus: (
          <span
            className={`status__tag ${CHAT_STATUS[user.isLiveChatBlocked]}`}
          >
            {CHAT_STATUS[user.isLiveChatBlocked]}
          </span>
        ),
        isChatBlocked: user.isLiveChatBlocked,
      };
      restructureUserData.push({
        ...userDetails,
        action: <IconButton user={userDetails} />,
      });
    });
    data = { restructureUserData, totalEntries: totalEntries };
  }
  return data;
}
let TIMEOUT_ID;
function useUserData() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (searchParams.get("page") || searchParams.get("search")) {
      TIMEOUT_ID = setTimeout(() => {
        dispatch(
          fetchAllUsers({
            queries: location.search,
          })
        );
      }, 1000);
    } else {
      dispatch(fetchAllUsers({}));
    }
    return () => clearTimeout(TIMEOUT_ID);
  }, [searchParams.get("page"), searchParams.get("search")]);
}

export default useUserData;
