import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import OtpInput from "react-otp-input";
import { EditProfileInput } from "../../pages/settings/components/EditProfileInput";
import { cn } from "../classroomHeader/imports";
import {
  CTAButton,
  Description,
  IconWrapper,
  Text,
  Title,
} from "../common/import";
import { PHONE_NUMBER_INPUT_SCREEN } from "./PhoneVerification";
import { sendOtp, verifyOtp } from "./onboarding";
import { setIsPhoneVerified } from "../../redux/slices/appConfigSlice";
import { useDispatch } from "react-redux";

const RESEND_OTP_COOLDOWN = 120; // 2 min
const OTP_LENGTH = 6;
const ResendOtpCountdown = ({ setIsResendOtpCooldown }) => {
  const [resendCooldown, setResendCooldown] = useState(RESEND_OTP_COOLDOWN);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setResendCooldown((prev) => {
        if (prev === 0) {
          clearInterval(intervalId);
          return 0;
        } else {
          return prev - 1;
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (resendCooldown === 0) {
      setIsResendOtpCooldown(true);
    }
  }, [resendCooldown]);
  const minutes = Math.floor(resendCooldown / 60);
  const seconds = resendCooldown % 60;
  return (
    <Text size="sm" weight="lg">{`0${minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`}</Text>
  );
};

const OTPScreen = ({
  otp,
  setOtp,
  setShowScreen,
  countryCode,
  phoneNumber,
  setIsDialogOpen,
  onPhoneVerifiedSuccess,
}) => {
  const dispatch = useDispatch();
  const [isResendOtpCooldown, setIsResendOtpCooldown] = useState(false);
  const [isOtpVerifyLoading, setIsOtpVerifyLoading] = useState(false);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  function backHandler() {
    setOtp("");
    setShowScreen(PHONE_NUMBER_INPUT_SCREEN);
  }

  // resend otp
  async function resendOtpHandler() {
    try {
      setIsResendOtpCooldown(true);
      setResendOtpLoading(true);
      await sendOtp(countryCode, phoneNumber);
      toast.success(`OTP resent on ${countryCode}${phoneNumber}`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsResendOtpCooldown(false);
      setResendOtpLoading(false);
    }
  }

  // verify otp
  async function verifyOtpHandler() {
    try {
      setIsOtpVerifyLoading(true);
      const { data } = await verifyOtp(otp);
      if (data.verified) {
        toast.success("Phone number verified successfully");
        dispatch(setIsPhoneVerified());
        if (onPhoneVerifiedSuccess) {
          onPhoneVerifiedSuccess();
        }
        setIsDialogOpen(false);
      } else {
        toast.error(
          "Your OTP didn't match. Please try again or request a new one."
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsOtpVerifyLoading(false);
    }
  }
  return (
    <div className="flex-col justify-between h-full t-flex">
      <div>
        <button
          className="items-center gap-1 mb-3 text-sm text-secondaryText t-flex"
          onClick={backHandler}
        >
          <IconWrapper icon="back" size="20" className="text-secondaryText" />
          Back
        </button>
        <div className="mb-2">
          <Title level="h5" size="md">
            {`Enter OTP sent to ${countryCode}${phoneNumber}`}
          </Title>
        </div>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          shouldAutoFocus
          skipDefaultStyles
          renderInput={({ className, type, ...props }) => (
            <EditProfileInput
              type="number"
              className="w-12 h-12 text-xl text-center [&:not(:last-child)]:mr-2 bg-neutral-100 border-border-500"
              {...props}
            />
          )}
        />
        <div className="mt-3">
          <div className="items-center gap-1 t-flex">
            <Description size="sm">
              {isResendOtpCooldown ? "Resend OTP on :" : "Resend OTP in"}
            </Description>
            {!isResendOtpCooldown && (
              <ResendOtpCountdown
                setIsResendOtpCooldown={setIsResendOtpCooldown}
              />
            )}
          </div>
          <div className="mt-2">
            <button
              disabled={
                !isResendOtpCooldown || resendOtpLoading || isOtpVerifyLoading
              }
              onClick={resendOtpHandler}
              className={cn(
                "items-center gap-1 group disabled:text-secondaryText text-sm font-semibold tracking-wider t-flex",
                isResendOtpCooldown
                  ? "text-blue-500"
                  : "text-secondaryText opacity-60"
              )}
            >
              <IconWrapper
                icon="phone"
                size="20"
                className={cn(
                  "group-disabled:text-secondaryText",
                  isResendOtpCooldown && resendOtpLoading
                    ? "text-secondaryText"
                    : "text-blue-500"
                )}
              />{" "}
              SMS
            </button>
          </div>
        </div>
      </div>
      <CTAButton
        isLoading={isOtpVerifyLoading}
        disabled={isOtpVerifyLoading || otp.length !== OTP_LENGTH}
        onClick={verifyOtpHandler}
        variant="primary"
        icon="dropRight"
        loaderSize="20"
        size="lg"
        className="flex-row-reverse w-full gap-1 py-3 rounded-lg"
      >
        Continue
      </CTAButton>
    </div>
  );
};

export default OTPScreen;
