import React, { forwardRef } from "react";
import { cn } from "../../../components/job-portal/helpers/utils";

export const EditProfileLabel = (props) => {
  const { children, className } = props;
  return (
    <label
      {...props}
      className={cn("text-sm font-medium text-primaryText", className)}
    >
      {children}
    </label>
  );
};
export const EditProfileInput = forwardRef((props, ref) => {
  const { className } = props;
  return (
    <input
      {...props}
      ref={ref}
      spellCheck="false"
      autoComplete="off"
      className={cn(
        "px-2 w-full disabled:text-secondaryText py-2.5 text-sm font-medium  rounded outline-none appearance-none  border text-primaryText placeholder:neutral-400 placeholder:font-normal border-neutral-300 focus:ring-primary-500 focus:ring-2 focus:border-transparent",
        className
      )}
    />
  );
});
