import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useIsWindowBelowWidth } from "../../../helper/useScreenType";
import ErrorBoundaryFallback from "../../ErrorBoundaryFallback";
import Header from "../../classroomHeader/Header";
import { BATCH_TYPE_SELF_PACED } from "../../common/constant";
import PhoneVerificationDialog from "../../onboarding/PhoneVerificationDialog";
import { DialogModal } from "../components/ui/Modal";
import useSidebarConfig from "../hooks/useSidebarConfig";
import MiniSidebar from "./MiniSidebar";
import Sidebar from "./Sidebar";
import "./classroom.tailwind.scss";

export const HEADER_HEIGHT = 58;
export const MINI_SIDE_PANEL_WINDOW_WIDTH = 1536;
export const HIDE_SIDE_PANEL_WINDOW_WIDTH = 768;
const ClassroomLayout = () => {
  const sidebarConfig = useSidebarConfig();
  const isWindowBelowWidth = useIsWindowBelowWidth(
    MINI_SIDE_PANEL_WINDOW_WIDTH
  );
  const hideSidePanel = useIsWindowBelowWidth(HIDE_SIDE_PANEL_WINDOW_WIDTH);
  const location = useLocation();
  const { offeringSlug, liveSlug, eventSlug } = useParams();
  const [isSidebarPanelCollapsed, setIsSidebarPanelCollapsed] = useState(false);
  const [isSidebarPanelOverlay, setIsSidebarPanelOverlay] = useState(false);
  const SIDEBAR_RENDER_CONDITION =
    offeringSlug ||
    liveSlug ||
    eventSlug ||
    location.pathname.includes("/2023-wrapped");
  const { data } = useSelector((state) => state.batchConfigReducer);
  const user = useSelector(
    (state) => state.persistedReducer?.appConfigReducer?.user
  );
  const appConfigStatus = useSelector(
    (state) => state.persistedReducer?.appConfigReducer?.status
  );
  function toggleSidebarHandler(activeState) {
    setIsSidebarPanelOverlay(activeState);
  }
  return (
    <div className={`t-flex items-start justify-start min-h-screen `}>
      {!isWindowBelowWidth && !SIDEBAR_RENDER_CONDITION && (
        <div className="sticky top-0 h-screen border-r bg-navbar-exception border-border-500 ">
          <Sidebar
            isSidebarPanelCollapsed={isSidebarPanelCollapsed}
            setIsSidebarPanelCollapsed={setIsSidebarPanelCollapsed}
            sidebarConfig={sidebarConfig}
            toggleSidebar={toggleSidebarHandler}
            SIDEBAR_RENDER_CONDITION={SIDEBAR_RENDER_CONDITION}
          />
        </div>
      )}

      {isWindowBelowWidth && !SIDEBAR_RENDER_CONDITION && !hideSidePanel && (
        <div className="sticky top-0 h-screen bg-navbar-exception">
          <MiniSidebar
            sidebarConfig={sidebarConfig}
            toggleSidebar={toggleSidebarHandler}
          />
        </div>
      )}
      <DialogModal
        type="sidebar"
        isOpen={isSidebarPanelOverlay}
        setIsOpen={setIsSidebarPanelOverlay}
        hasTheme
      >
        <div className="h-screen bg-navbar-exception">
          <Sidebar
            toggleOverlaySidebar={toggleSidebarHandler}
            isOverlayNavbar
            sidebarConfig={sidebarConfig}
            showSidebarPanelOverlayButton={isWindowBelowWidth}
          />
        </div>
      </DialogModal>
      <div className="w-full min-h-screen bg-neutral-100">
        <Header
          isSidebarPanelCollapsed={isSidebarPanelCollapsed}
          toggleSidebar={toggleSidebarHandler}
          SIDEBAR_RENDER_CONDITION={SIDEBAR_RENDER_CONDITION}
          hideSidePanel={hideSidePanel}
        />
        <div className="t-flex">
          <div className="w-full">
            <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
      {appConfigStatus === "idle" &&
        user &&
        !user.isPhoneVerified &&
        !location.pathname.includes("/lecture") &&
        data &&
        data.batch.type !== BATCH_TYPE_SELF_PACED &&
        !eventSlug && <PhoneVerificationDialog />}
    </div>
  );
};

export default ClassroomLayout;
