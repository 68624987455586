import React, { useRef, useState } from "react";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { tooglePopupState } from "../../hooks/usePopupHandler";
import PopupLayout from "../../layout/PopupLayout";
import { dropdownValueChangeHandler } from "../../user/hooks/helper";
import "./dropdown.scss";
const DropdownOption = ({ id, name, onClick }) => {
  return (
    <li
      role="menuitem"
      key={id}
      onClick={onClick}
      className="capitalize admin__panel__primary__text"
    >
      {name}
    </li>
  );
};
const Dropdown = (props) => {
  const { dropdownValue, setDropdownValue, label, data } = props;
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);
  return (
    <div className="flex input__wrapper">
      <label className="admin__panel__primary__text">{label}</label>
      <div className="select__wrapper" ref={dropdownRef}>
        <button
          className="flex"
          onClick={() => tooglePopupState(setIsActive, isActive)}
        >
          <p className="admin__panel__primary__text">
            {dropdownValue.name || dropdownValue}
          </p>
          <MdOutlineArrowDropDown
            className={`${isActive ? "active-icon" : ""}`}
          />
        </button>
        <PopupLayout
          setIsPopupActive={setIsActive}
          isPopupActive={isActive}
          ref={dropdownRef}
        >
          <div className="select__options__wrapper">
            <ul className="option__wrapper">
              {data.length > 0 &&
                data.map((item) => (
                  <DropdownOption
                    key={item.key ? item.key : item.id}
                    {...item}
                    onClick={() =>
                      dropdownValueChangeHandler(
                        setDropdownValue,
                        setIsActive,
                        item
                      )
                    }
                  />
                ))}
            </ul>
          </div>
        </PopupLayout>
      </div>
    </div>
  );
};

export default Dropdown;
