import React, { useState } from "react";
import phoneVerificationIllustration from "../../img/phone-verification.webp";
import { Description, Text, Title } from "../common/import";
import OTPScreen from "./OTPScreen";
import PhoneNumberInputScreen from "./PhoneNumberInputScreen";

export const PHONE_NUMBER_INPUT_SCREEN = 1;
export const OTP_INPUT_SCREEN = 2;
const DEFAULT_COUNTRY_CODE = "+91";
const DEFAULT_PHONE_MAXLENGTH = 10;

const PhoneVerification = ({ setIsDialogOpen, onPhoneVerifiedSuccess }) => {
  const [showScreen, setShowScreen] = useState(PHONE_NUMBER_INPUT_SCREEN);
  const [otp, setOtp] = useState("");
  const [inputValue, setInputValue] = useState({
    phoneNumber: "",
    countryCode: {
      maxPhoneLength: DEFAULT_PHONE_MAXLENGTH,
      value: DEFAULT_COUNTRY_CODE,
    },
    error: null,
  });

  return (
    <div className="w-full p-6 border rounded-lg shadow-lg bg-neutral-100 border-border-500">
      <div className="flex-col items-center justify-center gap-1 mb-8 t-flex max-md:mb-6">
        <Title level="h3" size="lg">
          Phone Verification
        </Title>
        <Description size="sm" className="max-md:text-sm">
          Please verify your phone number to continue with your course
        </Description>
      </div>
      <div className="justify-between t-flex">
        <div className="basis-2/5 max-md:hidden">
          <img
            width={260}
            src={phoneVerificationIllustration}
            alt="phone verification illustration"
          />
        </div>
        <div className="w-[1px] mx-6 bg-border-300  max-md:hidden"></div>
        <div className="flex-col justify-between min-h-[310px] max-md:min-h-[280px] max-md:basis-full basis-1/2 t-flex">
          {showScreen === OTP_INPUT_SCREEN && (
            <OTPScreen
              otp={otp}
              onPhoneVerifiedSuccess={onPhoneVerifiedSuccess}
              setOtp={setOtp}
              setShowScreen={setShowScreen}
              countryCode={inputValue.countryCode.value}
              phoneNumber={inputValue.phoneNumber}
              setIsDialogOpen={setIsDialogOpen}
            />
          )}

          {showScreen === PHONE_NUMBER_INPUT_SCREEN && (
            <PhoneNumberInputScreen
              setShowScreen={setShowScreen}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          )}

          <div className="mt-3">
            <Text className="" size="sm" weight="md">
              Having trouble?&nbsp;
              <a
                href="mailto:contact@codingshuttle.com"
                className="font-semibold text-blue-500 underline transition-opacity underline-offset-2 hover:opacity-85"
              >
                Contact Us
              </a>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneVerification;
