import { Link } from "react-router-dom";
import Badge, { UserBadge } from "../../job-portal/components/ui/Badges";
import Skeleton from "../../job-portal/components/ui/Skeleton";
import { Description } from "../imports";

export function restructureLeaderboardData(leaderboardData = [], currentTab) {
  const restructuredData = [];
  for (let i = 0; i < leaderboardData.length; i++) {
    const {
      id,
      user: { graduationYear, name, photo, username },
    } = leaderboardData[i];
    const scoreKey = currentTab?.toLowerCase() + "Score";
    const rankKey = currentTab?.toLowerCase() + "Rank";
    const score = leaderboardData[i][scoreKey];
    const rank = leaderboardData[i][rankKey];
    const data = {
      id: i === 0 ? -1 : id,
      rank: (
        <div>
          <Badge
            size="xs"
            weight="semibold"
            variant="secondary"
            className="px-3 bg-neutral-400 w-max "
            border="rounded"
          >
            {rank}
          </Badge>
        </div>
      ),
      user: (
        <div>
          <Link to={`/profile/${username}`} className="group w-max">
            <UserBadge
              extraNode={
                <Description
                  className="group-hover:text-primary-500"
                  variant="primary"
                  size="xs"
                  weight="md"
                >{`@${username}`}</Description>
              }
              title={name}
              size="sm"
              weight="md"
              imageProps={{
                url: photo,
                variant: "circle",
                size: "md",
              }}
            />
          </Link>
        </div>
      ),
      score: (
        <div>
          <Badge
            size="xs"
            weight="semibold"
            variant="secondary"
            className="border-success-700 text-success-400 bg-success-600 w-max "
            border="rounded"
          >
            {`${score?.toLocaleString("en-IN") || "_ _"} Pts.`}
          </Badge>
        </div>
      ),
      graduationYear: (
        <div>
          <Description
            size="sm"
            variant="secondary"
            weight="md"
            className="line-clamp-1"
          >
            {graduationYear}
          </Description>
        </div>
      ),
    };
    restructuredData.push(data);
  }

  return restructuredData;
}

export const leaderboardLoadingSkeletonTemplate = {
  id: 1,
  rank: <Skeleton variant="input" size="full" className="w-8 rounded-full" />,
  user: (
    <div className="items-center gap-2 t-flex">
      <Skeleton variant="avatar" size="md" />
      <Skeleton variant="input" size="full" className="w-[50%] h-4" />
    </div>
  ),
  score: <Skeleton variant="input" size="full" className="w-20 rounded-full" />,
  graduationYear: <Skeleton variant="input" size="full" className="w-20" />,
};

export function findCurrentUserIndex(data = [], currentUserId) {
  const userIndex = data.findIndex((entry) => entry.id === currentUserId);
  return userIndex + 1;
}
