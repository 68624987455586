import { getValuesFromData } from "../../transaction/hooks/helpers";

export function getFilterQueries(filters) {
  if (filters.length <= 0) return {};
  return {
    leadBatchId: getValuesFromData(filters, "batches", "id")[0],
    leadsStatuses: getValuesFromData(filters, "leadsStatus", "value"),
    startDate: getValuesFromData(filters, "startDate")[0],
    endDate: getValuesFromData(filters, "endDate")[0],
    lastViewStartDate: getValuesFromData(filters, "lastViewStartDate")[0],
    lastViewEndDate: getValuesFromData(filters, "lastViewEndDate")[0],
    leadsTypes: getValuesFromData(filters, "leadTypes", "value"),
  };
}

export function filterPhoneNumber(data, isFilterActive, setTableData) {
  if (!isFilterActive) return setTableData(data);
  const filteredData = data.filter((entry) => entry.contact);
  setTableData(filteredData);
}

export function updateCurrentStatus(data, targetId, status, otherReason) {
  const dataClone = JSON.parse(JSON.stringify(data));
  const updatedData = dataClone.map((data) => {
    if (data.id != targetId) return data;
    data.status = status;
    data.otherReason = otherReason;
    return data;
  });
  return updatedData;
}
