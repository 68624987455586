import { VALID_PARAMS } from "./constant";
let TIMEOUT;

export function debounce(fn, delay) {
  if (TIMEOUT) {
    clearTimeout(TIMEOUT);
  }
  TIMEOUT = setTimeout(fn, delay);
}
export function getParamsByParamKey(searchParams) {
  const topicParams = searchParams.getAll(VALID_PARAMS["topics"]);
  const difficultyParams = searchParams.getAll(VALID_PARAMS["difficulty"]);
  const problemTypeParams = searchParams.getAll(VALID_PARAMS["problemType"]);
  return { topicParams, difficultyParams, problemTypeParams };
}
export function getAllParams(searchParams) {
  const { topicParams, difficultyParams, problemTypeParams } =
    getParamsByParamKey(searchParams);
  const topicKey = `${VALID_PARAMS["topics"]}`;
  const difficultyKey = `${VALID_PARAMS["difficulty"]}`;
  const problemTypeKey = `${VALID_PARAMS["problemType"]}`;
  return {
    [topicKey]: topicParams,
    [difficultyKey]: difficultyParams,
    [problemTypeKey]: problemTypeParams,
  };
}
