import { Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";

export const InfoTooltip = (props) => {
  const { children, tooltipText, placement } = props;
  const siteThemeStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isDarkMode
  );
  return (
    <Tooltip
      placement={placement || "top"}
      title={tooltipText}
      overlayInnerStyle={{ fontSize: "12px", fontWeight: "500" }}
      overlayClassName={`${
        !siteThemeStatus ? "white-theme" : ""
      } problems-title-tooltip`}
      overlayStyle={{ width: "max-content", maxWidth: "250px" }}
    >
      {children}
    </Tooltip>
  );
};
