import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { restructureSessions } from "../../components/admin/event&live/hooks/useFetchEvents";
import {
  EVENT_TAB,
  TYPE_EVENT,
  TYPE_LIVE,
} from "../../components/admin/event&live/utils/constants";
import { updateCurrentStatus } from "../../components/admin/leads/hooks/helpers";
import {
  restructureColdLeads,
  restructureLeadsMetaData,
} from "../../components/admin/leads/hooks/useFetchColdLeads";
import { restructureReferrals } from "../../components/admin/referral/hooks/useFetchReferral";
import {
  restructureMetaData,
  restructureTransactionData,
} from "../../components/admin/transaction/hooks/useFetchTransaction";
import { updateChatStatus } from "../../components/admin/user/hooks/helper";
import { restructureData } from "../../components/admin/user/hooks/useUserData";
import { CHAT_BLOCK_STATUS } from "../../components/admin/user/utils/constantData";
import { axiosClient } from "../../utils/axiosClient";
import { restructureExportLeadsMetaData } from "../../components/admin/export-leads/hooks/useExportLeads";
import {
  includeLeadFilterDropdown,
  includeUserFilterDropdown,
} from "../../components/admin/export-leads/utils/constant";

export const fetchMetadata = createAsyncThunk("fetch/metaData", async () => {
  const { data } = await axiosClient.get("/admin/meta");
  return data;
});
export const fetchFilteredTransaction = createAsyncThunk(
  "fetch/transactions",
  async ({
    batchIds,
    couponCodes,
    paymentStatuses,
    startDate,
    endDate,
    page,
    search,
    bundleIds,
  }) => {
    const { data } = await axiosClient.post(
      "/admin/transactions",
      {
        batchIds,
        bundleIds,
        couponCodes,
        paymentStatuses,
        startDate,
        endDate,
      },
      {
        params: {
          page,
          search,
        },
      }
    );
    return data;
  }
);
export const fetchGraphData = createAsyncThunk(
  "fetch/graphsData",
  async ({ filterDate }) => {
    const { data } = await axiosClient(`/admin/graphs?startDate=${filterDate}`);
    return data;
  }
);
export const fetchAllUsers = createAsyncThunk(
  "fetch/allUsers",
  async ({ queries }) => {
    const API_URL = queries ? `/admin/users${queries}` : `/admin/users`;
    const { data } = await axiosClient(API_URL);
    return data;
  }
);
export const fetchUserEnrolledBatches = createAsyncThunk(
  "fetch/enrolledBatches",
  async ({ userId }) => {
    const { data } = await axiosClient(`/admin/users/${userId}/enrollments`);
    return data;
  }
);
export const fetchColdLeads = createAsyncThunk(
  "fetch/coldLeads",
  async ({
    leadResouceType,
    leadResourceId,
    startDate,
    endDate,
    lastViewStartDate,
    lastViewEndDate,
    hasPhoneNumber,
    leadLastViewSortType,
    leadViewsSortType,
    leadsStatuses,
    leadsTypes,
    page,
    search,
  }) => {
    const { data } = await axiosClient.post(
      "/admin/coldLeads",
      {
        leadResouceType,
        leadResourceId: leadResourceId ? Number(leadResourceId) : null,
        startDate,
        endDate,
        lastViewStartDate,
        lastViewEndDate,
        hasPhoneNumber,
        leadViewsSortType,
        leadLastViewSortType,
        leadsStatuses,
        leadsTypes,
      },
      {
        params: {
          page: page || 1,
          search: search || "",
        },
      }
    );
    return data;
  }
);
export const postLeadStatus = createAsyncThunk(
  "lead/status",
  async ({ leadId, status, message }, thunkAPI) => {
    const { data } = await axiosClient.patch(
      `/admin/coldLeads/status/${leadId}`,
      {
        status,
        otherReason: message,
      }
    );
    if (data.status === "ok") {
      thunkAPI.dispatch(
        setUpdatedLeadStatusData({
          targetId: leadId,
          status,
          otherReason: message,
        })
      );
    }
    return data;
  }
);
export const fetchLiveData = createAsyncThunk(
  "fetch/eventsData",
  async ({ activeTab, page, search, startDate, endDate }) => {
    let API_URL =
      activeTab === EVENT_TAB ? "/admin/events" : "/admin/liveClasses";
    const { data } = await axiosClient.post(
      API_URL,
      { startDate, endDate },
      {
        params: {
          search,
          page,
        },
      }
    );
    return data;
  }
);

export const endLiveStream = createAsyncThunk(
  "fetch/endLive",
  async ({ resourceId, resourceType }) => {
    const data = await axiosClient.patch(
      `/admin/endLive/${resourceId}?type=${resourceType}`
    );
    return data;
  }
);

export const getStreamViewers = createAsyncThunk(
  "fetch/viewersCount",
  async ({ resourceId, resourceType }) => {
    const data = await axiosClient.get(
      `/admin/live/${resourceId}/viewers?type=${resourceType}`
    );
    return data;
  }
);

export const blockUnblockUser = createAsyncThunk(
  "fetch/blockUnblockUser",
  async ({ newChatStatus, userId, type, resourceId }, thunkAPI) => {
    const data = await axiosClient.patch(`/admin/live/chat/block`, {
      newChatStatus,
      userId,
      type,
      resourceId,
    });
    if (type) return data;
    if (data.status === "ok") {
      thunkAPI.dispatch(
        setUpdatedChatStatusData({
          targetId: userId,
          status: newChatStatus === CHAT_BLOCK_STATUS ? true : false,
        })
      );
    }
    return data;
  }
);

export const fetchReferrals = createAsyncThunk(
  "fetch/referrals",
  async ({ startDate, endDate, referalStatuses, page, search }) => {
    const { data } = await axiosClient.post(
      "/admin/referrals",
      {
        referalStatuses,
        startDate,
        endDate,
      },
      {
        params: {
          page: page || 1,
          search: search || "",
        },
      }
    );
    return data;
  }
);
const adminSlice = createSlice({
  name: "adminSlice",
  initialState: {
    batchesList: [],
    bundlesList: [],
    metaData: {
      status: "idle",
      data: [],
      error: null,
    },
    filteredTransaction: {
      status: "idle",
      data: [],
      error: null,
    },
    graphs: {
      status: "idle",
      data: null,
      error: null,
    },
    allUsers: {
      status: "idle",
      data: null,
      error: null,
      apiData: null,
    },
    userEnrolledbatches: {
      status: "idle",
      data: null,
      error: null,
    },
    enrollUser: {
      status: "idle",
      data: null,
      error: null,
    },
    coldLeads: {
      status: "loading",
      data: null,
      error: null,
      apiData: null,
      metaData: null,
    },
    exportLeads: {
      exportIncludeUserFilter: null,
      exportIncludeLeadFilter: null,
    },
    leadStatus: {
      status: "idle",
      data: null,
      error: null,
    },
    events: {
      status: "idle",
      data: null,
      error: null,
    },
    endLiveStream: {
      status: "idle",
      data: null,
      error: null,
    },
    viewers: {
      status: "idle",
      data: null,
      error: null,
    },
    blockUnblockUser: {
      status: "idle",
      data: null,
      error: null,
    },
    referrals: {
      status: "idle",
      data: null,
      error: null,
      referralStatuses: [],
    },
  },
  reducers: {
    setUpdatedLeadStatusData: (state, action) => {
      const { targetId, status, otherReason } = action.payload;
      const updatedData = updateCurrentStatus(
        state.coldLeads.apiData,
        targetId,
        status,
        otherReason
      );
      state.coldLeads.apiData = updatedData;
      state.coldLeads.data.coldLeads = restructureColdLeads(updatedData);
    },
    setUpdatedReferralStatusData: (state, action) => {
      const { targetId, status } = action.payload;
      const referralData = JSON.parse(
        JSON.stringify(state.referrals.data.referrals)
      );
      referralData.forEach((referral) => {
        if (referral.id === targetId) {
          referral.status = status;
        }
      });
      state.referrals.data.referrals = referralData;
    },
    setUpdatedChatStatusData: (state, action) => {
      const { targetId, status } = action.payload;
      const apiData = state.allUsers.apiData;
      const updatedData = updateChatStatus(targetId, status, apiData);
      state.allUsers.apiData = updatedData;
      state.allUsers.data = restructureData(updatedData);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetadata.pending, (state) => {
        state.metaData.status = "loading";
      })
      .addCase(fetchMetadata.fulfilled, (state, action) => {
        state.batchesList = action.payload.batches;
        state.bundlesList = action.payload.bundles;
        const dropdownData = restructureMetaData(action.payload);
        const coldLeadsMetaData = restructureLeadsMetaData(
          action.payload,
          action.payload.isAdmin,
          action.payload.salesTeamAllowedBatches
        );
        state.referrals.referralStatuses = action.payload.referalStatuses.map(
          (referalStatus, index) => ({ name: referalStatus, id: index + 1 })
        );
        const exportIncludeUserFilter = restructureExportLeadsMetaData(
          action.payload,
          includeUserFilterDropdown
        );
        const exportIncludeLeadFilter = restructureExportLeadsMetaData(
          action.payload,
          includeLeadFilterDropdown
        );
        state.metaData.data = dropdownData;
        state.coldLeads.metaData = coldLeadsMetaData;
        state.exportLeads.exportIncludeUserFilter = exportIncludeUserFilter;
        state.exportLeads.exportIncludeLeadFilter = exportIncludeLeadFilter;
        state.metaData.status = "idle";
      })
      .addCase(fetchMetadata.rejected, (state, action) => {
        state.metaData.status = "error";
        state.metaData.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(fetchFilteredTransaction.pending, (state) => {
        state.filteredTransaction.status = "loading";
      })
      .addCase(fetchFilteredTransaction.fulfilled, (state, action) => {
        const restructurefilteredData = restructureTransactionData(
          action.payload.transactions
        );
        state.filteredTransaction.data = {
          transaction: restructurefilteredData,
          totalEntries: action.payload.totalEntries,
        };
        state.filteredTransaction.status = "idle";
      })
      .addCase(fetchFilteredTransaction.rejected, (state, action) => {
        state.filteredTransaction.status = "error";
        state.filteredTransaction.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(fetchGraphData.pending, (state) => {
        state.graphs.status = "loading";
      })
      .addCase(fetchGraphData.fulfilled, (state, action) => {
        const GRAPHS_DATA_SET = Object.keys(action.payload);
        const restructureGraphsData = {};
        for (let dataSet of GRAPHS_DATA_SET) {
          const TOTAL_KEYS = Object.keys(action.payload[dataSet])[0];
          const label = action.payload[dataSet].data.map((item) =>
            new Date(item.lable).toLocaleDateString("en-IN")
          );
          const value = action.payload[dataSet].data.map((item) => item.data);
          const accumulatedLabel = action.payload[dataSet].accumulatedData.map(
            (item) => new Date(item.lable).toLocaleDateString("en-IN")
          );
          const accumulatedData = action.payload[dataSet].accumulatedData.map(
            (item) => item.data
          );
          restructureGraphsData[dataSet] = {
            label,
            value,
            accumulatedLabel,
            accumulatedData,
            [TOTAL_KEYS]: action.payload[dataSet][TOTAL_KEYS],
          };
        }
        state.graphs.data = restructureGraphsData;
        state.graphs.status = "idle";
      })
      .addCase(fetchGraphData.rejected, (state, action) => {
        state.graphs.status = "error";
        state.graphs.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.allUsers.status = "loading";
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        const allUsers = action.payload.users;
        state.allUsers.apiData = allUsers;
        const data = restructureData(allUsers, action.payload.totalEntries);
        state.allUsers.data = data;
        state.allUsers.status = "idle";
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.allUsers.status = "error";
        state.allUsers.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(fetchUserEnrolledBatches.pending, (state) => {
        state.userEnrolledbatches.status = "loading";
      })
      .addCase(fetchUserEnrolledBatches.fulfilled, (state, action) => {
        const restructurebatches = [];
        for (let enrollment of action.payload.enrollments) {
          restructurebatches.push({
            name: enrollment.batch.name,
            id: enrollment.batch.id,
          });
        }
        state.userEnrolledbatches.data = restructurebatches;
        state.userEnrolledbatches.status = "idle";
      })
      .addCase(fetchUserEnrolledBatches.rejected, (state, action) => {
        state.userEnrolledbatches.status = "error";
        state.userEnrolledbatches.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(fetchColdLeads.pending, (state) => {
        state.coldLeads.status = "loading";
      })
      .addCase(fetchColdLeads.fulfilled, (state, action) => {
        const restructuredColdLeads = restructureColdLeads(
          action.payload.coldLeads
        );
        state.coldLeads.apiData = action.payload.coldLeads;
        state.coldLeads.data = {
          coldLeads: restructuredColdLeads,
          totalEntries: action.payload.totalEntries,
        };
        state.coldLeads.status = "idle";
      })
      .addCase(fetchColdLeads.rejected, (state, action) => {
        state.coldLeads.status = "error";
        state.coldLeads.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(postLeadStatus.pending, (state) => {
        state.leadStatus.status = "loading";
      })
      .addCase(postLeadStatus.fulfilled, (state, action) => {
        state.leadStatus.status = "idle";
        toast.success(action.payload.message);
      })
      .addCase(postLeadStatus.rejected, (state, action) => {
        state.leadStatus.status = "error";
        state.leadStatus.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(fetchLiveData.pending, (state) => {
        state.events.status = "loading";
      })
      .addCase(fetchLiveData.fulfilled, (state, action) => {
        const restructuredData = action.payload.events
          ? restructureSessions(action.payload.events, TYPE_EVENT)
          : restructureSessions(action.payload.liveClasses, TYPE_LIVE);
        state.events.data = {
          eventsData: restructuredData,
          totalEntries: action.payload.totalEntries,
        };
        state.events.status = "idle";
      })
      .addCase(fetchLiveData.rejected, (state, action) => {
        state.events.status = "error";
        state.events.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(endLiveStream.pending, (state, action) => {
        state.endLiveStream.status = "loading";
      })
      .addCase(endLiveStream.fulfilled, (state, action) => {
        state.endLiveStream.data = action.payload;
        if (action.payload.status === "ok") {
          toast.success("Live Stream Ended Successfully");
        }
        state.endLiveStream.status = "idle";
      })
      .addCase(endLiveStream.rejected, (state, action) => {
        state.endLiveStream.status = "error";
        state.endLiveStream.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(getStreamViewers.pending, (state, action) => {
        state.viewers.status = "loading";
      })
      .addCase(getStreamViewers.fulfilled, (state, action) => {
        state.viewers.data = action.payload.data;
        state.viewers.status = "idle";
      })
      .addCase(getStreamViewers.rejected, (state, action) => {
        state.viewers.status = "error";
        state.viewers.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(blockUnblockUser.pending, (state, action) => {
        state.blockUnblockUser.status = "loading";
      })
      .addCase(blockUnblockUser.fulfilled, (state, action) => {
        state.blockUnblockUser.data = action.payload;
        toast.success(action.payload.message);
        state.blockUnblockUser.status = "idle";
      })
      .addCase(blockUnblockUser.rejected, (state, action) => {
        state.blockUnblockUser.status = "error";
        state.blockUnblockUser.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(fetchReferrals.pending, (state, action) => {
        state.referrals.status = "loading";
      })
      .addCase(fetchReferrals.fulfilled, (state, action) => {
        state.referrals.data = {
          referrals: action.payload.referals,
          totalEntries: action.payload.totalEntries,
        };

        state.referrals.status = "idle";
      })
      .addCase(fetchReferrals.rejected, (state, action) => {
        state.referrals.status = "error";
        state.referrals.error = action.error;
        toast.error(action.error.message);
      });
  },
});

export const {
  setUpdatedLeadStatusData,
  setUpdatedReferralStatusData,
  setUpdatedChatStatusData,
} = adminSlice.actions;
export default adminSlice.reducer;
