import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSlugId } from "../../helper/restructureData";
import MockTestQuestionModal from "../../pages/mock-series/components/MockTestQuestionModal";
import { toggleTheme } from "../../redux/slices/appConfigSlice";
import {
  cleanPrevBatchConfig,
  setBatchConfig,
} from "../../redux/slices/batchConfigSlice";
import UserNavigationMenu from "../UserNavigationMenu";
import {
  ReportBugButton,
  ReportBugTrigger,
} from "../job-portal/components/common/ReportBug";
import IconWrapper from "../job-portal/components/ui/IconWrapper";
import { cn } from "../job-portal/helpers/utils";
import CompanyLogo from "../landing-page-v3/companyLogo";
import DripNavigation from "../week-drip/components/DripNavigation";
import BatchItem from "./BatchItem";
import GlobalSearch from "./GlobalSearch";
import "./header-swtich.scss";
import "./header.scss";

export default function Header({
  toggleSidebar,
  SIDEBAR_RENDER_CONDITION,
  isSidebarPanelCollapsed,
  hideSidePanel,
}) {
  const { batchSlug, weekSlug, mockTestSlug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const batchId = useSlugId(batchSlug);
  const [currentBatch, setCurrentBatch] = useState(null);
  const error = useSelector((state) => state.batchConfigReducer?.error);
  const batchConfigData = useSelector((state) => state.batchConfigReducer.data);
  const enrolledBatches = useSelector(
    (state) => state.persistedReducer?.appConfigReducer?.enrolledBatches
  );

  const user = useSelector(
    (state) => state.persistedReducer?.appConfigReducer?.user
  );

  const { data, status } = useSelector(
    (state) => state.dripReducer?.dripConfig
  );

  const isConfigLoading = useSelector(
    (state) => state.dripReducer?.dripConfig?.status
  );
  const themeStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isDarkMode
  );

  useEffect(() => {
    dispatch(setBatchConfig({ batchId }));
    return () => dispatch(cleanPrevBatchConfig());
  }, [batchSlug]);
  useEffect(() => {
    if (batchConfigData?.batch) {
      setCurrentBatch(batchConfigData?.batch?.name);
    }
  }, [batchConfigData]);

  useEffect(() => {
    if (error?.status === 403) return navigate("/forbidden", { replace: true });
    if (error?.status === 404) return navigate("/not-found", { replace: true });
  }, [error]);

  const items = enrolledBatches.map((item, index) => {
    return {
      label: (
        <Link
          to={`/classroom/${item.batch.slug}`}
          onClick={() => {
            setCurrentBatch(item.batch.name);
          }}
        >
          <BatchItem
            batchName={item.batch.name}
            batchImg={item.batch.thumbnail.url}
          />
        </Link>
      ),
      key: `${index}`,
    };
  });

  return (
    <div className="sticky top-0 z-10 items-center justify-between w-full px-5 py-2.5 max-md:px-2 header bg-navbar-exception shadow-sm t-flex h-[58px] border-border-500 border-b">
      <nav>
        <div
          className={cn(
            `items-center hidden  gap-2.5 max-2xl:flex ${
              SIDEBAR_RENDER_CONDITION || isSidebarPanelCollapsed ? "flex" : ""
            }`
          )}
        >
          {(SIDEBAR_RENDER_CONDITION || hideSidePanel) && (
            <button
              className={cn(
                `flex items-center justify-center max-md:flex max-2xl:hidden ${
                  SIDEBAR_RENDER_CONDITION ? "max-2xl:flex" : ""
                }`
              )}
              onClick={() => toggleSidebar(true)}
            >
              <IconWrapper
                icon={"menu"}
                size={25}
                className={"text-primaryText"}
              />
            </button>
          )}

          <Link to="/" className="flex company-logo">
            <CompanyLogo size={"90"} />
          </Link>
        </div>
        {!mockTestSlug && currentBatch && (
          <>
            {items.length > 1 ? (
              <Dropdown
                className="button-dark max-sm:hidden"
                menu={{ items }}
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    {currentBatch}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            ) : (
              enrolledBatches.length > 0 && (
                <Link
                  className="text-sm max-sm:hidden"
                  to={`/classroom/${enrolledBatches[0].batch.slug}`}
                >
                  {enrolledBatches[0].batch.name}
                </Link>
              )
            )}
            {enrolledBatches.length > 0 ? <GlobalSearch /> : null}
            {weekSlug &&
              (isConfigLoading === "loading" ? (
                <Skeleton.Input active className="max-sm:hidden" />
              ) : (
                data?.header &&
                data?.header?.week > 0 && (
                  <DripNavigation dripConfig={data} status={status} />
                )
              ))}
          </>
        )}
      </nav>
      {mockTestSlug && <MockTestQuestionModal />}
      <div className="flex profile-container">
        {enrolledBatches.length > 0 && (
          <Link to="/refer" className="glare-effect">
            <div className="items-center gap-1 max-md:hidden hover:bg-neutral-400 px-3 py-1.5 text-primaryText rounded border border-border-500 text-xs bg-neutral-100 t-flex font-medium">
              <div className="bg-[#f0ac00] border rounded-full border-border-500 p-1">
                <IconWrapper icon="rupee" size="12" className="text-black" />
              </div>
              Refer & Earn
            </div>
            <div className="bg-[#00be68] md:hidden border rounded-full border-border-500 p-1.5">
              <IconWrapper
                icon="rupee"
                size="16"
                className="text-primaryText"
              />
            </div>
          </Link>
        )}

        {enrolledBatches.length > 0 ? (
          <ReportBugTrigger WrapperComponent={ReportBugButton} />
        ) : null}

        <div className="theme-switch">
          <label className="switch">
            <input
              defaultChecked={!themeStatus}
              type="checkbox"
              onClick={() => dispatch(toggleTheme())}
            />
            <span className="slider"></span>
          </label>
        </div>
        {user && <UserNavigationMenu />}
      </div>
    </div>
  );
}
