import React from "react";
import { cn } from "../components/classroomHeader/imports";

const ThemeProvider = (props) => {
  const { theme, children, className } = props;
  return (
    <div className={cn(`${theme} min-h-screen`, className)}>{children}</div>
  );
};

export default ThemeProvider;
