export const includeUserFilterKeys = [
  "enrolledBatches",
  "enrolledBundles",
  "notEnrolledBatches",
  "notEnrolledBundles",
];
export const includeLeadsFilterKeys = [
  "leadBatches",
  "leadBundles",
  "leadTypes",
  "lastViewStartDate",
  "lastViewEndDate",
];
export const exportLeadsFilterDropdown = [
  {
    id: 1,
    index: "name",
    title: "Include",
    label: "include",
    options: [
      {
        id: 1,
        name: "users",
        label: "Users",
      },
      {
        id: 2,
        name: "leads",
        label: "Leads",
      },
    ],
  },
  {
    id: 2,
    index: "name",
    title: "Result Type",
    label: "resultType",
    isRadioInput: true,
    options: [
      {
        id: 1,
        name: "intersection",
        label: "Intersection",
      },
      {
        id: 2,
        name: "union",
        label: "Union",
      },
    ],
  },
];
export const includeUserFilterDropdown = [
  {
    id: 1,
    index: "name",
    title: "Enrolled Batches",
    label: "enrolledBatches",
    dataKey: "batches",
    options: [],
  },
  {
    id: 2,
    index: "name",
    title: "Enrolled Bundles",
    label: "enrolledBundles",
    dataKey: "bundles",
    options: [],
  },
  {
    id: 3,
    index: "name",
    title: "Not Enrolled Batches",
    label: "notEnrolledBatches",
    dataKey: "batches",
    options: [],
  },
  {
    id: 4,
    index: "name",
    title: "Not Enrolled Bundles",
    label: "notEnrolledBundles",
    dataKey: "bundles",
    options: [],
  },
];
export const includeLeadFilterDropdown = [
  {
    id: 1,
    index: "name",
    title: "Lead Batches",
    label: "leadBatches",
    dataKey: "batches",
    options: [],
  },
  {
    id: 2,
    index: "name",
    title: "Lead Bundles",
    label: "leadBundles",
    dataKey: "bundles",
    options: [],
  },
  {
    id: 3,
    index: "type",
    title: "Type",
    label: "leadTypes",
    dataKey: "leadTypes",
    options: [],
  },
  {
    id: 4,
    title: "Last view",
    isDate: true,
    filterBasis: "lastViewTime",
    options: [
      {
        id: "lastViewStartDate#1",
        label: "From :",
        name: "lastViewStartDate",
        dataLabel: "lastViewTime",
      },
      {
        id: "lastViewEndDate#1",
        label: "To :",
        name: "lastViewEndDate",
        dataLabel: "lastViewTime",
      },
    ],
  },
];

export const csvFileHeader = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Phone",
    key: "phone",
  },
  {
    label: "Graduation Year",
    key: "graduationYear",
  },
  {
    label: "Country Code",
    key: "countryCode",
  },
  {
    label: "Contact",
    key: "phoneNumber",
  },
];
