import React, { useState } from "react";
import Badge from "../../job-portal/components/ui/Badges";
import { InputText } from "../../job-portal/components/ui/Input";
import { Text } from "../../job-portal/components/ui/Typography";
import { searchFilter } from "../../job-portal/hooks/getJobFilters";

const TopicsDropdown = (props) => {
  const { metaOptions, onChange, activeFilters, filterType, placeholder } =
    props;
  const [filteredOptions, setFilteredOptions] = useState(metaOptions || []);

  function searchInputHandler(e) {
    const query = e.target.value;
    const searchIndex = "value";
    const result = searchFilter(metaOptions, query, searchIndex);
    setFilteredOptions(result);
  }
  return (
    <>
      <div className="w-full min-w-[300px]">
        <InputText
          variant="primary"
          size="sm"
          htmlFor="search"
          icon="search"
          iconSize="16"
          placeholder={placeholder}
          onChange={searchInputHandler}
        />
      </div>
      <div className="flex-wrap items-center w-full gap-2 t-flex">
        {filteredOptions.map((option) => (
          <div key={option.key}>
            <input
              type="checkbox"
              name={filterType}
              id={option.label}
              hidden
              onChange={onChange}
              value={option.value}
              data-option-key={option.key}
              aria-label={option.label}
              checked={activeFilters.includes(option.value)}
            />
            <label htmlFor={option.label}>
              <Badge
                border="rounded"
                weight="sm"
                role="button"
                tabIndex="0"
                className={`${
                  activeFilters.includes(option.value)
                    ? "text-white bg-primary-500"
                    : ""
                } [&:not(.bg-primary-500)]:hover:bg-neutral-500`}
              >
                {option.label}
              </Badge>
            </label>
          </div>
        ))}
        {filteredOptions.length === 0 ? (
          <div className="items-center justify-center w-full t-flex">
            <Text variant="destructive" size="xs" weight="md">
              Topic not found
            </Text>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default TopicsDropdown;
