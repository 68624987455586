import React from "react";
import { Description } from "../../../components/common/import";
import { CTALink } from "../../../components/job-portal/components/ui/CTALink";
import notEnrolledImg from "../../../img/not-enrolled-img.png";
import { getCoursesUrl } from "../../../utils/getRoutes";
const NotEnrolled = () => {
  return (
    <div className="items-center justify-center gap-3 p-4 mb-8 rounded-lg bg-indigo-500/20 t-flex max-sm:py-8">
      <div className="max-sm:hidden">
        <img src={notEnrolledImg} alt="not enrolled" className="max-w-36" />
      </div>
      <div className="flex-col gap-3 t-flex max-sm:items-center max-w-96">
        <div>
          <Description
            size="md"
            weight="md"
            variant="secondary"
            className="max-md:text-md max-sm:text-center"
          >
            Looks like you have not enrolled in any of our interactive course
          </Description>
        </div>
        <div>
          <CTALink
            target="_blank"
            to={getCoursesUrl()}
            variant="primary"
            size="sm"
            className="w-max"
          >
            Explore Courses
          </CTALink>
        </div>
      </div>
    </div>
  );
};

export default NotEnrolled;
