import { updateProblemBookmarkStatus } from "../../../redux/slices/ProblemListSlice";
import { getCommonFieldsInOfferingsJSX } from "../../common/OfferingJsx";
import { BookmarkButton } from "../../job-portal/components/common/ActionButton";
import Badge from "../../job-portal/components/ui/Badges";
import Skeleton from "../../job-portal/components/ui/Skeleton";
import { Description } from "../../job-portal/components/ui/Typography";
import { IconWrapper } from "../Imports";
import { difficultyTypeColorVariant } from "./constant";
export const problemsSkeletonTemplate = {
  id: 1,
  status: <Skeleton variant="avatar" size="sm" />,
  title: <Skeleton variant="input" size="full" className="w-[90%]" />,
  type: <Skeleton variant="avatar" size="sm" />,
  topic: <Skeleton variant="input" size="full" className="w-[90%]" />,
  difficulty: (
    <Skeleton variant="input" size="full" className="w-[80%] rounded-full" />
  ),
  score: <Skeleton variant="input" size="full" className="w-[80%]" />,
  solvedBy: <Skeleton variant="input" size="full" className="w-[80%]" />,
  actions: <Skeleton variant="avatar" size="sm" />,
};

const offeringType = {
  problem: "problems",
  mcq: "mcq",
  lecture: "lecture",
  recording: "lecture",
  live: "live",
};
export function getOfferingRoute(
  notesBatchSlug,
  type,
  slug,
  weekSlug,
  contestSlug,
  mockTestSlug,
  batchSlug
) {
  if (type === "lecture" || type === "recording") {
    return `/classroom/${notesBatchSlug}/w/${weekSlug}/${offeringType[type]}/${slug}`;
  } else if (type === "contest") {
    return `/contest/${contestSlug}/problems/${slug}`;
  } else if (type === "mock-series") {
    return `/classroom/${batchSlug}/mock-series/${mockTestSlug}/problems/${slug}`;
  } else return `/classroom/${notesBatchSlug}/${offeringType[type]}/${slug}`;
}

export function restructureProblemsDataToJsx(
  problemsData = [],
  batchSlug,
  batchId,
  dispatch,
  status,
  bookmarkProblemId,
  setBookmarkedProblemId,
  type,
  currentTab
) {
  return problemsData.map((data) => ({
    ...getCommonFieldsInOfferingsJSX(data, batchSlug),
    topic: (
      <Description weight="md" size="sm" className="line-clamp-1">
        {data.topic}
      </Description>
    ),
    difficulty: (
      <Badge
        size="xs"
        variant="secondary"
        className={`${difficultyTypeColorVariant[data.difficulty]} w-max`}
        border="normal"
      >
        {data.difficulty}
      </Badge>
    ),
    score: (
      <Description weight="md" size="sm">
        {`${data.score}/${data.maxScore}`}
      </Description>
    ),
    solvedBy: (
      <div className="gap-2 t-flex">
        <IconWrapper icon="people" size={20} className="text-primary-500" />

        <Description weight="md" size="sm">
          {data.solvedBy}
        </Description>
      </div>
    ),
    actions: (
      <div className="t-flex">
        <BookmarkButton
          size="24"
          loading={status === "loading"}
          targetId={bookmarkProblemId}
          id={data.id}
          bookmarkHandler={() => {
            setBookmarkedProblemId(data.id);
            dispatch(
              updateProblemBookmarkStatus({
                batchId,
                offeringId: data.id,
                bookmarkStatus: !data.hasBookmarked,
                type,
                currentTab,
              })
            );
          }}
          hasBookmarked={data.hasBookmarked}
        />
      </div>
    ),
  }));
}
