export function getSelfPacedLectureRoute(batchSlug, offeringSlug) {
  return `/classroom/${batchSlug}/dashboard?v=${offeringSlug}`;
}
export function getDripWiseLectureRoute(batchSlug, weekSlug, offeringSlug) {
  return `/classroom/${batchSlug}/${weekSlug}/lecture/${offeringSlug}`;
}
export function getCourseDetailsUrl(type, batchSlug) {
  return `${process.env.REACT_APP_LANDING_PAGE_URL}/courses/${type}/${batchSlug}`;
}
export function getCoursesUrl() {
  return `${process.env.REACT_APP_LANDING_PAGE_URL}/courses`;
}
