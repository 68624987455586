import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axiosClient";

export const setAppConfig = createAsyncThunk("fetch/appConfig", async () => {
  const response = await axiosClient.get(`/classroom/getUserConfig`, {
    params: { includeBatches: true },
  });
  return response;
});

const appConfigSlice = createSlice({
  name: "appConfigSlice",
  initialState: {
    codeEditorConfig: {
      fontSize: "14px",
      tabSize: 2,
      preferredLanguage: "java",
      theme: "light",
    },
    isLoading: false,
    toastData: {},
    isLoggedIn: false,
    isDarkMode: false,
    redirectionUrl: "",
    user: {},
    isChatModelActive: true,
    isReportBugModalOpen: false,
    currentBatchId: null,
    enrolledBatches: [],
    upcomingBatches: [],
    batchConfigState: {
      currentBatchResources: {},
    },
    googleApis: {
      tokenClient: null,
      gapiInited: false,
      gisInited: false,
    },
    videoPlayerConfig: {
      playbackRate: 1,
      lectureAutoPlay: false,
    },
  },

  reducers: {
    setBatchConfigState: (state, action) => {
      state.batchConfigState = {
        currentBatchResources: action.payload.currentBatchResources,
      };
    },
    setCodeEditorConfig: (state, action) => {
      state.codeEditorConfig = action.payload;
    },
    setIsReportBugModalOpen: (state, action) => {
      state.isReportBugModalOpen = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    showToast: (state, action) => {
      state.toastData = action.payload;
    },
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = {};
      state.enrolledBatches = [];
      state.currentBatchId = null;
    },
    toggleTheme: (state) => {
      state.isDarkMode = !state.isDarkMode;
    },
    setIsChatModelActive: (state, action) => {
      state.isChatModelActive = action.payload;
    },
    updateRedirectionUrl: (state, action) => {
      state.redirectionUrl = action.payload;
    },
    setActiveBatch: (state, action) => {
      state.currentBatchId = action.payload;
    },
    updateUserData: (state, action) => {
      state.user = action.payload;
    },
    handleGapiLoaded: (state) => {
      state.googleApis.gapiInited = true;
    },
    handleGisLoaded: (state, action) => {
      state.googleApis.gisInited = true;
      state.googleApis.tokenClient = action.payload;
    },
    resetGapiAndGis: (state) => {
      state.googleApis.gisInited = false;
      state.googleApis.gapiInited = false;
      state.googleApis.tokenClient = null;
    },
    setIsPhoneVerified: (state) => {
      state.user.isPhoneVerified = true;
    },
    setVideoPlaybackRate: (state, action) => {
      state.videoPlayerConfig.playbackRate = action.payload;
    },
    setLectureAutoPlay: (state, action) => {
      state.videoPlayerConfig.lectureAutoPlay = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(setAppConfig.pending, (state, action) => {
        state.error = null;
        state.status = "loading";
      })
      .addCase(setAppConfig.fulfilled, (state, action) => {
        state.status = "idle";
        state.enrolledBatches = action.payload.data.enrollments;
        state.user = action.payload.data.user;
        if (action.payload.data.user) {
          state.isLoggedIn = true;
        } else {
          state.isLoggedIn = false;
        }
        state.upcomingBatches = action.payload?.data?.upcomingBatches;
      })
      .addCase(setAppConfig.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export default appConfigSlice.reducer;

export const {
  setLoading,
  showToast,
  login,
  logout,
  toggleTheme,
  updateRedirectionUrl,
  setActiveBatch,
  updateUserData,
  setCodeEditorConfig,
  setIsChatModelActive,
  setBatchConfigState,
  resetGapiAndGis,
  handleGapiLoaded,
  handleGisLoaded,
  setIsReportBugModalOpen,
  setIsPhoneVerified,
  setVideoPlaybackRate,
  setLectureAutoPlay,
} = appConfigSlice.actions;
