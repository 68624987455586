import React from "react";
import { IconWrapper, Text, cn } from "../imports";

const SearchOfferingType = (props) => {
  const { label, type, icon, variant, onClick, searchType } = props;
  return (
    <button
      onClick={() => onClick(type)}
      className={cn(
        "flex-col items-center gap-1 p-2 px-4 transition-colors rounded select-none t-flex [&:not(.active)]:hover:bg-neutral-400 group [&:is(.active)]:bg-primary-500",
        searchType === type ? "active" : ""
      )}
    >
      <div className="p-2.5 t-flex rounded-full bg-neutral-400">
        <IconWrapper
          icon={icon}
          style={{ color: `var(--t-${variant})` }}
          size="28"
        />
      </div>
      <Text size="xs" weight="md" className="group-[&:is(.active)]:text-white">
        {label}
      </Text>
    </button>
  );
};

export default SearchOfferingType;
