import axios from "axios";
import { getItem, KEY_JWT, removeItem, setItem } from "./localStorageManager";
import { logout } from "../redux/slices/appConfigSlice";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  withCredentials: true,
});

axiosClient.interceptors.request.use(async (request) => {
  const accessToken = getItem(KEY_JWT);
  if (!accessToken) {
    return request;
  }
  request.headers["cs-auth"] = `Bearer ${accessToken}`;
  return request;
});

axiosClient.interceptors.response.use(
  async (response) => {
    const data = response.data; // axios data for orignal backend response
    if (response.status === 200) {
      return data;
    }
    const error = data.error;

    return Promise.reject(error);
  },
  async (error) => {
    const response = error.response;
    if (!response) return Promise.reject(error?.message);

    const orignalRequest = response.config; //for orignal requested url
    const statusCode = response.status;
    if (orignalRequest.url === "/classroom/auth/refreshAccessToken") {
      const error = response.data.error;
      removeItem(KEY_JWT);
      let mod = await import("../redux/store");
      let reduxStore = mod.store;
      reduxStore.dispatch(logout());
      window.location.replace(
        `${process.env.REACT_APP_SERVER_BASE_URL}/connect/google`,
        "_self"
      );
      return Promise.reject(error);
    }

    if (statusCode === 401) {
      const response = await axiosClient.get(
        `/classroom/auth/refreshAccessToken`
      );
      setItem(KEY_JWT, response.data.jwt);

      orignalRequest.headers["cs-auth"] = `Bearer ${response.data.jwt}`;
      return axiosClient(orignalRequest);
    }
    return Promise.reject(error?.response?.data?.error);
  }
);
