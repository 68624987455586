import React, { forwardRef, useRef } from "react";
import { Text } from "./Typography";
import { cn } from "../../helpers/utils";
import NotFound from "./NotFound";

const Table = forwardRef((props, ref) => {
  const {
    header,
    data,
    className,
    nodeIndex,
    activeClass,
    isLoading,
    tableClassName,
  } = props;
  let isDragging = false;
  let startMouseX = 0;
  let startScrollLeft = 0;
  const tableRef = useRef(null);

  function mouseDownHandler(e) {
    isDragging = true;
    startMouseX = e.pageX;
    startScrollLeft = tableRef.current.scrollLeft;
  }

  function mouseExitHandler() {
    isDragging = false;
  }
  function mouseMove(e) {
    if (isDragging) {
      let mouseDeltaX = e.pageX - startMouseX;
      tableRef.current.scrollLeft = startScrollLeft - mouseDeltaX;
    }
  }
  return (
    <div
      className={cn(
        "w-full overflow-x-scroll transition-all border rounded select-none border-border-500  max-lg:cursor-grab",
        className?.table
      )}
      onMouseDown={mouseDownHandler}
      onMouseUp={mouseExitHandler}
      onMouseMove={mouseMove}
      onMouseLeave={mouseExitHandler}
      ref={tableRef}
    >
      <table
        className={cn(
          "w-full border-collapse min-w-[1024px] table-fixed",
          tableClassName
        )}
      >
        <thead className="border-b border-border-500 bg-neutral-400">
          <tr>
            {header.map((ele) => {
              const colSize = ele.size;
              return (
                <th
                  key={ele.id}
                  style={{ width: colSize }}
                  className={cn("p-3.5", colSize)}
                >
                  <Text
                    size="sm"
                    weight="md"
                    className="justify-start whitespace-nowrap"
                  >
                    {ele.label}
                  </Text>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((data, index) => (
            <tr
              aria-rowindex={index}
              aria-expanded={true}
              ref={nodeIndex === index ? ref : null}
              key={data.id}
              className={cn(
                "border-b last:border-b-0 [&:not(:last-child)]:border-b-border-300  even:bg-neutral-200",
                className?.row,
                nodeIndex === index ? activeClass : ""
              )}
            >
              {header.map(({ dataIndex, id }) => (
                <td key={id} className="p-3.5">
                  {data[dataIndex]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!isLoading && data.length === 0 && (
        <div className="justify-center w-full py-10 t-flex">
          <NotFound
            className="w-40"
            text="No Results Found."
            textSize="text-sm"
          />
        </div>
      )}
    </div>
  );
});

export default Table;
