import { completeSolutionMeta } from "./constants";

export function restructureSolutionData(defaultCode) {
  const clonedCompleteSolutionMeta = JSON.parse(
    JSON.stringify(completeSolutionMeta)
  );
  const restructuredData = clonedCompleteSolutionMeta.filter((data) => {
    if (defaultCode[data.dataIndex]) {
      data.code = defaultCode[data.dataIndex];
      return data;
    }
  });
  return restructuredData;
}
