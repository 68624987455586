const filterTypes = {
  JOB_TYPE: "jobType",
  JOB_LEVEL: "jobLevel",
  SALARY_RANGE: "salaryRange",
};

function getFiltersByKey(dataSet, key) {
  if (dataSet.length <= 0) return;
  const restructureFilters = [];
  for (let i = 0; i < dataSet.length; i++) {
    const targetObj = dataSet[i];
    if (targetObj[key]) {
      restructureFilters.push(targetObj[key]);
    }
  }
  return restructureFilters;
}

export function getJobFilters(appliedFilters) {
  const filters = {
    jobTypes: getFiltersByKey(appliedFilters, filterTypes.JOB_TYPE),
    jobLevels: getFiltersByKey(appliedFilters, filterTypes.JOB_LEVEL),
    salaryRanges: getFiltersByKey(appliedFilters, filterTypes.SALARY_RANGE),
  };
  return filters;
}

export function getSalaryRanges(value) {
  const salaryRanges = {};
  if (value.includes("-")) {
    const splitRanges = value.split("-");
    salaryRanges["gte"] = splitRanges[0];
    salaryRanges["lte"] = splitRanges[1];
  } else {
    salaryRanges["gte"] = value;
  }
  return salaryRanges;
}

export function searchFilter(searchOptionList, query, key) {
  const results = searchOptionList.filter((option) => {
    const targetKey = key ? option[key] : option;
    if (targetKey.toLowerCase().includes(query.toLowerCase())) {
      return option;
    }
  });
  return results;
}
