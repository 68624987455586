import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export function useErrorHandler() {
  const navigate = useNavigate();
  return (error) => {
    toast.error(error.message);
    if (error.status == "403") navigate("/forbidden");
    if (error.status == "404") navigate("/404");
  };
}
