import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CSPagination from "../../components/job-portal/components/ui/CSPagination";
import { DEFAULT_PAGE } from "../../components/job-portal/helpers/constant";
import {
  axiosClient,
  mapGeneratedSkeletons,
} from "../../components/leaderboard/imports";
import {
  ClassroomContentLayout,
  ClassroomTitleDescriptionBlock,
  Description,
  IconWrapper,
  InfoIconWithTooltip,
  Table,
  Text,
  Title,
} from "../../components/week-drip/import";
import { useErrorHandler } from "../../helper/handleError";
import { useSlugId } from "../../helper/restructureData";
import { SpinnerLoadingPage } from "./components/ContestLoading";
import { contestLeaderboardHeader } from "./constant";
import {
  contestLeaderboardLoadingSkeletonTemplate,
  restructureContestLeaderboardData,
} from "./utils/restructureData";
import { InfoTooltip } from "../../components/job-portal/components/ui/Tooltip";
import ContestPracticeModeBanner from "./components/ContestPracticeModeBanner";
import useFetchData from "../../helper/useFetchData";
import ErrorContent from "./components/ErrorContent";
import ContestLeaderboardCardSkeleton from "./components/ContestLeaderboardCardSkeleton";
import { generateSkeleton } from "../../components/job-portal/components/ui/Skeleton";

export function getCompletionTime(completionTime, startTime) {
  const startDate = dayjs(completionTime);
  const diff = startDate.diff(startTime);
  let h = Math.floor(diff / (1000 * 60 * 60));
  let min = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  let sec = Math.floor((diff % (1000 * 60)) / 1000);
  let ms = diff % 1000;
  h = h < 10 ? `0${h}` : h;
  min = min < 10 ? `0${min}` : min;
  sec = sec < 10 ? `0${sec}` : sec;
  ms = ms < 10 ? `0${ms}` : ms;
  return `${h}h : ${min}m : ${sec}s : ${ms}ms`;
}
export const CurrentUserStatsCard = (props) => {
  const { icon, label, value, tooltipText } = props;
  return (
    <div className="flex-col gap-3 p-4 border rounded-lg shadow-sm border-border-300 t-flex bg-neutral-100">
      <div>
        <div className="items-center justify-center p-3 bg-teal-200 rounded-full dark:bg-teal-800 dark:bg-opacity-30 bg-opacity-30 t-flex w-max">
          <IconWrapper icon={icon} size="35" className="text-teal-500" />
        </div>
      </div>
      <div className="flex-col gap-1 t-flex">
        <div className="items-center gap-1 t-flex">
          <Title level="h3">{label}</Title>
          {tooltipText && (
            <InfoTooltip tooltipText={tooltipText}>
              <IconWrapper icon="info" size="16" className="text-primium-400" />
            </InfoTooltip>
          )}
        </div>
        <div>
          <Text size="2xl" weight="lg" className="justify-start text-teal-500">
            {value !== null && value !== undefined ? value : "_ _"}
          </Text>
        </div>
      </div>
    </div>
  );
};
const ContestStatsCards = (props) => {
  const { contestSlug } = useParams();
  const contestId = useSlugId(contestSlug);
  const { data: contestConfig } = useSelector(
    (state) => state.contestReducer.contestConfig
  );
  const {
    data: currentUserLeaderboardData,
    isLoading,
    error,
  } = useFetchData(`/classroom/contests/${contestId}/ledger`, onSuccess);
  function onSuccess(data, setData) {
    const userContestLedger = data.userContestLedger;
    let completionTime = null;
    let completionTimeWithPenalty = null;
    if (
      userContestLedger.completionTime &&
      userContestLedger.completionTimeWithPenalty
    ) {
      completionTime = getCompletionTime(
        userContestLedger.completionTime,
        contestConfig.contest.startTime
      );
      completionTimeWithPenalty = getCompletionTime(
        userContestLedger.completionTimeWithPenalty,
        contestConfig.contest.startTime
      );
    }
    const statsCardData = [
      {
        key: 1,
        icon: "medal",
        label: "Contest Rank",
        value: userContestLedger.rank,
        tooltipText:
          contestConfig && !contestConfig.contest.hasEnded
            ? "Your final rank will be available once the contest ends"
            : "Your final rank in the contest",
        isHidden: !userContestLedger.hasRegistered,
      },
      {
        key: 2,
        icon: "running",
        label: "Score During Contest",
        value: userContestLedger.scoreDuringContest,
      },
      {
        key: 3,
        icon: "bolt",
        label: "Score",
        value: userContestLedger.score,
        isHidden: contestConfig && !contestConfig.contest.hasEnded,
      },

      {
        key: 4,
        icon: "timer",
        label: "Completion Time",
        value: completionTime,
        isHidden: !userContestLedger.hasRegistered,
      },
      {
        key: 5,
        icon: "skull",
        label: "Completion Time With Penalty",
        value: completionTimeWithPenalty,
        tooltipText:
          "Each wrong answer attempt adds 5 minutes penalty to your completion time",
        isHidden: !userContestLedger.hasRegistered,
      },
      {
        key: 6,
        icon: "wrong",
        label: "Wrong Answer Attempts",
        value:
          userContestLedger.wrongAnswerAttempts !== "null" ||
          userContestLedger.wrongAnswerAttempts !== "undefined"
            ? userContestLedger.wrongAnswerAttempts
            : null,
        tooltipText:
          "Wrong answer attempts include runtime error, wrong answer and TLE submissions during contest.",
        isHidden: !userContestLedger.hasRegistered,
      },
    ];
    setData([...statsCardData]);
  }
  if (isLoading) {
    return (
      <div className="grid gap-8 grid-cols-[repeat(auto-fill,_minmax(310px,1fr))]">
        {generateSkeleton(5, ContestLeaderboardCardSkeleton).map(
          (Skeleton, i) => (
            <Skeleton key={i} />
          )
        )}
      </div>
    );
  }
  if (error) {
    return <ErrorContent error={error} className="h-max" />;
  }
  return (
    <div className="grid gap-8 grid-cols-[repeat(auto-fill,_minmax(310px,1fr))]">
      {!isLoading &&
        currentUserLeaderboardData &&
        currentUserLeaderboardData.map(
          (data) =>
            !data.isHidden && <CurrentUserStatsCard key={data.key} {...data} />
        )}
    </div>
  );
};

const ContestLeaderboard = () => {
  const errorHandler = useErrorHandler();
  const { contestSlug } = useParams();
  const contestId = useSlugId(contestSlug);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState({
    leaderboard: [],
    totalEntries: 0,
  });
  const { data: contestConfig, status } = useSelector(
    (state) => state.contestReducer.contestConfig
  );
  async function fetchContestLeaderboard(currentPage) {
    try {
      setLeaderboardLoading(true);
      const { data } = await axiosClient(
        `/classroom/contests/${contestId}/leaderboard`,
        { params: { page: currentPage, limit: 50 } }
      );
      setLeaderboardData({
        leaderboard: data.leaderboard,
        totalEntries: data.totalEntries,
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLeaderboardLoading(false);
    }
  }
  async function pageChangeHandler(page) {
    setCurrentPage(page);
    fetchContestLeaderboard(page);
  }

  useEffect(() => {
    fetchContestLeaderboard(DEFAULT_PAGE);
  }, []);
  if (status === "loading") {
    return <SpinnerLoadingPage />;
  }
  return (
    <ClassroomContentLayout>
      {contestConfig && contestConfig.contest.hasEnded && (
        <ContestPracticeModeBanner />
      )}
      <div className="flex-col gap-8 t-flex">
        <ClassroomTitleDescriptionBlock
          title="Contest Leaderboard"
          description="Solve the contest questions before time to get yourself on leaderboard"
        />
        {contestConfig && <ContestStatsCards />}

        <div className="flex-col gap-6 t-flex">
          <Table
            tableClassName="min-w-max cursor-grab"
            header={contestLeaderboardHeader}
            data={
              leaderboardLoading
                ? mapGeneratedSkeletons(
                    10,
                    contestLeaderboardLoadingSkeletonTemplate
                  )
                : restructureContestLeaderboardData(
                    leaderboardData.leaderboard,
                    contestConfig && contestConfig.contest.startTime
                  )
            }
            isLoading={leaderboardLoading}
          />
          {leaderboardData.leaderboard.length !== 0 && (
            <div className="flex-wrap items-center justify-between t-flex">
              <div>
                <Description size="sm" className="items-center gap-1 t-flex">
                  Showing
                  <Text size="sm" weight="md">
                    {leaderboardData.totalEntries}
                  </Text>
                  result(s)
                </Description>
              </div>
              <CSPagination
                totalEntries={leaderboardData.totalEntries}
                onPageChange={pageChangeHandler}
                currentPage={currentPage}
                disabled={leaderboardLoading}
              />
            </div>
          )}
        </div>
      </div>
    </ClassroomContentLayout>
  );
};

export default ContestLeaderboard;
