import React from "react";

const HomeLayout = ({ children }) => {
  return (
    <div className="container mx-auto px-4 max-sm:px-2 max-2xl:w-[85%] max-xl:w-[100%]">
      {children}
    </div>
  );
};

export default HomeLayout;
