import { difficultyType } from "../components/explore-problems/helpers/constant";

export default function restructureData(dataSet) {
  const restructuredData = [];
  for (let data of dataSet) {
    const problem = data.problem || data.mcq;
    const offering = {
      id: data.id,
      slug: data.slug,
      type: data.offeringType,
      title: problem.title,
      topic: problem.topic,
      difficulty: difficultyType[data.difficulty],
      isImportant: problem.isImportant,
      maxScore: data.maxScore,
      hasCompleted: data.isAccepted,
      score: data.userScore,
      solvedBy: data.batchSubmissionsCount,
      hasBookmarked: data.hasBookmarked,
    };
    restructuredData.push(offering);
  }
  return restructuredData;
}
export function useSlugId(slug) {
  if (slug && slug.includes("_")) {
    return slug?.split("_")[1];
  }
  return slug;
}
