import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { updateRedirectionUrl } from "../redux/slices/appConfigSlice";
import { KEY_JWT, getItem } from "../utils/localStorageManager";
import Loader from "./Loader/Loader";

const IsLoggedIn = () => {
  const loginStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isLoggedIn
  );
  const location = useLocation();
  const dispatch = useDispatch();

  if (!loginStatus) {
    const jwt = getItem(KEY_JWT);
    if (!jwt || jwt == "") {
      dispatch(updateRedirectionUrl(location.pathname));
      window.location.replace(
        `${process.env.REACT_APP_SERVER_BASE_URL}/connect/google`
      );
      return <Loader />;
    }
  }
  return <Outlet />;
};
export default IsLoggedIn;
