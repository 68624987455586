import React from "react";
import { cn } from "../../helpers/utils";
import { Text } from "../ui/Typography";

const SortDropdown = (props) => {
  const { metaOptions, activeFilters, onSortChange, setIsDropdownOpen } = props;
  return (
    <div className="flex-col justify-center w-full t-flex min-w-max">
      {metaOptions.map((option) => (
        <button
          disabled={activeFilters.label === option.label}
          key={option.key}
          onClick={() =>
            onSortChange(
              option.type,
              option.value,
              option.label,
              setIsDropdownOpen
            )
          }
          className={cn(
            ` t-flex items-center justify-start group p-2 [&:not(.active)]:hover:bg-neutral-400  ${
              activeFilters.label === option.label
                ? "font-medium [&>span]:text-light bg-primary-500 active"
                : ""
            }`
          )}
          role="button"
        >
          <Text size="sm" weight="md" variant="secondary">
            {option.label}
          </Text>
        </button>
      ))}
    </div>
  );
};

export default SortDropdown;
