import React, { forwardRef } from "react";
import { cn } from "../helpers/utils";

const DropdownLayout = forwardRef((props, ref) => {
  const { children, className, ...restProps } = props;
  return (
    <div
      className={cn(
        `border border-border-300 bg-neutral-100  w-full min-w-min absolute right-0 top-full mt-4 shadow-2xl rounded overflow-hidden overflow-y-auto duration-150 scale-75 transition origin-top max-h-72 group-aria-[expanded=true]:scale-100 z-50 dropdwon-layout ${className}`
      )}
      onClick={(e) => e.stopPropagation()}
      ref={ref}
      {...restProps}
    >
      {children}
    </div>
  );
});

export default DropdownLayout;
