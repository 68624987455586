import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { IconWrapper, Text } from "./import";
const InfoIconWithTooltip = (props) => {
  const siteThemeStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isDarkMode
  );
  const { text, tooltipText } = props;
  return (
    <div className="items-center gap-1 t-flex">
      <div>
        <Text variant="primary" size="sm" weight="md">
          {text}
        </Text>
      </div>
      <Tooltip
        placement="top"
        title={tooltipText}
        overlayClassName={`${
          !siteThemeStatus ? "white-theme" : ""
        } problems-title-tooltip`}
        overlayStyle={{ width: "max-content", maxWidth: "250px" }}
      >
        <IconWrapper icon="info" size="18" className="text-primium-400" />
      </Tooltip>
    </div>
  );
};

export default InfoIconWithTooltip;
