import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axiosClient";
import { toast } from "react-hot-toast";

const errorNotify = (message) => {
  toast.error(message);
};

export const fetchQuickCompilerDataNp = createAsyncThunk(
  "fetch/quickCompilerDataNp",
  async (uid) => {
    try {
      const response = await axiosClient(`/classroom/quickCompiler/${uid}`);
      return response.data.entry;
    } catch (err) {
      errorNotify(err.message);
    }
  }
);

const quickCompilerSliceNp = createSlice({
  name: "quickCompilerSliceNp",
  initialState: {
    defaultCode: {
      cpp: '#include <iostream>\n\nint main() {\n    std::cout << "Hello World in CPP";\n    return 0;\n}',
      java: 'class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello World in Java"); \n    }\n}',
      python: 'print("Hello World in Python")',
    },
    stdin: "",
    stdout: {},
    reduxDispatch: true,
    isCodeEdited: false,
    qcResultLoading: false,
  },
  reducers: {
    updateIsCodeEdited: (state, action) => {
      state.isCodeEdited = action.payload;
    },
    updateReduxDispatch: (state, action) => {
      state.reduxDispatch = action.payload;
    },
    updateQcCodeNp: (state, action) => {
      const { language, value } = action.payload;
      state.defaultCode[language] = value;
    },
    updateStdinNp: (state, action) => {
      state.stdin = action.payload;
    },
    updateQcResultLoading: (state, action) => {
      state.qcResultLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuickCompilerDataNp.fulfilled, (state, action) => {
      const { code, language, stdin } = action.payload;
      state.defaultCode[language] = code;
      state.stdin = stdin;
    });
  },
});

export default quickCompilerSliceNp.reducer;
export const {
  updateIsCodeEdited,
  updateQcCodeNp,
  updateReduxDispatch,
  updateStdinNp,
  updateQcResultLoading,
} = quickCompilerSliceNp.actions;
