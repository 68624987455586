import { useMemo } from "react";
import { useSelector } from "react-redux";
import resources from "../../../resources";
import {
  DISCORD_DATA_INDEX,
  QUICK_COMPILER_DATA_INDEX,
  sidebarLinksData,
} from "../helpers/constant";

const useSidebarConfig = () => {
  const enrolledBatches = useSelector(
    (state) => state.persistedReducer?.appConfigReducer?.enrolledBatches
  );
  const appConfigStatus = useSelector(
    (state) => state.persistedReducer?.appConfigReducer?.status
  );
  const batchConfigState = useSelector(
    (state) => state.persistedReducer.appConfigReducer.batchConfigState
  );
  const currentBatch = useSelector(
    (state) => state.persistedReducer.appConfigReducer.currentBatchId
  );
  const { data: batchConfigData } = useSelector(
    (state) => state.batchConfigReducer
  );
  const sidebarLinks = useMemo(() => {
    if (appConfigStatus !== "idle") return [];
    const restructureLinks = sidebarLinksData.map((data) => {
      const restructureTabs = [];
      const tabs = data.tabs;
      for (let originalTab of tabs) {
        const tab = { ...originalTab };
        if (enrolledBatches.length == 0) {
          if (
            tab.dataIndex === DISCORD_DATA_INDEX ||
            tab.dataIndex === QUICK_COMPILER_DATA_INDEX
          ) {
            restructureTabs.push(tab);
            continue;
          }
          tab.href = null;
          tab.isLocked = true;
          restructureTabs.push(tab);

          continue;
        }
        if (tab.resourcekey) {
          if (
            batchConfigState.currentBatchResources[
              resources[tab.resourcekey]
            ] == undefined
          ) {
            continue;
          } else if (
            batchConfigState.currentBatchResources[
              resources[tab.resourcekey]
            ] == 0
          ) {
            tab.isLocked = true;
            tab.href = null;
          }
        }
        if (!tab.isLocked && tab.generateHref)
          tab.href = tab.generateHref(batchConfigData?.batch?.slug);

        restructureTabs.push(tab);
      }
      return { ...data, tabs: restructureTabs };
    });
    return restructureLinks;
  }, [batchConfigState, appConfigStatus, batchConfigData]);
  return sidebarLinks;
};

export default useSidebarConfig;
