export const tableHeader = [
  {
    label: "ID",
    dataIndex: "id",
    id: 1,
  },
  {
    label: "Name",
    dataIndex: "name",
    id: 2,
    withImage: true,
  },
  {
    label: "Email",
    dataIndex: "email",
    id: 3,
  },
  {
    label: "Chat Status",
    dataIndex: "chatStatus",
    id: 4,
  },

  {
    label: "Action",
    dataIndex: "action",
    id: 5,
  },
];

export const inputProps = [
  {
    id: 1,
    label: "Batch",
    placeholder: "Select Batch",
    name: "batch",
  },
];

export const actionButtonDropdownData = [
  {
    id: 1,
    label: "Enroll User",
    onClick: (setState, key) =>
      setState((prev) => {
        return { ...prev, [key]: true };
      }),
    key: "enrollModel",
  },
  {
    id: 2,
    label: "Block/Unblock",
    onClick: (setState, key) =>
      setState((prev) => {
        return { ...prev, [key]: true };
      }),
    key: "confirmModel",
  },
];

export const dropdownDataset = [
  {
    id: 1,
    title: "Chat Status",
    index: "status",
    label: "chatStatus",
    options: [
      {
        id: 1,
        status: "block",
        label: "Blocked",
      },
      {
        id: 2,
        status: "unblock",
        label: "Unblocked",
      },
    ],
  },
];
export const CHAT_BLOCK_STATUS = "block";
export const CHAT_UNBLOCK_STATUS = "unblock";
export const CHAT_STATUS = {
  true: "Block",
  false: "Unblock",
};
