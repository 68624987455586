import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isNumber } from "lodash";
import toast from "react-hot-toast";
import getAttemptedQuestion from "../../pages/contest/utils/getAttemptedQuestion";
import { axiosClient } from "../../utils/axiosClient";
import { OFFERING_STATUS_TYPE_SOLVED } from "./contestSlice";
export const fetchMockTestConfig = createAsyncThunk(
  "fetch/mockTestConfig",
  async ({ batchId, mockTestId }) => {
    const { data } = await axiosClient(
      `/classroom/${batchId}/mocktest/${mockTestId}/config`
    );
    return data;
  }
);
const mockSeriesSlice = createSlice({
  name: "contest",
  initialState: {
    mockSeriesConfig: {
      data: null,
      status: "idle",
      error: null,
      hasMockTestEnded: false,
      attemptedQuestions: 0,
      isDurationEnded: false,
      mockTestOfferings: [],
    },
  },
  reducers: {
    setDurationEnded: (state, action) => {
      state.mockSeriesConfig.isDurationEnded = action.payload;
    },
    updateMockTestOfferingsStatus: (state, action) => {
      const { updateType, offeringId } = action.payload;
      const offerings = JSON.parse(
        JSON.stringify(state.mockSeriesConfig.mockTestOfferings)
      );

      for (let i = 0; i < offerings.length; i++) {
        if (Number(offerings[i].id) === Number(offeringId)) {
          if (
            updateType === OFFERING_STATUS_TYPE_SOLVED &&
            !offerings[i].isAccepted
          ) {
            offerings[i].isAccepted = true;
          }
          offerings[i].counter = isNumber(offerings[i].counter)
            ? offerings[i].counter + 1
            : 1;
        }
      }
      state.mockSeriesConfig.attemptedQuestions =
        getAttemptedQuestion(offerings);
      state.mockSeriesConfig.mockTestOfferings = offerings;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMockTestConfig.pending, (state, action) => {
        state.mockSeriesConfig.status = "loading";
      })
      .addCase(fetchMockTestConfig.fulfilled, (state, action) => {
        state.mockSeriesConfig.data = action.payload;

        if (action.payload.mockTestLedger.hasEnded) {
          state.mockSeriesConfig.hasMockTestEnded = true;
        }
        state.mockSeriesConfig.mockTestOfferings =
          action.payload.mockTest.offerings;
        state.mockSeriesConfig.attemptedQuestions = getAttemptedQuestion(
          action.payload.mockTest.offerings
        );
        state.mockSeriesConfig.status = "idle";
      })
      .addCase(fetchMockTestConfig.rejected, (state, action) => {
        state.mockSeriesConfig.status = "error";
        state.mockSeriesConfig.error = action.error;
        toast.error(action.error.message);
      });
  },
});
export const { setDurationEnded, updateMockTestOfferingsStatus } =
  mockSeriesSlice.actions;
export default mockSeriesSlice.reducer;
