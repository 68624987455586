import {
  OFFERING_STATUS_TYPE_ATTEMPTED,
  OFFERING_STATUS_TYPE_SOLVED,
  updateContestOfferingsStatus,
} from "../../../redux/slices/contestSlice";
import { updateMockTestOfferingsStatus } from "../../../redux/slices/mockSeriesSlice";
import { store } from "../../../redux/store";

export function updatedOfferings({ offeringId, isContest, description }) {
  if (description === "Accepted") {
    const updateType = OFFERING_STATUS_TYPE_SOLVED;

    store.dispatch(
      isContest
        ? updateContestOfferingsStatus({ updateType, offeringId })
        : updateMockTestOfferingsStatus({ updateType, offeringId })
    );
  } else {
    const updateType = OFFERING_STATUS_TYPE_ATTEMPTED;
    store.dispatch(
      isContest
        ? updateContestOfferingsStatus({ updateType, offeringId })
        : updateMockTestOfferingsStatus({ updateType, offeringId })
    );
  }
}
