import React, { useRef } from "react";
import Header from "./landing-page-v3/Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "./landing-page-v3/footer/Footer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSelector } from "react-redux";
import GoogleOneTapLogin from "./GoogleOneTapLogin";

function HeaderV3Wrapper() {
  const isLoggedIn = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.isLoggedIn
  );
  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {!isLoggedIn && <GoogleOneTapLogin />}
        <Header />
        <Outlet />
        <Footer />
      </GoogleOAuthProvider>
    </>
  );
}

export default HeaderV3Wrapper;
