import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function currencyFormatter(value) {
  if (!Number(value)) return value;
  const formatter = new Intl.NumberFormat("en-in", {
    currency: "INR",
    style: "currency",
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
}
