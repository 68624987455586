import { Link } from "react-router-dom";
import googleSignup from "../../img/google-signup.svg";
import googleSignupDark from "../../img/google-signup-dark.png";
import googleIcon from "../../img/LandingPageV3/google-icon.png";
import { useDispatch } from "react-redux";
import { updateRedirectionUrl } from "../../redux/slices/appConfigSlice";
import { cn } from "../classroomHeader/imports";
const GoogleSignin = ({ width, height, isDarkTheme, ...props }) => {
  const dispatch = useDispatch();
  return (
    <Link
      aria-label="Login with Google"
      to={`${process.env.REACT_APP_SERVER_BASE_URL}/connect/google`}
      {...props}
      onClick={() => {
        dispatch(updateRedirectionUrl(window?.location?.pathname));
      }}
    >
      <img
        title="google-logo"
        src={isDarkTheme ? googleSignupDark : googleSignup}
        width={width || 175}
        height={height || 40}
        alt="google-signup"
      />
    </Link>
  );
};
export const SignInWithGoogle = (props) => {
  const { className, redirectPath, ...restProps } = props;
  const dispatch = useDispatch();
  return (
    <Link
      {...restProps}
      to={`${process.env.REACT_APP_SERVER_BASE_URL}/connect/google`}
      aria-label="Login with Google"
      className={cn(
        "t-flex items-center justify-center gap-[10px] text-sm font-semibold border border-slate-300/20 rounded-full text-[#E3E3E3] px-[12px] py-2.5",
        className
      )}
      onClick={() => {
        dispatch(updateRedirectionUrl(redirectPath ? redirectPath : "/"));
      }}
    >
      <img src={googleIcon} alt="google" className="w-[22px]" />
      Sign in with Google
    </Link>
  );
};

export default GoogleSignin;
