export function restructureSearchResults(searchResults = []) {
  const restructuredData = searchResults.map((searchResult) => {
    const {
      mcq,
      problem,
      video_lecture,
      offeringType,
      slug,
      drip: { slug: dripSlug, week },
    } = searchResult;
    const offering = mcq || problem || video_lecture;
    return {
      title: offering.title,
      topic: offering.topic,
      offeringType,
      dripSlug,
      week,
      offeringSlug: slug,
      duration:
        offering?.duration &&
        new Date(offering?.duration * 1000)?.toISOString().slice(11, 19),
      redirectLink: `/classroom/problems/${slug}`,
    };
  });
  return restructuredData;
}
