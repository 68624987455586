import { cva } from "class-variance-authority";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { cn } from "../../helpers/utils";
import IconWrapper from "./IconWrapper";
import { buttonVariants } from "./CTAButton";

const linkVariants = cva(
  [
    "t-flex rounded group gap-1.5 justify-between font-medium aria-disabled:opacity-60  aria-disabled:pointer-events-none aria-disabled:cursor-auto ",
  ],
  {
    variants: {
      variant: {
        primary: [
          "[&:not(.active)]:hover:bg-neutral-500 [&.active]:bg-primary-500 [&.active]:text-light  text-primaryText p-2",
        ],
      },
      size: {
        sm: ["text-sm"],
      },
    },
  }
);
export const CTALink = (props) => {
  const {
    variant,
    size,
    children,
    className,
    icon,
    iconProps,
    weight,
    textProps,
    ...restProps
  } = props;
  return (
    <Link
      {...restProps}
      className={cn(buttonVariants({ variant, size, weight }), className)}
    >
      {icon && <IconWrapper icon={icon} {...iconProps} />}
      <span {...textProps}>{children}</span>
    </Link>
  );
};

export const SidebarLink = (props) => {
  const {
    text,
    icon,
    activeHref,
    variant,
    size,
    isLocked,
    className,
    href,
    dataIndex,
    generateHref,
    ...restProps
  } = props;
  return (
    <Link
      to={href}
      className={cn(linkVariants({ variant, size }), className)}
      {...restProps}
      aria-label={dataIndex}
    >
      <div className="gap-2 t-flex">
        <IconWrapper
          icon={icon}
          strokeWidth={1.5}
          className=" group-[.active]:stroke-light"
        />
        <span className="items-center t-flex">{text}</span>
      </div>
      {isLocked && (
        <IconWrapper
          icon={"locked"}
          strokeWidth={1.5}
          className=" group-[.active]:stroke-light"
        />
      )}
    </Link>
  );
};
