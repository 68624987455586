import { cva } from "class-variance-authority";
import React, { forwardRef } from "react";
import { cn } from "../../helpers/utils";
import "../../job-portal.scss";
import IconWrapper from "./IconWrapper";
import SpinnerLoader from "./Loader";
const checkboxVariants = cva(
  [
    "appearance-none disabled:cursor-not-allowed border border-border-500 rounded animatedCheckbox cursor-pointer",
  ],
  {
    variants: {
      variant: {},
      size: {
        sm: ["w-5 h-5 "],
        md: ["w-7 h-7 "],
      },
    },
  }
);
export const Checkbox = (props) => {
  const {
    htmlFor,
    label,
    size,
    variant,
    className,
    isLoading,
    checked,
    ...restProps
  } = props;
  return (
    <div>
      <label
        htmlFor={htmlFor}
        className="items-center gap-2 text-sm font-medium cursor-pointer text-secondaryText t-flex"
        role="button"
      >
        {isLoading ? (
          <SpinnerLoader size="20" />
        ) : (
          <input
            type="checkbox"
            role="checkbox"
            {...restProps}
            checked={checked}
            className={cn(checkboxVariants({ size, variant }), className)}
          />
        )}
        {label && <span className="gap-1 t-flex">{label}</span>}
      </label>
    </div>
  );
};

const inputTextVariants = cva(
  [
    "appearance-none rounded text-primaryText outline-none placeholder:neutral-400 placeholder:font-normal",
  ],
  {
    variants: {
      variant: {
        primary: ["bg-transparent"],
      },
      size: {
        sm: ["py-1.5 text-[12px]"],
        md: ["text-sm py-2"],
      },
    },
  }
);
export const InputText = forwardRef((props, ref) => {
  const {
    htmlFor,
    size,
    variant,
    className,
    icon,
    isLoading,
    iconSize,
    suffixIcon,
    suffixIconProps,
    onClear,
    ...restProps
  } = props;
  function focusHandler(e) {
    const targetElement = e.target.closest("div");
    targetElement.classList.remove("bg-neutral-400");
    targetElement.classList.add("bg-neutral-500");
  }
  function blurHandler(e) {
    const targetElement = e.target.closest("div");
    targetElement.classList.remove("bg-neutral-500");
    targetElement.classList.add("bg-neutral-400");
  }
  return (
    <div className="px-4 rounded bg-neutral-400" tabIndex="0">
      <label
        htmlFor={htmlFor}
        className="items-center gap-2 text-sm font-medium cursor-pointer text-secondaryText t-flex"
      >
        {icon && <IconWrapper icon={icon} size={iconSize} />}
        <input
          ref={ref}
          type="text"
          role="textbox"
          {...restProps}
          className={cn(inputTextVariants({ size, variant }), className)}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />
        {suffixIcon && (
          <button onClick={onClear}>
            <IconWrapper icon={suffixIcon} {...suffixIconProps} />
          </button>
        )}
      </label>
    </div>
  );
});
