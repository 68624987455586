import { cva } from "class-variance-authority";
import React, { forwardRef, useMemo } from "react";
import { cn } from "../../helpers/utils";
const progressbarVariant = cva(["w-full transition-all duration-200"], {
  variants: {
    variant: {
      destructive: ["bg-[#D10026]"],
      success: ["bg-[#00BE68]"],
      warning: ["bg-[#F0AC00]"],
    },
    size: {
      sm: ["h-2"],
      md: ["h-4"],
    },
  },
});
export const Progressbar = forwardRef((props, ref) => {
  const {
    progess,
    size,
    variant,
    className,
    progressWrapperClassName,
    ...restProps
  } = props;
  return (
    <div
      className={cn(
        "w-full overflow-hidden rounded-lg bg-neutral-400 ",
        progressWrapperClassName
      )}
    >
      <div
        data-progress={progess}
        ref={ref}
        className={cn(progressbarVariant({ size, variant }), className)}
        {...restProps}
      ></div>
    </div>
  );
});
const variant = {
  Easy: "var(--t-success-400)",
  Medium: "var(--t-warning-400)",
  Hard: "var(--t-danger-400)",
  MCQ: "var(--t-primary-500)",
};
export const CircularProgressBar = (props) => {
  const { children, graphData } = props;

  const restructuredData = useMemo(() => {
    const mcqLength = graphData.mcq.solvedPercentageByTotalProblems;
    const easyLength =
      graphData.easy.solvedPercentageByTotalProblems + mcqLength;
    const mediumLength =
      graphData.medium.solvedPercentageByTotalProblems + easyLength;
    const hardLength =
      graphData.hard.solvedPercentageByTotalProblems + mediumLength;
    const problemType = {
      Easy: easyLength,
      Medium: mediumLength,
      Hard: hardLength,
      MCQ: mcqLength,
    };
    return Object.values(graphData).map((el) => {
      return {
        ...el,
        solvedPercentageByTotalProblems: problemType[el.label],
      };
    });
  }, [graphData]);
  return (
    <div className="relative percent">
      <svg viewBox="0 0 100 100">
        <circle
          className="stroke-neutral-600"
          cx="50px"
          cy="50px"
          r="45"
        ></circle>
        {restructuredData.map((problemStats) => (
          <circle
            key={problemStats.label}
            data-problem-type={problemStats.label}
            cx="50px"
            cy="50px"
            r="45"
            style={{
              "--percent": problemStats.solvedPercentageByTotalProblems || 0.1,
              stroke: variant[problemStats.label],
            }}
          ></circle>
        ))}
      </svg>
      <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4">
        {children}
      </div>
    </div>
  );
};
