import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { SpinnerLoadingPage } from "../pages/contest/components/ContestLoading";
import ErrorContent from "../pages/contest/components/ErrorContent";
import { BATCH_TYPE_DRIP_WISE, BATCH_TYPE_SELF_PACED } from "./common/constant";
const SelfPacedDashboard = lazy(() =>
  import("../pages/self-paced/SelfPacedDashboard")
);
const DripWiseDashboard = lazy(() => import("./classes/DripWiseDashboard.js"));
const DashboardRedirect = () => {
  const { status, data, error } = useSelector(
    (state) => state.batchConfigReducer
  );

  if (status === "loading") {
    return <SpinnerLoadingPage />;
  }
  if (error) {
    return <ErrorContent error={error} />;
  }

  if (data?.batch?.type === BATCH_TYPE_DRIP_WISE) {
    return <DripWiseDashboard />;
  }
  if (data?.batch?.type === BATCH_TYPE_SELF_PACED) {
    return <SelfPacedDashboard />;
  }
  return null;
};
export default DashboardRedirect;
