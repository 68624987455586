import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _, { isNumber } from "lodash";
import toast from "react-hot-toast";
import { hasContestDurationEnded } from "../../pages/contest/utils/contestEndChecks";
import getAttemptedQuestion from "../../pages/contest/utils/getAttemptedQuestion";
import { axiosClient } from "../../utils/axiosClient";

export const OFFERING_STATUS_TYPE_SOLVED = "solved";
export const OFFERING_STATUS_TYPE_ATTEMPTED = "attempted";
export const fetchContestConfig = createAsyncThunk(
  "fetch/contestConfig",
  async ({ contestId }) => {
    const { data } = await axiosClient(
      `/classroom/contests/${contestId}/config`
    );
    return data;
  }
);
const contestSlice = createSlice({
  name: "contest",
  initialState: {
    contestConfig: {
      data: null,
      status: "idle",
      error: null,
      hasContestEnded: false,
      attemptedQuestions: 0,
      contestOfferings: [],
    },
  },
  reducers: {
    setAttemptedQuestions: (state) => {
      state.contestConfig.attemptedQuestions = getAttemptedQuestion(
        state.contestConfig.data.contest.offerings
      );
    },
    setHasContestEnded: (state, action) => {
      state.contestConfig.hasContestEnded = true;
    },
    updateContestOfferingsStatus: (state, action) => {
      const { updateType, offeringId } = action.payload;
      const offerings = JSON.parse(
        JSON.stringify(state.contestConfig.contestOfferings)
      );

      for (let i = 0; i < offerings.length; i++) {
        if (Number(offerings[i].id) === Number(offeringId)) {
          if (
            updateType === OFFERING_STATUS_TYPE_SOLVED &&
            !offerings[i].isAccepted
          ) {
            offerings[i].isAccepted = true;
          }
          offerings[i].counter = isNumber(offerings[i].counter)
            ? offerings[i].counter + 1
            : 1;
        }
      }
      state.contestConfig.attemptedQuestions = getAttemptedQuestion(offerings);
      state.contestConfig.contestOfferings = offerings;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContestConfig.pending, (state, action) => {
        state.contestConfig.status = "loading";
      })
      .addCase(fetchContestConfig.fulfilled, (state, action) => {
        state.contestConfig.data = action.payload;
        const ledger = action.payload.contestLedger;

        if (!action.payload.contest.hasEnded) {
          if (
            (ledger.completionTime &&
              _.isDate(new Date(ledger.completionTime))) ||
            hasContestDurationEnded(
              action.payload.contest.startTime,
              action.payload.contest.durationInMinutes
            )
          ) {
            state.contestConfig.hasContestEnded = true;
          }
        }
        state.contestConfig.contestOfferings = action.payload.contest.offerings;
        state.contestConfig.attemptedQuestions = getAttemptedQuestion(
          action.payload.contest.offerings
        );
        state.contestConfig.status = "idle";
      })
      .addCase(fetchContestConfig.rejected, (state, action) => {
        state.contestConfig.status = "error";
        state.contestConfig.error = action.error;
        toast.error(action.error.message);
      });
  },
});
export const {
  setHasContestEnded,
  setAttemptedQuestions,
  updateContestOfferingsStatus,
} = contestSlice.actions;
export default contestSlice.reducer;
