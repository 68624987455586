import React from "react";
import { GrFormClose } from "react-icons/gr";
import "../../../leads/lib/styles/lead-details.scss";
import { Tooltip } from "antd";
import dayjs from "dayjs";
const ReffereeDetails = ({ setIsModelActive, data, modelKey }) => {
  function modalHandler() {
    setIsModelActive((prev) => {
      return { ...prev, [modelKey]: false };
    });
  }
  const batchOrBundle = data?.batch || data?.batchBundle;
  return (
    <div className="flex admin__model__container">
      <div
        className="model__overlay"
        role="button"
        onClick={modalHandler}
      ></div>
      <div className="model__content__container">
        <div className="flex model__header">
          <div className="heading">
            <h2 className="admin__panel__secondary__heading">
              Refferee Details
            </h2>
          </div>
          <button className="flex admin__hover__button" onClick={modalHandler}>
            <GrFormClose />
          </button>
        </div>
        <div className="model__body leads__modal__body">
          <div className="flex user__details">
            <div className="flex user__image">
              <img src={data?.user?.photo} alt="user-profile" />
            </div>
            <div className="user__info">
              <h4 className="admin__panel__primary__text ">
                {data?.user?.name}
              </h4>
              <p className="admin__panel__secondary__text">
                {data?.user?.email}
              </p>
            </div>
          </div>
          <div className="order__details">
            <div className="heading">
              <h4 className="admin__panel__primary__heading">
                Refferee Details :
              </h4>
            </div>
            <div className="order__details__inner__wrapper">
              <div className="batch__details detail__container">
                <div className="heading">
                  <h4 className="admin__panel__primary__text">Batch Details</h4>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Batch Name -</p>
                  <p className="admin__panel__primary__text">
                    {batchOrBundle?.name}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Launched At -</p>
                  <p className="admin__panel__primary__text">
                    {dayjs(batchOrBundle?.launchDate).format("DD MMMM YYYY")}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Batch Price -</p>
                  <p className="admin__panel__primary__text">
                    {batchOrBundle?.price?.toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="transaction__details detail__container">
                <div className="heading">
                  <h4 className="admin__panel__primary__text">Order Details</h4>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Order ID -</p>
                  <p className="admin__panel__primary__text">{data?.orderId}</p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Payment ID -</p>
                  <p className="admin__panel__primary__text">
                    {data?.paymentId}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Status -</p>
                  <p className="capitalize admin__panel__primary__text">
                    {data?.status}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Paid At -</p>
                  <p className="capitalize admin__panel__primary__text">
                    {dayjs(data?.paidAt).format("DD MMM YYYY, hh:mm A")}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Tax Amount -</p>
                  <p className="capitalize admin__panel__primary__text">
                    {data?.taxAmount?.toLocaleString()}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Final Price -</p>
                  <p className="capitalize admin__panel__primary__text">
                    {data?.price?.toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="transaction__details detail__container">
                <div className="heading">
                  <h4 className="admin__panel__primary__text">User Details</h4>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Name -</p>
                  <p className="admin__panel__primary__text">
                    {data?.user?.name}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Email -</p>
                  <p className="admin__panel__primary__text">
                    {data?.user?.email}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">
                    Phone Number -
                  </p>
                  <p className="admin__panel__primary__text">
                    {data?.user?.phoneNumber}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LeadStatus = ({ otherReason, status }) => {
  return otherReason ? (
    <Tooltip title={`Reason: ${otherReason}`}>
      <span className={`lead__status ${status}`}>{status}</span>
    </Tooltip>
  ) : (
    <span className={`lead__status ${status}`}>{status}</span>
  );
};
export default ReffereeDetails;
