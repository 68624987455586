import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchLiveData } from "../../../../redux/slices/adminSlice";
import ActionButton from "../lib/ui/ActionButton";
import { TYPE_EVENT, TYPE_LIVE } from "../utils/constants";
import { getFilterQueries } from "./helpers";
export function restructureSessions(dataSet, type) {
  const restructuredData = dataSet.map((data) => {
    const dataTemplate = {
      id: data.id,
      title: data.title,
      startTime: new Date(data.startTime).toLocaleString("en-IN"),
      endTime: new Date(data.endTime).toLocaleString("en-IN"),
      type: data.type,
      channelDetails: data.channel,
    };
    return {
      ...dataTemplate,
      action: (
        <ActionButton
          id={dataTemplate.id}
          type={dataTemplate.type}
          data={dataTemplate}
          text={`End ${type === TYPE_LIVE ? TYPE_LIVE : TYPE_EVENT}`}
          targetName={dataTemplate.title}
        />
      ),
    };
  });
  return restructuredData;
}

export function useFetchEventsData(activeTab, searchParams, filter) {
  let TIMEOUT_ID;
  const dispatch = useDispatch();
  const page = searchParams.get("page");
  const search = searchParams.get("search");
  useEffect(() => {
    if (page || search || filter.length > 0) {
      TIMEOUT_ID = setTimeout(() => {
        dispatch(
          fetchLiveData({
            activeTab,
            page,
            search,
            startDate: searchParams.get("startDate"),
            endDate: searchParams.get("endDate"),
          })
        );
      }, 1000);
    } else {
      dispatch(fetchLiveData({ activeTab }));
    }
    return () => clearTimeout(TIMEOUT_ID);
  }, [activeTab, search, filter, page]);
}
