import dayjs from "dayjs";
import {
  Badge,
  Description,
  Title,
} from "../../../components/explore-problems/Imports";
import { CTALink } from "../../../components/job-portal/components/ui/CTALink";
import { getCourseDetailsUrl } from "../../../utils/getRoutes";

export const UpcomingCourseCard = (props) => {
  const {
    thumbnail,
    slug,
    name,
    type,
    description,
    allowEnrollments,
    category,
    tags,
  } = props;
  const batchTags = category?.tags || tags;
  return (
    <article className="relative flex-col p-3 border rounded-md shadow-sm t-flex border-border-500">
      <div className="rounded-[inherit]">
        <img
          className="object-cover w-full h-full rounded-md"
          src={thumbnail?.url}
          alt="our program"
        />
      </div>
      <div className="flex-col flex-1 gap-1 my-2 t-flex">
        <div className="flex flex-wrap items-center justify-start gap-1">
          {batchTags &&
            batchTags.split(",").map((tag) => (
              <Badge
                size="xs"
                key={tag}
                variant="secondary"
                className="text-blue-500 bg-blue-600 border-blue-700 w-max"
                border="normal"
              >
                {tag}
              </Badge>
            ))}
        </div>
        <Title weight="bold" level="h3" size="lg" className="max-sm:text-lg">
          {name}
        </Title>
        <p className="text-sm font-medium text-secondaryText line-clamp-2">
          {description}
        </p>
      </div>
      <div className="items-center justify-between gap-4 mt-4 t-flex max-sm:flex-col-reverse">
        {allowEnrollments && (
          <CTALink
            variant="primary"
            to={`/checkout/${type}/${slug}`}
            size="md"
            className="w-full h-12"
          >
            Purchase
          </CTALink>
        )}

        <CTALink
          to={getCourseDetailsUrl(type, slug)}
          target="_blank"
          size="md"
          variant="secondary"
          iconProps={{ size: 20, className: "shrink-0" }}
          icon="dropRight"
          className="flex-row-reverse w-full h-12 whitespace-nowrap"
        >
          More Details
        </CTALink>
      </div>
    </article>
  );
};

export const EnrolledCourseCard = (props) => {
  const { batch, validTill } = props;
  const batchTags = batch?.category?.tags;
  return (
    <article className="relative flex-col p-3 border rounded-md shadow-sm t-flex border-border-500">
      <div className="rounded-[inherit]">
        <img
          className="object-cover w-full h-full rounded-md"
          src={batch.thumbnail?.url}
          alt="our program"
        />
      </div>
      <div className="flex-col flex-1 gap-1 mt-2 t-flex">
        <div className="flex flex-wrap items-center justify-start gap-1">
          {batchTags &&
            batchTags.split(",").map((tag) => (
              <Badge
                size="xs"
                key={tag}
                variant="secondary"
                className="text-blue-500 bg-blue-600 border-blue-700 w-max"
                border="normal"
              >
                {tag}
              </Badge>
            ))}
        </div>
        <Title weight="bold" level="h3" size="lg" className="">
          {batch.name}
        </Title>
        <div>
          <Description size="xs" weight="md">
            {`Valid until ${dayjs(validTill).format("DD MMM YYYY")}`}
          </Description>
        </div>
      </div>
      <div className="items-center justify-between gap-4 mt-4 t-flex max-sm:flex-col-reverse">
        <CTALink
          to={`/classroom/${batch.slug}/dashboard`}
          size="md"
          variant="primary"
          iconProps={{ size: 20, className: "shrink-0" }}
          icon="rightArrow"
          className="flex-row-reverse w-full h-12 whitespace-nowrap"
        >
          Continue Learning
        </CTALink>
      </div>
    </article>
  );
};
