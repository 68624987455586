import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HomeLayout from "../../../layouts/HomeLayout";
import { toggleTheme } from "../../../redux/slices/appConfigSlice";
import UserNavigationMenu from "../../UserNavigationMenu";
import CompanyLogo from "../companyLogo";
import {
  ReportBugButton,
  ReportBugTrigger,
} from "../components/common/ReportBug";
import { cn } from "../helpers/utils";

const WelcomeHeader = () => {
  const dispatch = useDispatch();
  const themeStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isDarkMode
  );
  const user = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.user
  );
  return (
    <div
      className={cn(
        "sticky z-10 px-5 py-2.5 bg-navbar-exception top-0 flex items-center  min-h-[64px] shadow-[0px_2px_4px_rgba(0,_0,_0,_.15)]"
      )}
    >
      <HomeLayout>
        <header className="flex items-center justify-between">
          <div className="flex items-center gap-10">
            <Link to={"/classroom"} className="shrink-0">
              <CompanyLogo islight={!themeStatus} />
            </Link>
          </div>

          <div className="flex items-center gap-8 max-[475px]:gap-4">
            <ReportBugTrigger WrapperComponent={ReportBugButton} />
            <div className="theme-switch">
              <label className="switch">
                <input
                  defaultChecked={!themeStatus}
                  type="checkbox"
                  onClick={() => dispatch(toggleTheme())}
                />
                <span className="slider"></span>
              </label>
            </div>
            <UserNavigationMenu user={user} />
          </div>
        </header>
      </HomeLayout>
    </div>
  );
};

export default WelcomeHeader;
