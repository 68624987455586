export const leaderboardHeader = [
  {
    id: 0,
    label: "Rank",
    dataIndex: "rank",
    size: "10%",
  },
  {
    id: 1,
    label: "User",
    dataIndex: "user",
    size: "45%",
  },
  {
    id: 2,
    label: "Score",
    dataIndex: "score",
    size: "30%",
  },
  {
    id: 3,
    label: "Graduation Year",
    dataIndex: "graduationYear",
    size: "15%",
  },
];

export const leaderboardSortMetaData = [
  {
    key: 1,
    value: "asc",
    type: "rank",
    label: "Rank, high to low",
  },
  {
    key: 2,
    value: "desc",
    type: "rank",
    label: "Rank, low to high",
  },
];
export const DEFAULT_SORTBY = {
  type: leaderboardSortMetaData[0].type,
  label: leaderboardSortMetaData[0].label,
  value: leaderboardSortMetaData[0].value,
};
export const leaderboardTabs = [
  {
    id: 1,
    label: "Global",
    value: "global",
  },
  {
    id: 2,
    label: "Monthly",
    value: "monthly",
  },
  {
    id: 3,
    label: "Weekly",
    value: "weekly",
  },
];
export const LEADERBOARD_VALID_SORT_PARAMS = {
  [`${leaderboardSortMetaData[0].type}_${leaderboardSortMetaData[0].value}`]:
    leaderboardSortMetaData[0].label,
  [`${leaderboardSortMetaData[1].type}_${leaderboardSortMetaData[1].value}`]:
    leaderboardSortMetaData[1].label,
};
export const ALLOWED_SORT = ["asc", "desc"];
