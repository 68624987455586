import { axiosClient } from "../../utils/axiosClient";

export function sendOtp(countryCode, phoneNumber) {
  return axiosClient.post("/classroom/auth/phone", {
    countryCode,
    phoneNumber,
  });
}
export function verifyOtp(userOtp) {
  return axiosClient.post("/classroom/auth/phone/verify", {
    userOtp,
  });
}
