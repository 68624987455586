import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import defaultProfile from "../../../../img/defaultprofile.png";
import { fetchColdLeads } from "../../../../redux/slices/adminSlice";
import { axiosClient } from "../../../../utils/axiosClient";
import ActionButton from "../lib/ui/ActionButton";
import { LeadStatus, QueryMessage } from "../lib/ui/LeadDetails";
import { dateFormatOptions, dropdownDataset } from "../utils/constants";
export function restructureLeadsMetaData(
  metaData,
  isAdmin,
  salesTeamAllowedBatches
) {
  const dropdownDataClone = JSON.parse(JSON.stringify(dropdownDataset));
  for (let dropdownData of dropdownDataClone) {
    const metaOptions = metaData[dropdownData.label];
    if (!metaOptions) continue;
    if (dropdownData.label === "batches") {
      const bundles = metaData.bundles.map((bundle) => ({
        ...bundle,
        id: `batchBundle_${bundle.id}`,
        bundleId: bundle.id,
      }));
      const batches = metaData.batches.map((batch) => ({
        ...batch,
        id: `batch_${batch.id}`,
        batchId: batch.id,
      }));

      if (!isAdmin) {
        const filteredAllowedBatches = batches.filter((batch) =>
          salesTeamAllowedBatches.batches.includes(batch.batchId)
        );
        const filteredAllowedBundles = bundles.filter((bundle) =>
          salesTeamAllowedBatches.bundles.includes(bundle.bundleId)
        );
        dropdownData.options = [
          ...filteredAllowedBatches,
          ...filteredAllowedBundles,
        ];
      } else {
        dropdownData.options = [...bundles, ...batches];
      }
    } else {
      dropdownData.options = metaOptions;
    }
  }
  return dropdownDataClone;
}
export function restructureColdLeads(dataSet) {
  const restructuredData = [];
  dataSet.forEach((data) => {
    if (!data) return;
    const dataTemplate = {
      id: data.id,
      photo: data.user?.photo || defaultProfile,
      name: data.user?.name || data.userData?.name,
      email: data.user?.email || data.userData?.email,
      type: data.type && data.type,
      contact: data.user
        ? data.user.countryCode + data.user.phoneNumber
        : data.userData.countryCode + data.userData.phoneNumber,
      graduationYear: data.user?.graduationYear,
      querySubject:
        data.type === "query" ? (
          <QueryMessage message={data.caption} />
        ) : (
          data.caption
        ),
      views: data.additionalInfo?.views,
      batchName: (
        data.additionalInfo?.batch || data.additionalInfo?.batchBundle
      )?.name,
      batchLaunchDate: data.additionalInfo?.batch?.launchDate
        ? new Date(data.additionalInfo?.batch?.launchDate).toLocaleString(
            "en-IN",
            dateFormatOptions
          )
        : "N/A",
      lastViewAt: new Date(data.lastViewAt).toLocaleString(
        "en-IN",
        dateFormatOptions
      ),
      lastViewDateValue: new Date(data.lastViewAt).valueOf(),
      leadStatus: data.status && (
        <LeadStatus otherReason={data.otherReason} status={data.status} />
      ),
    };
    restructuredData.push({
      ...dataTemplate,
      action: <ActionButton data={dataTemplate} />,
    });
  });

  return restructuredData;
}

export function restructureCSVFileData(dataSet) {
  const restructuredData = dataSet.map((data) => {
    const dataTemplate = {
      name: data.user?.name || data?.userData?.name,
      email: data.user?.email || data?.userData?.email,
      contact: data.user?.phoneNumber || data?.userData?.phoneNumber,
      views: data?.additionalInfo?.views || "NA",
      batchName: (
        data.additionalInfo?.batch || data.additionalInfo?.batchBundle
      )?.name,

      lastViewAt:
        new Date(data.lastViewAt).toLocaleString("en-IN", dateFormatOptions) ||
        "NA",
      leadStatus:
        data.status === "other"
          ? `other: ${data.otherReason}`
          : data.status || "NA",
    };
    return dataTemplate;
  });

  return restructuredData;
}
export function useFetchColdLeads(filters, sortBasis) {
  const [searchParams] = useSearchParams();
  let TIMEOUT_ID;
  const sortBy = searchParams.get("sortBy");
  const sortType = sortBy && sortBy.split("_")[0];
  const sortValue = sortBy && sortBy.split("_")[1];
  const leadResouceType =
    searchParams.get("batches") && searchParams.get("batches").split("_")[1];
  const leadResourceId =
    searchParams.get("batches") && searchParams.get("batches").split("_")[2];
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      searchParams.get("page") ||
      searchParams.get("search") ||
      filters.length > 0 ||
      searchParams.get("includePhoneNumber")
    ) {
      TIMEOUT_ID = setTimeout(() => {
        dispatch(
          fetchColdLeads({
            leadResouceType,
            leadResourceId,

            leadsStatuses: searchParams.getAll("leadsStatus"),
            startDate:
              searchParams.get("startDate") &&
              new Date(searchParams.get("startDate")).valueOf(),
            endDate:
              searchParams.get("endDate") &&
              new Date(searchParams.get("endDate")).valueOf(),
            lastViewStartDate:
              searchParams.get("lastViewStartDate") &&
              new Date(searchParams.get("lastViewStartDate")).valueOf(),
            lastViewEndDate:
              searchParams.get("lastViewEndDate") &&
              new Date(searchParams.get("lastViewEndDate")).valueOf(),
            leadsTypes: searchParams.getAll("leadTypes"),
            page: searchParams.get("page"),
            search: searchParams.get("search"),
            hasPhoneNumber: searchParams.get("includePhoneNumber"),

            [sortType]: sortValue,
          })
        );
      }, 1000);
    } else {
      dispatch(
        fetchColdLeads({
          [sortType]: sortValue,
        })
      );
    }
    return () => clearTimeout(TIMEOUT_ID);
  }, [
    searchParams.get("page"),
    searchParams.get("search"),
    filters,
    searchParams.get("includePhoneNumber"),
    sortBasis,
  ]);
}

export async function fetchCsvFileData(isPhoneFilterActive, searchParams) {
  let API_URL = `/admin/coldLeads?limit=-1`;
  const sortBy = searchParams.get("sortBy");
  const sortType = sortBy && sortBy.split("_")[0];
  const sortValue = sortBy && sortBy.split("_")[1];
  const leadResouceType =
    searchParams.get("batches") && searchParams.get("batches").split("_")[1];
  const leadResourceId =
    searchParams.get("batches") && searchParams.get("batches").split("_")[2];
  const API_BODY = {
    leadResouceType,
    leadResourceId,
    leadsStatuses: searchParams.getAll("leadsStatus"),
    startDate:
      searchParams.get("startDate") &&
      new Date(searchParams.get("startDate")).valueOf(),
    endDate:
      searchParams.get("endDate") &&
      new Date(searchParams.get("endDate")).valueOf(),
    lastViewStartDate:
      searchParams.get("lastViewStartDate") &&
      new Date(searchParams.get("lastViewStartDate")).valueOf(),
    lastViewEndDate:
      searchParams.get("lastViewEndDate") &&
      new Date(searchParams.get("lastViewEndDate")).valueOf(),
    leadsTypes: searchParams.getAll("leadTypes"),
    hasPhoneNumber: isPhoneFilterActive,
    [sortType]: sortValue,
  };
  const { data } = await axiosClient.post(API_URL, API_BODY, {
    params: {
      page: searchParams.get("page"),
      search: searchParams.get("search"),
    },
  });
  const restructuredData = restructureCSVFileData(data.coldLeads);
  return restructuredData;
}
