import React from "react";
import "../styles/action-dropdown.scss";
const ActionDropdown = ({ dropdownData = [], setState }) => {
  return (
    <>
      <div className="action__dropdown__wrapper flex">
        {dropdownData.map((item) => (
          <div className="button__wrapper" key={item.id}>
            <button onClick={() => item.onClick(setState, item.key)}>
              <span className="admin__panel__primary__text">{item.label}</span>
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default ActionDropdown;
