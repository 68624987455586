import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getSelfPacedLectureRoute } from "../../../utils/getRoutes";
import { BATCH_TYPE_SELF_PACED } from "../../common/constant";
import { OFFERING_ICON_VARIANT } from "../helpers/constant";
import { IconWrapper, Text, offeringTypeIcon } from "../imports";

const offeringRedirectLink = {
  problem: (batchSlug, offeringSlug) =>
    `/classroom/${batchSlug}/problems/${offeringSlug}`,
  mcq: (batchSlug, offeringSlug) =>
    `/classroom/${batchSlug}/mcq/${offeringSlug}`,
  lecture: (batchSlug, offeringSlug, dripSlug) =>
    `/classroom/${batchSlug}/w/${dripSlug}/lecture/${offeringSlug}`,
};
const SearchResult = (props) => {
  const {
    title,
    topic,
    offeringType,
    dripSlug,
    duration,
    week,
    setIsSearchModalActive,
    offeringSlug: slug,
  } = props;
  const { batchSlug } = useParams();
  function closeSearchModalHandler() {
    setIsSearchModalActive(false);
  }
  const { data } = useSelector((state) => state.batchConfigReducer);

  const redirectPath = data
    ? data.batch.type === BATCH_TYPE_SELF_PACED && offeringType === "lecture"
      ? getSelfPacedLectureRoute(batchSlug, slug)
      : offeringRedirectLink[offeringType](batchSlug, slug, dripSlug)
    : "";

  return (
    <Link
      to={redirectPath}
      onClick={closeSearchModalHandler}
      className="w-full p-2 py-3 transition-colors rounded hover:bg-neutral-400 group"
    >
      <div className="items-start gap-2 t-flex">
        <div className="mt-1">
          <IconWrapper
            icon={offeringTypeIcon[offeringType]}
            style={{ color: `var(--t-${OFFERING_ICON_VARIANT[offeringType]})` }}
          />
        </div>

        <div className="w-full">
          <Text
            size="sm"
            weight="md"
            variant="primary"
            className="justify-start transition-colors group-hover:text-primaryText"
          >
            {title}
          </Text>
          <Text
            size="xs"
            weight="md"
            variant="secondary"
            className="justify-start mt-0.5"
          >
            {`${week < 0 ? "Prep Module" : `Week ${week}`} | ${
              topic ? `Topic : ${topic}` : `Duration : ${duration}`
            }`}
          </Text>
        </div>
      </div>
    </Link>
  );
};

export default SearchResult;
