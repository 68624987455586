import React from "react";
import { Description, Title } from "../components/ui/Typography";
import { cn } from "../helpers/utils";

export const ClassroomTitleDescriptionBlock = (props) => {
  const { title, description } = props;
  return (
    <div className="flex-col gap-1 t-flex ">
      <Title variant="primary" size="lg" weight="semibold">
        {title}
      </Title>
      <Description variant="primary" size="sm" weight="normal">
        {description}
      </Description>
    </div>
  );
};
const ClassroomContentLayout = (props) => {
  const { children, className } = props;
  return (
    <div className={cn("container p-4 m-auto max-md:px-2", className)}>
      {children}
    </div>
  );
};

export default ClassroomContentLayout;
