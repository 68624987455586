import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { restructureSolutionData } from "../../components/problems/Solutions/RestructureData";
import { NOTES_RESOURCE_TYPE } from "../../helper/GlobalVariables";
import { updatedOfferings } from "../../pages/contest/utils/updatedOfferings";
import { axiosClient } from "../../utils/axiosClient";
import { dateToAgo } from "../../utils/dateFormatter";

const languageMap = {
  cpp: {
    language_name: "C++",
    language_mode: "cpp",
  },
  python: {
    language_name: "Python",
    language_mode: "python",
  },
  java: {
    language_name: "Java",
    language_mode: "java",
  },
};
function getCurrentOfferingApiRoute({
  batchId,
  contestId,
  offeringId,
  isContest,
  routeQuery,
  mockTestId,
  isMockTest,
}) {
  const VALID_QUERY_KEYS = [
    "getOffering",
    "getAllSolution",
    "getUnlockSolution",
    "saveCode",
    "getMySubmission",
    "getCommunitySubmission",
    "runOrSubmitCode",
  ];
  if (!routeQuery || !VALID_QUERY_KEYS.includes(routeQuery)) {
    return toast.error(
      "Route Type is not Provided or invalid in getCurrentOfferingApiRoute Function"
    );
  }
  const ROUTE_TYPE = isContest
    ? "contests"
    : isMockTest
    ? `${batchId}/mocktest`
    : "codeeditor";
  const currentType = isContest ? contestId : isMockTest ? mockTestId : batchId;
  const commonPath = `/classroom/${ROUTE_TYPE}/${currentType}/problems`;
  const API_ROUTES = {
    getOffering: `${commonPath}/${offeringId}`,
    getAllSolution: `${commonPath}/${offeringId}/solution`,
    getUnlockSolution: `${commonPath}/${offeringId}/solution/unlock`,
    saveCode: `${commonPath}/${offeringId}/saveCode`,
    getMySubmission: `${commonPath}/${offeringId}/submissions`,
    getCommunitySubmission: `${commonPath}/${offeringId}/submissions/community`,
    runOrSubmitCode: `/classroom/${
      isContest ? "contests" : isMockTest ? `${batchId}/mocktest` : "evaluate"
    }/${currentType}/problems/${offeringId}/${
      isContest || isMockTest ? "evaluate" : ""
    }`,
  };

  return API_ROUTES[routeQuery];
}
export const fetchCurrentProblemData = createAsyncThunk(
  "fetch/currentProblemData",
  async ({
    batchId,
    offeringId,
    contestId,
    isContest,
    mockTestId,
    isMockTest,
  }) => {
    const CURRENT_API_ROUTE = getCurrentOfferingApiRoute({
      batchId,
      contestId,
      isContest,
      offeringId,
      routeQuery: "getOffering",
      mockTestId,
      isMockTest,
    });
    const response = await axiosClient.get(CURRENT_API_ROUTE);
    return response;
  }
);

export const fetchSubmissionsData = createAsyncThunk(
  "fetch/submissionsData",
  async ({
    batchId,
    offeringId,
    isContest,
    contestId,
    mockTestId,
    isMockTest,
  }) => {
    const CURRENT_API_ROUTE = getCurrentOfferingApiRoute({
      batchId,
      contestId,
      isContest,
      offeringId,
      routeQuery: "getMySubmission",
      mockTestId,
      isMockTest,
    });
    const res = await axiosClient.get(CURRENT_API_ROUTE);
    let restructredSubmission = [];
    for (const submission of res.data) {
      restructredSubmission.push({
        id: submission.id,
        statusText: submission.result,
        time: dateToAgo(submission.createdAt),
        ...languageMap[submission.language],
        code: submission.code,
        memory: submission.memory,
        executionTime: submission.time,
        timestamp: new Date(submission.createdAt),
      });
    }
    return restructredSubmission;
  }
);

export const fetchCommunitySubmissionsData = createAsyncThunk(
  "fetch/communitySubmissionsData",
  async ({
    batchId,
    offeringId,
    newPage,
    contestId,
    isContest,
    mockTestId,
    isMockTest,
  }) => {
    const CURRENT_API_ROUTE = getCurrentOfferingApiRoute({
      batchId,
      contestId,
      isContest,
      offeringId,
      routeQuery: "getCommunitySubmission",
      mockTestId,
      isMockTest,
    });
    const res = await axiosClient.get(CURRENT_API_ROUTE, {
      params: {
        page: newPage,
        limit: 12,
      },
    });

    let restructredSubmission = [];
    for (const submission of res.data.submissions) {
      restructredSubmission.push({
        id: submission.id,
        statusText: submission.result,
        time: dateToAgo(submission.createdAt),
        ...languageMap[submission.language],
        user: submission.user,
        memory: submission.memory,
        executionTime: submission.time,
        timestamp: new Date(submission.createdAt),
      });
    }

    return {
      restructredSubmission,
      totalEntries: res.data.totalEntries,
      newPage,
    };
  }
);

export const sendCodeData = createAsyncThunk(
  "post/codeData",
  async (req, { dispatch }) => {
    const {
      offeringId,
      data,
      batchId,
      isContest,
      contestId,
      mockTestId,
      isMockTest,
    } = req;
    const CURRENT_API_ROUTE = getCurrentOfferingApiRoute({
      batchId,
      contestId,
      isContest,
      offeringId,
      routeQuery: "runOrSubmitCode",
      mockTestId,
      isMockTest,
    });
    const response = await axiosClient.request({
      method: "post",
      url: CURRENT_API_ROUTE,
      data: data,
    });
    if (data.evaluationType == "submit") {
      dispatch(
        fetchSubmissionsData({
          offeringId,
          batchId,
          contestId,
          isContest,
          routeQuery: "getMySubmission",
        })
      );
      dispatch(currentProblemSlice.actions.setDescriptionTabKey("3"));
      if ((isContest || isMockTest) && data.evaluationType === "submit") {
        updatedOfferings({
          isContest,
          isMockTest,
          offeringId,
          evaluationType: data.evaluationType,
          description: response.data.codeStatus.description,
        });
      }
    }
    return response;
  }
);

export const fetchSolution = createAsyncThunk(
  "fetch/solution",
  async ({
    offeringId,
    batchId,
    contestId,
    isContest,
    mockTestId,
    isMockTest,
  }) => {
    const CURRENT_API_ROUTE = getCurrentOfferingApiRoute({
      batchId,
      contestId,
      isContest,
      offeringId,
      routeQuery: "getAllSolution",
      mockTestId,
      isMockTest,
    });
    const response = await axiosClient.request({
      method: "get",
      url: CURRENT_API_ROUTE,
    });

    return response;
  }
);
// to fetch user notes
export const fetchNotesData = createAsyncThunk(
  "fetch/getNotes",
  async ({
    offeringId,
    batchId,
    contestId,
    isContest,
    mockTestId,
    isMockTest,
  }) => {
    const { BATCH, CONTEST, MOCK_TEST } = NOTES_RESOURCE_TYPE;
    const resourceType = isContest ? CONTEST : isMockTest ? MOCK_TEST : BATCH;
    const resourceId = isContest
      ? contestId
      : isMockTest
      ? mockTestId
      : batchId;
    const response = await axiosClient.get(
      `/classroom/getNotes/${offeringId}`,
      {
        params: {
          resourceType,
          resourceId,
        },
      }
    );
    return response.data.userNotes;
  }
);
// to update user notes
export const updateNotesData = createAsyncThunk(
  "fetch/updateNotes",
  async ({
    offeringId,
    noteContent,
    batchId,
    contestId,
    isContest,
    mockTestId,
    isMockTest,
  }) => {
    const { BATCH, CONTEST, MOCK_TEST } = NOTES_RESOURCE_TYPE;
    const resourceType = isContest ? CONTEST : isMockTest ? MOCK_TEST : BATCH;
    const resourceId = isContest
      ? contestId
      : isMockTest
      ? mockTestId
      : batchId;
    const response = await axiosClient.post(
      `/classroom/updateNotes/${offeringId}`,
      {
        noteContent,
      },
      {
        params: {
          resourceType,
          resourceId,
        },
      }
    );

    return response;
  }
);
// to save user code
export const saveUserCode = createAsyncThunk(
  "fetch/saveCode",
  async ({
    batchId,
    offeringId,
    code,
    language,
    contestId,
    isContest,
    mockTestId,
    isMockTest,
  }) => {
    const CURRENT_API_ROUTE = getCurrentOfferingApiRoute({
      batchId,
      contestId,
      isContest,
      offeringId,
      routeQuery: "saveCode",
      mockTestId,
      isMockTest,
    });
    const response = await axiosClient.post(CURRENT_API_ROUTE, {
      language,
      code,
    });
    return response.data;
  }
);
export const unlockSolution = createAsyncThunk(
  "post/unlockSolution",
  async (req) => {
    const {
      offeringId,
      batchId,
      resourceType,
      isContest,
      contestId,
      mockTestId,
      isMockTest,
    } = req;
    const CURRENT_API_ROUTE = getCurrentOfferingApiRoute({
      batchId,
      contestId,
      isContest,
      offeringId,
      routeQuery: "getUnlockSolution",
      mockTestId,
      isMockTest,
    });
    const response = await axiosClient.request({
      method: "post",
      url: CURRENT_API_ROUTE,
      data: {
        resourceType: resourceType,
      },
    });

    return response;
  }
);

//fetch solutionData -> solution api - update solutionn object(post req)
//unlockSolution - (resourse type) dispatch

const initialState = {
  isSettingModelActive: false,
  canAccessSolutionsAndCommunitySubmissions: false,
  status: "idle",
  error: null,
  description: {},
  currentTabKey: "1",
  isOutputTabActive: false,
  currentCode: {
    code: "",
    programmingLanguage: null,
    testInput: null,
  },
  previousProblem: null,
  nextProblem: null,
  relatedLectures: [],
  savedCode: {
    data: {
      cpp: "",
      java: "",
      python: "",
    },
    isUserCodeSaved: true,
    error: null,
  },
  solution: {
    hintOne: {
      isLocked: true,
    },
    hintTwo: {
      isLocked: true,
    },
    solutionApproach: {
      isLocked: true,
    },
    completeSolution: {
      isLocked: true,
    },
    status: "idle",
  },

  codeOutput: {
    currentTabKey: "1",
    status: "idle",
    executionResult: undefined,
    mode: undefined,
  },
  submissions: {
    data: [],
    status: "idle",
    error: undefined,
  },
  communitySubmissions: {
    currPage: 1,
    data: [],
    totalEntries: 0,
    accesedStatus: false,
    status: "idle",
    error: undefined,
  },
  notes: {
    data: "",
    status: "idle",
    error: undefined,
  },
  maxScore: 0,
  userScore: 0,
};

const currentProblemSlice = createSlice({
  name: "currentProblem",
  initialState,
  reducers: {
    setIsSettingModelActive: (state, action) => {
      state.isSettingModelActive = action.payload;
    },
    setSaveCode: (state, action) => {
      state.savedCode.data = action.payload;
    },
    //problem solving page reducers
    updateCode: (state, action) => {
      state.currentCode.code = action.payload;
    },
    updateTestCase: (state, action) => {
      state.currentCode.testInput = action.payload;
    },
    updateLanguage: (state, action) => {
      state.currentCode.programmingLanguage = action.payload;
    },
    handleEvaluationType: (state, action) => {
      state.currentCode.evaluationType = action.payload;
    },

    //cleaning store after exiting problem solving page
    cleanDescription: (state, action) => {
      state.description = {};
    },
    cleanSolutions: (state, action) => {
      state.solution = {};
    },
    resetTestCase: (state) => {
      state.currentCode.testInput = state.description.testcase.input;
    },

    setResultTabKey: (state, action) => {
      state.codeOutput.currentTabKey = action.payload;
    },

    setDescriptionTabKey: (state, action) => {
      state.currentTabKey = action.payload;
    },

    setCodeRunMode: (state, action) => {
      state.codeOutput.mode = action.payload;
    },
    setIsCodeOutputActive: (state, action) => {
      state.isOutputTabActive = action.payload;
    },

    currentProblemCleanup: () => {
      return initialState;
    },

    cleanCommunitySubmissions: (state) => {
      state.communitySubmissions.data = [];
      state.communitySubmissions.currPage = 0;
      state.communitySubmissions.totalEntries = 0;
      state.communitySubmissions.accesedStatus = false;
    },

    setCommunitySubmissionAccesedStatus: (state, action) => {
      state.communitySubmissions.accesedStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentProblemData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCurrentProblemData.fulfilled, (state, action) => {
        state.description = action.payload.data.problem.problem;
        state.savedCode.data =
          action.payload.data.problem.problem.savedCode ||
          action.payload.data.problem.problem.defaultCode;
        state.currentCode.code =
          state.savedCode.data[state.currentCode.programmingLanguage || "java"];
        state.currentCode.testInput =
          action.payload.data?.problem?.problem?.testcase?.input;
        if (action.payload.data.problem.userScore)
          state.userScore = action.payload.data.problem.userScore;
        state.maxScore = action.payload.data.problem.problem.maxScore;
        state.previousProblem = action.payload.data.prevOffering || null;
        state.nextProblem = action.payload.data.nextOffering || null;
        state.relatedLectures =
          action.payload.data.relatedLectureOfferings || [];
        state.canAccessSolutionsAndCommunitySubmissions =
          action.payload.data.canAccessSolutionsAndCommunitySubmissions;
        state.communitySubmissions.accesedStatus =
          action.payload.data.communitySubmissionAccesedStatus;
        state.status = "idle";
      })
      .addCase(fetchCurrentProblemData.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })

      .addCase(fetchSolution.pending, (state, action) => {
        state.solution.status = "loading";
      })
      .addCase(fetchSolution.fulfilled, (state, action) => {
        state.solution = action.payload.data;
        if (!state.solution.completeSolution.isLocked) {
          const completeSolutonRestructuredData = restructureSolutionData(
            action.payload.data.completeSolution.data
          );
          state.solution.completeSolution.data =
            completeSolutonRestructuredData;
        }
        state.solution.status = "idle";
      })
      .addCase(fetchSolution.rejected, (state, action) => {
        state.solution.status = "error";
        state.solution.error = action.error.message;
      })

      // .addCase(unlockSolution.pending, (state, action) => {
      //   state.solution.status = "loading";
      // })
      .addCase(unlockSolution.fulfilled, (state, action) => {
        const apiResponse = action.payload.data;
        if (!apiResponse.completeSolution.isLocked) {
          const completeSolutonRestructuredData = restructureSolutionData(
            action.payload.data.completeSolution.data
          );
          apiResponse.completeSolution.data = completeSolutonRestructuredData;
        }
        state.solution = apiResponse;
        state.solution.status = "idle";
      })
      .addCase(unlockSolution.rejected, (state, action) => {
        state.solution.status = "error";
        state.solution.error = action.error.message;
      })

      .addCase(sendCodeData.pending, (state, action) => {
        state.codeOutput.status = "loading";
      })
      .addCase(sendCodeData.fulfilled, (state, action) => {
        state.codeOutput.executionResult = action.payload.data;
        if (action.payload.data.score) {
          state.userScore = action.payload.data.score;
        }
        state.codeOutput.status = "idle";
      })
      .addCase(sendCodeData.rejected, (state, action) => {
        toast.error(action.error.message);
        state.codeOutput.status = "error";
        state.codeOutput.error = action.error.message;
      })

      .addCase(fetchSubmissionsData.pending, (state, action) => {
        state.submissions.status = "loading";
        state.submissions.data = [];
      })
      .addCase(fetchSubmissionsData.fulfilled, (state, action) => {
        state.submissions.status = "idle";
        state.submissions.data = action.payload;
      })
      .addCase(fetchSubmissionsData.rejected, (state, action) => {
        state.submissions.status = "error";
        state.submissions.error = action.error.message;
      })

      .addCase(fetchCommunitySubmissionsData.pending, (state, action) => {
        state.communitySubmissions.status = "loading";
      })
      .addCase(fetchCommunitySubmissionsData.fulfilled, (state, action) => {
        state.communitySubmissions.status = "idle";
        state.communitySubmissions.data = [
          ...state.communitySubmissions.data,
          ...action.payload.restructredSubmission,
        ];
        state.communitySubmissions.totalEntries = action.payload.totalEntries;
        state.communitySubmissions.currPage = action.payload.newPage;
      })
      .addCase(fetchCommunitySubmissionsData.rejected, (state, action) => {
        state.communitySubmissions.status = "error";
        state.communitySubmissions.error = action.error.message;
        toast.error(action.error.message);
      })

      .addCase(fetchNotesData.pending, (state, action) => {
        state.notes.status = "loading";
      })
      .addCase(fetchNotesData.fulfilled, (state, action) => {
        state.notes.status = "idle";
        state.notes.data = action.payload;
      })
      .addCase(fetchNotesData.rejected, (state, action) => {
        state.notes.status = "error";
        state.notes.error = action.error.message;
      })
      .addCase(saveUserCode.pending, (state, action) => {
        state.savedCode.isUserCodeSaved = false;
      })
      .addCase(saveUserCode.fulfilled, (state, action) => {
        state.savedCode.isUserCodeSaved = true;
      })
      .addCase(saveUserCode.rejected, (state, action) => {
        state.savedCode.error = action.error.message;
        toast.error("Faild to save code");
      });
  },
});

export default currentProblemSlice.reducer;
export const {
  unlockHint1,
  unlockHint2,
  unlockSolutionApproach,
  unlockCompleteSolution,
  updateCode,
  updateLanguage,
  updateTestCase,
  handleEvaluationType,
  cleanDescription,
  cleanSolutions,
  resetTestCase,
  setResultTabKey,
  setDescriptionTabKey,
  setCodeRunMode,
  setIsCodeOutputActive,
  currentProblemCleanup,
  setIsSettingModelActive,
  setSaveCode,
  cleanCommunitySubmissions,
  setCommunitySubmissionAccesedStatus,
} = currentProblemSlice.actions;
