import { AiFillCode } from "react-icons/ai";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { IoExtensionPuzzleSharp } from "react-icons/io5";
import { HiOutlineLink } from "react-icons/hi2";
import { MdLiveTv } from "react-icons/md";
export const OFFERING_ICON = {
  problem: <AiFillCode size={20} />,
  lecture: <BsFillPlayCircleFill size={20} />,
  mcq: <IoExtensionPuzzleSharp size={20} />,
  externalProblem: <HiOutlineLink size={20} />,
  live: <MdLiveTv size={20} />,
};

export const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZone: "Asia/Kolkata",
};

export const NOTES_RESOURCE_TYPE = {
  BATCH: "batch",
  CONTEST: "contest",
  MOCK_TEST: "mockTest",
};
