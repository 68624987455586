import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useSlugId } from "../../../helper/restructureData";
import { fetchProblems } from "../../../redux/slices/ProblemListSlice";
import {
  VALID_EXPLORE_PROBLEM_TAB,
  VALID_PARAMS,
  difficultyDataIndex,
  problemTypes,
} from "./constant";
let TIMEOUT_ID;
export function useFetchProblems(
  activeFilterValues,
  batchSlug,
  page,
  currentTab
) {
  const batchId = useSlugId(batchSlug);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const sortParams = searchParams.get("sortBy");
  const search = searchParams.get("search");
  const topicFilterParams = searchParams.getAll(VALID_PARAMS["topics"]);
  let sort;
  let isDefaultSort;
  let difficultyParams = searchParams.getAll(VALID_PARAMS["difficulty"]);
  let problemTypeParams = searchParams.getAll(VALID_PARAMS["problemType"]);
  difficultyParams = difficultyParams.map(
    (value) => difficultyDataIndex[value]
  );
  problemTypeParams = problemTypeParams.map((value) => problemTypes[value]);

  if (sortParams) {
    const [sortBy, value] = sortParams.split("-");
    isDefaultSort = sortBy === "createdAt" && value === "desc";
    sort = { [sortBy]: value };
  }
  const fetchFilteredProblem = useCallback(() => {
    dispatch(
      fetchProblems({
        batchId,
        sort,
        topicArray: topicFilterParams,
        difficultyArray: difficultyParams,
        problemTypeArray: problemTypeParams,
        search,
        page,
        tab: VALID_EXPLORE_PROBLEM_TAB[currentTab],
      })
    );
  }, [activeFilterValues, sort]);

  useEffect(() => {
    if (!sort) return;
    if (
      [...difficultyParams, ...problemTypeParams, ...topicFilterParams].length >
        0 ||
      search ||
      !isDefaultSort
    ) {
      TIMEOUT_ID = setTimeout(() => {
        fetchFilteredProblem();
      }, 500);
    } else {
      fetchFilteredProblem();
    }
    return () => TIMEOUT_ID && clearTimeout(TIMEOUT_ID);
  }, [page, searchParams, currentTab]);
}
