export function updateExistingJobData(data, targetId, status) {
  const dataSet = JSON.parse(JSON.stringify(data));
  if (dataSet.length > 0) {
    const updatedData = dataSet.map((data) => {
      if (targetId === data.id) {
        data.hasBookmarked = status;
      }
      return data;
    });
    return updatedData;
  }
}
