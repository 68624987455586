import React from "react";
import { useSelector } from "react-redux";
import CompanyLogo from "../../img/logo/companyLogo.png";
import "./loader.css";
const Loader = () => {
  const isDarkMode = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.isDarkMode
  );
  return (
    <div className={`loader-parent flex ${!isDarkMode ? "light" : "dark"}`}>
      <div className="loader-logo-container">
        <img src={CompanyLogo} alt="companylogo" />
      </div>
    </div>
  );
};

const Spinner = (props) => {
  const { size } = props;
  const spinnerStyle = {
    height: size || "20px",
    width: size || "20px",
  };
  return (
    <div className="loading__spinner__wrapper flex">
      <span style={spinnerStyle} className="loading__spinner"></span>
    </div>
  );
};

export { Spinner };
export default Loader;
