import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchFilteredTransaction } from "../../../../redux/slices/adminSlice";
import TransactionDetails from "../lib/ui/TransactionDetails";
import { dropdownDataset } from "../utils/constants";

export function restructureMetaData(metaData) {
  const dropdownDataClone = JSON.parse(JSON.stringify(dropdownDataset));
  for (let dropdownData of dropdownDataClone) {
    const metaOptions = metaData[dropdownData.label];
    if (!metaOptions) continue;
    if (
      !metaData.isAdmin &&
      (dropdownData.label === "batches" || dropdownData.label === "bundles")
    ) {
      if (!metaData.salesTeamAllowedBatches[dropdownData.label]) continue;
      const filteredAllowedBatchesOrBundles = (metaOptions || []).filter(
        (resource) =>
          metaData.salesTeamAllowedBatches[dropdownData.label].includes(
            resource.id
          )
      );
      dropdownData.options = filteredAllowedBatchesOrBundles;
    } else {
      dropdownData.options = metaOptions;
    }
  }
  return dropdownDataClone;
}

export function restructureTransactionData(dataSet) {
  const restructuredData = dataSet.map((data) => {
    const dataTemplate = {
      id: data.id,
      batchName: data?.batch?.name || data?.batchBundle?.name,
      launchDate: data.batch?.launchDate || "N/A",
      batchPrice: `₹${
        (data?.batch || data?.batchBundle)?.price?.toLocaleString("en-IN") ||
        "N/A"
      }`,
      couponTitle: data.couponDiscount && data.couponDiscount?.title,
      couponDiscount: data.couponDiscount
        ? {
            title: data.couponDiscount?.title || "N/A",
            description: data.couponDiscount?.description,
            amount: `₹${
              data.couponDiscount?.amount?.toLocaleString("en-IN") || "N/A"
            }`,
          }
        : null,
      earlyBirdDiscount: data.earlyBirdDiscount
        ? {
            title: data.earlyBirdDiscount?.title,
            description: data.earlyBirdDiscount?.description,
            amount: `₹${
              data.earlyBirdDiscount?.amount?.toLocaleString("en-IN") || "N/A"
            }`,
          }
        : null,
      orderId: data.orderId,
      paidAt: data.paidAt
        ? new Date(data.paidAt).toLocaleString("en-IN")
        : new Date(data.updatedAt).toLocaleString("en-IN"),
      transactionId: data.paymentId,
      status: (
        <span className={`${data.status} transaction__status`}>
          {data.status}
        </span>
      ),
      price: `₹${data.price?.toLocaleString("en-IN") || 0}`,
      photo: data.user?.photo,
      name: data.user?.name,
      email: data.user?.email,
    };
    return {
      ...dataTemplate,
      action: <TransactionDetails user={dataTemplate} />,
    };
  });
  return restructuredData;
}
export function useFetchTransaction(filters) {
  let TIMEOUT_ID;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      searchParams.get("page") ||
      searchParams.get("search") ||
      filters.length > 0
    ) {
      TIMEOUT_ID = setTimeout(() => {
        dispatch(
          fetchFilteredTransaction({
            batchIds: searchParams
              .getAll("batches")
              .map((batch) => batch.split("_")[1]),
            bundleIds: searchParams
              .getAll("bundles")
              .map((bundle) => bundle.split("_")[1]),
            couponCodes: searchParams.getAll("couponCodes"),
            paymentStatuses: searchParams.getAll("status"),
            startDate:
              searchParams.get("startDate") &&
              new Date(searchParams.get("startDate")).valueOf(),
            endDate:
              searchParams.get("endDate") &&
              new Date(searchParams.get("endDate")).valueOf(),
            page: searchParams.get("page"),
            search: searchParams.get("search"),
          })
        );
      }, 1000);
    } else {
      dispatch(fetchFilteredTransaction({}));
    }
    return () => clearTimeout(TIMEOUT_ID);
  }, [searchParams.get("page"), searchParams.get("search"), filters]);
}
