import React, { useState } from "react";
import { DialogModal } from "../job-portal/components/ui/Modal";
import PhoneVerification from "./PhoneVerification";

const PhoneVerificationDialog = ({ onPhoneVerifiedSuccess }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  return (
    <DialogModal
      isDisabled={true}
      type="default"
      isOpen={isDialogOpen}
      overlayClassName="bg-opacity-80"
      wrapperClassName="max-w-[740px]"
      hasTheme
    >
      <PhoneVerification
        setIsDialogOpen={setIsDialogOpen}
        onPhoneVerifiedSuccess={onPhoneVerifiedSuccess}
      />
    </DialogModal>
  );
};

export default PhoneVerificationDialog;
