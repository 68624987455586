import { DEFAULT_SORTBY } from "../../explore-problems/Imports";

export const DISCORD_LINK = "https://discord.gg/eXh7mjRq4W";

export const DISCORD_DATA_INDEX = "discord";
export const QUICK_COMPILER_DATA_INDEX = "quickCompiler";
export const sidebarLinksData = [
  {
    id: "1",
    label: "Student Panel",
    tabs: [
      {
        id: 1,
        text: "Dashboard",
        generateHref: (batchId) => `/classroom/${batchId}/dashboard`,
        href: "",
        icon: "dashboard",
        isLocked: false,
        activeHref: "/dashboard",
        dataIndex: "dashboard",
      },
      {
        id: 2,
        text: "Problems",
        generateHref: (batchId) =>
          `/classroom/${batchId}/problems?sortBy=${DEFAULT_SORTBY.type}-${DEFAULT_SORTBY.value}`,
        href: "",
        icon: "code",
        isLocked: false,
        activeHref: "/problems",
        dataIndex: "problems",
        resourcekey: "allProblemsTabKey",
      },
      {
        id: 3,
        text: "Leaderboard",
        generateHref: (batchId) => `/classroom/${batchId}/leaderboard`,
        href: "",
        icon: "trophy",
        isLocked: false,
        activeHref: "/leaderboard",
        dataIndex: "leaderboard",
        resourcekey: "leaderboardTabKey",
      },
      {
        id: 4,
        text: "Notes",
        generateHref: (batchId) => `/classroom/${batchId}/allnotes`,
        href: "",
        icon: "note",
        isLocked: false,
        activeHref: "/allnotes",
        dataIndex: "allnotes",
        resourcekey: "notesTabKey",
      },
    ],
  },
  {
    id: "2",
    label: "Exclusive",
    tabs: [
      {
        id: 5,
        text: "Contest",
        generateHref: (batchId) => `/classroom/${batchId}/contest`,
        href: "",
        icon: "award",
        isLocked: false,
        activeHref: "/contest",
        dataIndex: "contest",
        resourcekey: "constestTabKey",
      },
      {
        id: 6,
        text: "Job Portal",
        generateHref: (batchId) => `/classroom/${batchId}/jobs`,
        href: "",
        icon: "job",
        isLocked: false,
        activeHref: "/jobs",
        dataIndex: "job portal",
        resourcekey: "jobPortalTabKey",
      },
      {
        id: 7,
        text: "Webinars",
        generateHref: (batchId) => `/classroom/${batchId}/events`,
        href: "",
        icon: "event",
        isLocked: false,
        activeHref: "/events",
        dataIndex: "webinars",
        resourcekey: "webinarsTabKey",
      },
      {
        id: 8,
        text: "Mock Series",
        generateHref: (batchId) => `/classroom/${batchId}/mock-series`,
        href: "",
        icon: "mockSeries",
        isLocked: false,
        activeHref: "/mock-series",
        dataIndex: "mock-series",
        resourcekey: "mockTestsTabKey",
      },
    ],
  },
  {
    id: "3",
    label: "General",
    tabs: [
      {
        id: 9,
        text: "Quick Compiler",
        href: `${process.env.REACT_APP_LANDING_PAGE_URL}/compilers`,
        target: "_blank",
        icon: "codeBox",
        isLocked: false,
        dataIndex: QUICK_COMPILER_DATA_INDEX,
        resourcekey: "quickCompilerTabKey",
      },
    ],
  },
  {
    id: "4",
    label: "Community",
    tabs: [
      {
        id: 10,
        text: "Join Discord",
        href: DISCORD_LINK,
        icon: "discord",
        target: "_blank",
        isLocked: false,
        dataIndex: DISCORD_DATA_INDEX,
      },
    ],
  },
];

export const filtersMetaData = [
  {
    id: 1,
    label: "Type of Employment",
    dataIndex: "jobType",
    options: [
      {
        id: 1,
        value: "full-Time",
        jobCount: 2,
        label: "Full-Time",
        isChecked: false,
        name: "jobType",
      },
      {
        id: 2,
        value: "part-Time",
        jobCount: 6,
        label: "Part-Time",
        isChecked: false,
        name: "jobType",
      },
      {
        id: 3,
        value: "remote",
        jobCount: 8,
        label: "Remote",
        isChecked: false,
        name: "jobType",
      },
      {
        id: 4,
        value: "internship",
        jobCount: 42,
        label: "Internship",
        isChecked: false,
        name: "jobType",
      },
      {
        id: 5,
        value: "contract",
        jobCount: 9,
        label: "Contract",
        isChecked: false,
        name: "jobType",
      },
    ],
  },
  {
    id: 2,
    label: "Job Level",
    dataIndex: "jobLevel",
    options: [
      {
        id: 6,
        value: "entry Level",
        jobCount: 11,
        label: "Entry Level",
        isChecked: false,
        name: "jobLevel",
      },
      {
        id: 7,
        value: "mid Level",
        jobCount: 21,
        label: "Mid Level",
        isChecked: false,
        name: "job Level",
      },
      {
        id: 8,
        value: "senior Level",
        jobCount: 24,
        label: "Senior Level",
        isChecked: false,
        name: "jobLevel",
      },
      {
        id: 9,
        value: "director",
        jobCount: 4,
        label: "Director",
        isChecked: false,
        name: "jobLevel",
      },
    ],
  },
];

export const sortOptions = [
  {
    label: "Featured",
    id: 1,
    sortType: {},
    isChecked: false,
  },
  {
    label: "Latest",
    id: 2,
    isChecked: true,
    sortType: { createdAt: "DESC" },
  },
  {
    label: "Oldest",
    id: 3,
    isChecked: false,
    sortType: { createdAt: "ASC" },
  },
];

export const RESOURCE_TYPE_DEAFULT = "allJobs";
export const RESOURCE_TYPE_APPLIED = "appliedJobs";
export const RESOURCE_TYPE_BOOKMARK = "bookmarkedJobs";
export const jobPortalTabs = [
  {
    id: 1,
    label: "All Jobs",
    value: RESOURCE_TYPE_DEAFULT,
  },
  {
    id: 2,
    label: "Saved Jobs",
    value: RESOURCE_TYPE_BOOKMARK,
  },
  {
    id: 3,
    label: "Applied Jobs",
    value: RESOURCE_TYPE_APPLIED,
  },
];

export const JOBS_TYPE = {
  [RESOURCE_TYPE_DEAFULT]: "All",
  [RESOURCE_TYPE_APPLIED]: "Applied",
  [RESOURCE_TYPE_BOOKMARK]: "Saved",
};

export const APPLIED_TEXT = "Applied";
export const BOOKMARK_STATUS = "bookmarkStatus";
export const APPLIED_STATUS = "appliedStatus";
export const DEFAULT_PAGE = 1;

export const NOT_FOUND_TYPE = {
  [RESOURCE_TYPE_DEAFULT]: "Jobs Not Found.",
  [RESOURCE_TYPE_BOOKMARK]: "No Saved Jobs.",
  [RESOURCE_TYPE_APPLIED]: "No Applied Jobs.",
};

export const searchOptionList = [
  "Delhi / NCR",
  "Pune",
  "Bangalore",
  "Hyderabad",
  "Chennai",
  "Mumbai",
  "Gurgaon/Gurugram",
  "Noida",
  "New Delhi",
  "Kolkata",
  "Ahmedabad",
  "Kochi/Cochin",
  "Coimbatore",
  "Jaipur",
  "Thiruvananthapuram",
  "Indore",
  "Chandigarh",
  "Mohali",
  "Vadodara/Baroda",
  "Surat",
  "Nagpur",
  "Navi Mumbai",
  "Thane",
];
