import { useGoogleOneTapLogin } from "@react-oauth/google";
import React from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { updateRedirectionUrl } from "../redux/slices/appConfigSlice";
import { useDispatch } from "react-redux";
const GoogleOneTapLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      dispatch(updateRedirectionUrl(location.pathname));
      navigate(
        `/connect/google/redirect?id_token=${credentialResponse.credential}`
      );
    },
    onError: () => {
      toast.error("Login Failed");
    },
  });
  return <></>;
};

export default GoogleOneTapLogin;
