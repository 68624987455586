import { Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Description } from "../../job-portal/components/ui/Typography";
import { InfoTooltip } from "../../job-portal/components/ui/Tooltip";
import { IconWrapper } from "../Imports";
import { getOfferingRoute } from "../helpers/restructureProblems";
import { cn } from "../../classroomHeader/imports";
export const EllipseText = (props) => {
  const { title, className } = props;
  const siteThemeStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isDarkMode
  );
  const titleRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  useEffect(() => {
    if (
      titleRef.current &&
      titleRef.current.offsetWidth < titleRef.current.scrollWidth
    ) {
      setIsEllipsisActive(true);
    }
  }, []);
  return isEllipsisActive ? (
    <Tooltip
      title={title}
      placement="topLeft"
      arrow={false}
      overlayInnerStyle={{ fontSize: "12px", fontWeight: "500" }}
      overlayClassName={`${
        !siteThemeStatus ? "white-theme" : ""
      } problems-title-tooltip`}
      overlayStyle={{ width: titleRef.current?.offsetWidth }}
    >
      <Description
        weight="md"
        size="sm"
        className={cn("truncate", className)}
        ref={titleRef}
      >
        {title}
      </Description>
    </Tooltip>
  ) : (
    <Description
      weight="md"
      size="sm"
      className={cn("truncate", className)}
      ref={titleRef}
    >
      {title}
    </Description>
  );
};
const ProblemTitle = (props) => {
  const { batchSlug, title, type, slug, isImportant, weekSlug } = props;
  return (
    <div className="items-center w-full gap-1.5 t-flex">
      <Link
        to={getOfferingRoute(batchSlug, type, slug, weekSlug)}
        className="[&>p]:hover:text-primary-500 transition-colors"
        style={{ maxWidth: isImportant ? "calc(100% - 56px)" : "100%" }}
      >
        <EllipseText title={title} />
      </Link>
      {isImportant && (
        <InfoTooltip tooltipText="Important">
          <IconWrapper icon="crown" className="text-primium-400" size="18" />
        </InfoTooltip>
      )}
    </div>
  );
};

export default ProblemTitle;
