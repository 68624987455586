import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/appConfigSlice";
import { resetPersistedValues } from "../redux/slices/quickCompilerSlice";
import { KEY_JWT, removeItem } from "../utils/localStorageManager";
import { IconWrapper } from "./common/import";
import Dropdown2 from "./job-portal/components/ui/Dropdown2";
import { Text, Title } from "./job-portal/components/ui/Typography";
import "./job-portal/layouts/classroom.tailwind.scss";
import fallbackProfile from "../img/defaultprofile.png";
const TriggerNode = (props) => {
  const { onClick } = props;
  const user = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.user
  );
  return (
    <div>
      <button
        onClick={onClick}
        className="flex"
        aria-label="profile-model-open-close-button"
      >
        <img
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackProfile;
          }}
          className={`object-cover w-9 h-9 rounded-full transition-opacity duration-500 ${
            user ? "opacity-100" : "opacity-0"
          }`}
          src={user?.photo}
          alt={user?.name}
        />
      </button>
    </div>
  );
};

const NavigationButton = (props) => {
  const { href, icon, label, redirectHandler, setIsDropdownOpen } = props;
  return (
    <div>
      <button
        onClick={() => redirectHandler(href, setIsDropdownOpen)}
        className="items-center w-full gap-2 p-2 rounded t-flex hover:bg-neutral-400"
      >
        <IconWrapper className="text-primaryText" size="20" icon={icon} />
        <Text size="sm" weight="normal">
          {label}
        </Text>
      </button>
    </div>
  );
};

const DropdownNavigationContent = (props) => {
  const { user, userNavigationLinks, redirectHandler, setIsDropdownOpen } =
    props;
  return (
    <div className="w-[220px]">
      <div className="items-center gap-2 px-2 t-flex">
        <div className="w-12 h-12 shrink-0">
          <img
            className="object-cover w-full h-full rounded-full "
            src={user?.photo}
            alt={user?.name}
          />
        </div>
        <div className="flex-col items-start gap-0.5 t-flex">
          <div>
            <Title level="h4" size="sm" weight="md" className="line-clamp-2">
              {user?.name}
            </Title>
          </div>
          <div>
            <Text
              variant="secondary"
              size="xs"
              weight="sm"
              className="line-clamp-1"
            >
              {`@${user?.username}`}
            </Text>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-full bg-neutral-500 my-3"></div>
      <div className="flex-col gap-1 t-flex">
        {userNavigationLinks.map((link) => (
          <NavigationButton
            key={link.id}
            {...link}
            redirectHandler={redirectHandler}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        ))}
      </div>
    </div>
  );
};
const UserNavigationMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isDarkMode = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.isDarkMode
  );
  const user = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.user
  );
  function redirectHandler(redirectPath, setIsDropdwonOpen) {
    typeof redirectPath === "string" ? navigate(redirectPath) : redirectPath();
    setIsDropdwonOpen(false);
  }

  const handleLogout = useCallback(async () => {
    dispatch(logout());
    navigate("/login", { replace: true });
    removeItem(KEY_JWT);
    dispatch(resetPersistedValues());
  }, []);
  const userNavigationLinks = [
    {
      id: 1,
      label: "My Profile",
      icon: "user",
      href: `/profile/${user?.username}`,
    },
    {
      id: 2,
      label: "My Purchases",
      icon: "purchases",
      href: "/account/purchase-history",
    },
    {
      id: 3,
      label: "Settings",
      icon: "settings",
      href: "/account/edit-profile",
    },
    {
      id: 4,
      label: "Log out",
      icon: "logout",
      href: handleLogout,
    },
  ];

  return (
    <div
      className={
        pathname.includes("/classroom") && isDarkMode ? "" : "white-theme"
      }
    >
      <Dropdown2
        text="hello"
        contentPosition={{ right: "0" }}
        TriggerComponent={TriggerNode}
        contentLayoutStyle="px-2"
      >
        <DropdownNavigationContent
          user={user}
          userNavigationLinks={userNavigationLinks}
          redirectHandler={redirectHandler}
        />
      </Dropdown2>
    </div>
  );
};

export default UserNavigationMenu;
