export const contestQuestionsTableHeader = [
  {
    id: 0,
    label: "S.No.",
    dataIndex: "index",
    size: "7%",
  },
  {
    id: 1,
    label: "Name of Problem",
    dataIndex: "title",
    size: "300px",
  },
  {
    id: 2,
    label: "Type",
    dataIndex: "type",
    size: "7%",
  },
  {
    id: 3,
    label: "Difficulty",
    dataIndex: "difficulty",
    size: "12%",
  },
  {
    id: 4,
    label: "Max Score",
    dataIndex: "maxScore",
    size: "12%",
  },
  {
    id: 5,
    label: "Status",
    dataIndex: "status",
    size: "15%",
  },
  {
    id: 6,
    label: "Actions",
    dataIndex: "actions",
    size: "12%",
  },
];

export const contestLeaderboardHeader = [
  {
    id: 0,
    label: "Rank",
    dataIndex: "rank",
    size: "10%",
  },
  {
    id: 1,
    label: "User",
    dataIndex: "user",
    size: "250px",
  },
  {
    id: 2,
    label: "Score",
    dataIndex: "score",
    size: "15%",
  },
  {
    id: 3,
    label: "Completion Time",
    dataIndex: "completionTime",
    size: "15%",
  },
  {
    id: 4,
    label: "Completion Time With Penalty",
    dataIndex: "completionTimeWithPenalty",
    size: "15%",
  },
  {
    id: 5,
    label: "Wrong Answers Attempts",
    dataIndex: "wrongAnswerAttempts",
    size: "15%",
  },
];
