const COOKIE_DOMAIN =
  process.env.REACT_APP_NODE_ENV === "development"
    ? "localhost"
    : "codingshuttle.com";
export const KEY_JWT = "jwt";
export const KEY_USERNAME = "username";
export const KEY_SITE_THEME = "site_theme";
export const KEY_CODE_EDITOR_THEME = "code_theme";

export function getItem(key) {
  return document.cookie
    ?.split("; ")
    ?.find((cookie) => cookie?.startsWith(key))
    ?.split("=")[1];
}
export function setItem(key, value) {
  const cookieConfig = [`${key}=${value}`, `domain=${COOKIE_DOMAIN}`, "Path=/"];
  document.cookie = cookieConfig.join(";");
}
export function removeItem(key) {
  const cookieConfig = [
    `${key}=`,
    `domain=${COOKIE_DOMAIN}`,
    "Path=/",
    `max-age=0`,
  ];
  document.cookie = cookieConfig.join(";");
}
