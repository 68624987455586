import React from "react";
import { GrFormClose } from "react-icons/gr";
import "../styles/transaction-model.scss";
const TransactionModel = ({ setIsModelActive, data }) => {
  function modalHandler() {
    setIsModelActive(false);
  }
  return (
    <div className="admin__model__container flex">
      <div
        className="model__overlay"
        role="button"
        onClick={modalHandler}
      ></div>
      <div className="model__content__container">
        <div className="model__header flex">
          <div className="heading">
            <h2 className="admin__panel__secondary__heading">
              Transaction Details
            </h2>
          </div>
          <button className="flex admin__hover__button" onClick={modalHandler}>
            <GrFormClose />
          </button>
        </div>
        <div className="model__body">
          <div className="user__details flex">
            <div className="user__image flex">
              <img src={data?.photo} alt="user-profile" />
            </div>
            <div className="user__info">
              <h4 className="admin__panel__primary__text ">{data?.name}</h4>
              <p className="admin__panel__secondary__text">{data?.email}</p>
            </div>
          </div>
          <div className="order__details">
            <div className="heading">
              <h4 className="admin__panel__primary__heading">
                Order Details :
              </h4>
            </div>
            <div className="order__details__inner__wrapper">
              <div className="batch__details detail__container">
                <div className="heading">
                  <h4 className="admin__panel__primary__text">Batch Details</h4>
                </div>
                <div className="detail__wrapper flex">
                  <p className="admin__panel__secondary__text">Batch Name -</p>
                  <p className="admin__panel__primary__text">
                    {data.batchName}
                  </p>
                </div>
                <div className="detail__wrapper flex">
                  <p className="admin__panel__secondary__text">Batch Price -</p>
                  <p className="admin__panel__primary__text">
                    {data.batchPrice}
                  </p>
                </div>
                <div className="detail__wrapper flex">
                  <p className="admin__panel__secondary__text">Launched At -</p>
                  <p className="admin__panel__primary__text">
                    {data.launchDate}
                  </p>
                </div>
              </div>
              <div className="transaction__details detail__container">
                <div className="heading">
                  <h4 className="admin__panel__primary__text">
                    Transaction Details
                  </h4>
                </div>
                <div className="detail__wrapper flex">
                  <p className="admin__panel__secondary__text">Order ID -</p>
                  <p className="admin__panel__primary__text">{data.orderId}</p>
                </div>
                <div className="detail__wrapper flex">
                  <p className="admin__panel__secondary__text">
                    Transaction ID -
                  </p>
                  <p className="admin__panel__primary__text">
                    {data.transactionId}
                  </p>
                </div>
                <div className="detail__wrapper flex">
                  <p className="admin__panel__secondary__text">Paid At -</p>
                  <p className="admin__panel__primary__text">{data.paidAt}</p>
                </div>
                <div className="detail__wrapper flex">
                  <p className="admin__panel__secondary__text">Status -</p>
                  <span className={data.status}>{data.status}</span>
                </div>
              </div>
              {data.earlyBirdDiscount || data.couponDiscount ? (
                <div className="discount__details detail__container">
                  <div className="heading">
                    <h4 className="admin__panel__primary__text">
                      Discount Details
                    </h4>
                  </div>
                  {data.earlyBirdDiscount && (
                    <div className="detail__wrapper flex">
                      <p className="admin__panel__secondary__text">
                        Early Bird Discount -
                      </p>
                      <p className="admin__panel__primary__text">
                        {data?.earlyBirdDiscount?.amount}
                      </p>
                    </div>
                  )}
                  {data.couponDiscount && (
                    <div className="detail__wrapper flex">
                      <p className="admin__panel__secondary__text">
                        {`${data.couponDiscount.title} Coupon Code -`}
                      </p>
                      <p className="admin__panel__primary__text">
                        {data.couponDiscount.amount}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="divider"></div>
              <div className="total__price flex">
                <div className="heading">
                  <h4 className="admin__panel__secondary__heading">
                    Total Amount
                  </h4>
                </div>
                <h4 className="admin__panel__secondary__heading">
                  {data.price}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionModel;
