import React from "react";
import IconWrapper from "./IconWrapper";
import { Pagination } from "antd";
import "./style.scss";
export const PAGE_DATA_LIMIT = 50; // per page item limit

export const CSPagination = ({
  totalEntries,
  onPageChange,
  currentPage,
  disabled,
  limit = PAGE_DATA_LIMIT,
}) => {
  return (
    <Pagination
      showSizeChanger={false}
      nextIcon={<IconWrapper icon="dropRight" />}
      prevIcon={<IconWrapper icon="leftArrow" />}
      current={currentPage}
      total={totalEntries}
      onChange={onPageChange}
      pageSize={limit}
      disabled={disabled}
    />
  );
};

export default CSPagination;
