import React, { useState } from "react";
import { Description } from "../../user-profile/imports";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../contest/components/ConfirmationModal";
import { axiosClient } from "../../../utils/axiosClient";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { useSlugId } from "../../../helper/restructureData";
import toast from "react-hot-toast";
import { IconWrapper, Text, Title } from "../../../components/common/import";
import { EllipseText } from "../../../components/explore-problems/components/ProblemTitle";
import {
  difficultyType,
  difficultyTypeColorVariant,
} from "../../../components/explore-problems/helpers/constant";
import { Badge } from "../../user-profile/imports";
import { DialogModal } from "../../../components/job-portal/components/ui/Modal";
import { SpinnerLoadingPage } from "../../contest/components/ContestLoading";
import { getProblemStatus } from "../../contest/utils/restructureData";

const MockTestQuestionCard = (props) => {
  const { problem, difficulty, counter, isAccepted, index, slug, closeModal } =
    props;
  const { mockTestSlug, batchSlug } = useParams();

  return (
    <NavLink
      to={`/classroom/${batchSlug}/mock-series/${mockTestSlug}/problems/${slug}`}
      onClick={closeModal}
      className="t-flex [&.active]:bg-neutral-400 items-center group  gap-0.5 hover:bg-neutral-400 py-3 px-2 [&:not(:last-child)]:border-b border-b-border-300"
    >
      <div className="w-full t-flex gap-0.5">
        <div>
          <Text
            size="sm"
            weight="md"
            className="transition-colors duration-150 group-hover:text-blue-500 group-[&.active]:text-blue-500"
          >
            {`Q${index}.`}
          </Text>
        </div>
        <div className="flex-col gap-1 t-flex">
          <div className="max-w-[310px]">
            <EllipseText
              title={problem.title}
              className="duration-150 text-primaryText group-hover:text-blue-500 group-[&.active]:text-blue-500"
            />
          </div>

          <div className="items-center t-flex">
            <Badge
              size="xxs"
              variant="secondary"
              className={`${difficultyTypeColorVariant[difficulty]} w-max`}
              border="normal"
            >
              {difficultyType[difficulty]}
            </Badge>
            <IconWrapper icon="dot" size="18" className="text-primaryText" />

            <Badge
              size="xxs"
              variant="secondary"
              className={`capitalize ${
                difficultyTypeColorVariant[
                  getProblemStatus(isAccepted, counter)
                ]
              } w-max`}
              border="normal"
            >
              {getProblemStatus(isAccepted, counter)}
            </Badge>
          </div>
        </div>
      </div>
      <div>
        <IconWrapper
          icon="dropRight"
          size="25"
          className="duration-150 text-primaryText group-hover:text-blue-500 group-[&.active]:text-blue-500"
        />
      </div>
    </NavLink>
  );
};
const QuestionModal = (props) => {
  const { setIsOpen } = props;
  const { status, data, mockTestOfferings } = useSelector(
    (state) => state.mockSeriesReducer.mockSeriesConfig
  );

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="max-w-[420px] w-[420px] bg-neutral-100 h-screen shadow-md sticky top-0 overflow-y-auto">
      <div className="flex-col w-full t-flex">
        <div className="sticky top-0 items-center justify-between px-4 py-4 bg-navbar-exception-hover t-flex">
          <Title size="md" level="h3">
            Mock Test Questions
          </Title>
          <button
            className="items-center justify-center transition-colors duration-150 group"
            onClick={closeModal}
          >
            <IconWrapper
              icon="close"
              size="25"
              className="transition-colors duration-150 group-hover:text-blue-500 text-primaryText"
            />
          </button>
        </div>
        {data && data.mockTest && (
          <div className="w-full px-2 pt-4 pb-4">
            <div className="flex-col border rounded t-flex border-border-500">
              {mockTestOfferings.map((offering, index) => (
                <MockTestQuestionCard
                  index={index + 1}
                  key={offering.id}
                  {...offering}
                  closeModal={closeModal}
                />
              ))}
            </div>
          </div>
        )}
        {status === "loading" && (
          <div className="w-[420px]">
            <SpinnerLoadingPage />
          </div>
        )}
      </div>
    </div>
  );
};

const MockTestQuestionModal = () => {
  const { batchSlug, mockTestSlug, offeringSlug } = useParams();
  const batchId = useSlugId(batchSlug);
  const mockTestId = useSlugId(mockTestSlug);
  const navigate = useNavigate();
  const { status, data, hasMockTestEnded } = useSelector(
    (state) => state.mockSeriesReducer.mockSeriesConfig
  );
  const [isLoading, setIsLoading] = useState(false);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  async function endMockTestHandler(closeModal) {
    try {
      setIsLoading(true);
      await axiosClient(`/classroom/${batchId}/mocktest/${mockTestId}/end`);
      closeModal();
      navigate(`/classroom/${batchSlug}/mock-series`);
      toast.success("The mock test is successfully ended");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    status === "idle" &&
    data && (
      <div className="items-center gap-6 t-flex">
        <div className="flex-col px-4 py-2 border border-blue-600 rounded dark:border-border-500 bg-navbar-exception-hover t-flex">
          <Description size="xs" weight="md" variant="secondary">
            {data?.mockTest?.title}
          </Description>
          {offeringSlug && (
            <>
              <button
                onClick={() => setQuestionModalOpen(true)}
                className="items-center justify-center mx-auto text-xs font-medium text-blue-500 underline w-max t-flex"
              >
                Test Series <IconWrapper icon="dropdown" size="14" />
              </button>
              <DialogModal
                type="sidebar"
                isOpen={questionModalOpen}
                setIsOpen={setQuestionModalOpen}
                hasTheme
              >
                <QuestionModal setIsOpen={setQuestionModalOpen} />
              </DialogModal>
            </>
          )}
        </div>
        {!hasMockTestEnded && (
          <ConfirmationModal
            isLoading={isLoading}
            onConfirm={endMockTestHandler}
            actionButtonText="End Mock Test"
            confirmButtonText="Submit & End Now"
            modalDescription={`This action <b className="font-semibold">cannot be undo</b>.
                 This will <b className="font-semibold">End the Mock Test</b> and
                 You <b className="font-semibold">won't</b> be able to continue
                 solving questions.`}
          />
        )}
      </div>
    )
  );
};

export default MockTestQuestionModal;
