import ProblemTitle from "../explore-problems/components/ProblemTitle";
import Skeleton from "../job-portal/components/ui/Skeleton";
import { OFFERING_ICON_VARIANT, offeringTypeIcon } from "./constant";
import { IconWrapper } from "./import";
export function getCommonFieldsInOfferingsJSX(data, batchSlug, weekSlug) {
  return {
    id: data.slug,
    status: (
      <div>
        <IconWrapper
          icon="circleCheck"
          strokeWidth="2.2"
          className={
            data.hasCompleted ? "text-success-400" : "text-neutral-600"
          }
          size="18"
        />
      </div>
    ),
    title: <ProblemTitle {...data} batchSlug={batchSlug} weekSlug={weekSlug} />,
    type: (
      <div className="t-flex">
        <IconWrapper
          icon={offeringTypeIcon[data?.type]}
          style={{ color: `var(--t-${OFFERING_ICON_VARIANT[data?.type]})` }}
          strokeWidth="1"
          size="22"
        />
      </div>
    ),
  };
}
export const commonSkeletonTableLoading = {
  id: 1,
  status: <Skeleton variant="avatar" size="sm" />,
  title: <Skeleton variant="input" size="full" className="w-[90%]" />,
  type: <Skeleton variant="avatar" size="sm" />,
};
