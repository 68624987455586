import React from "react";
import { Skeleton } from "../../../components/common/import";
import SpinnerLoader from "../../../components/job-portal/components/ui/Loader";
import { HEADER_HEIGHT } from "../../../components/job-portal/layouts/ClassroomLayout";
import { cn } from "../../../components/classroomHeader/imports";

const ContestCardLoading = () => {
  return (
    <div className="overflow-hidden transition-all border rounded-lg shadow-sm group hover:shadow-md border-border-500">
      <div className="flex-col w-full t-flex">
        <div className="w-full ">
          <div className="overflow-hidden h-[160px]">
            <Skeleton variant="input" size="full" className="h-full" />
          </div>
        </div>
        <div className="flex-col gap-6 px-2.5 py-3.5 t-flex">
          <div className="flex-col gap-1 t-flex">
            <Skeleton variant="input" size="full" className="h-8" />
            <Skeleton variant="input" className="w-2/4 h-4" />
          </div>
          <div>
            <Skeleton variant="input" size="lg" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SpinnerLoadingPage = (props) => {
  const { className } = props;
  return (
    <div
      className={cn(
        `items-center justify-center h-[calc(100vh-${HEADER_HEIGHT}px)] t-flex`,
        className
      )}
    >
      <SpinnerLoader size={30} className="border-4" />
    </div>
  );
};
export default ContestCardLoading;
