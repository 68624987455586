import React from "react";
import notFoundImage from "../../../../img/confetti/empty-box.png";
import { cn } from "../../helpers/utils";
const NotFound = (props) => {
  const { text, textSize, wrapperClassName, className } = props;
  return (
    <div
      className={cn(
        `flex-col items-center justify-center gap-6 m-6 t-flex ${wrapperClassName}`
      )}
    >
      <div
        className={cn(`items-center justify-center w-52 t-flex ${className}`)}
      >
        <img src={notFoundImage} alt="not-found" className="max-sm:w-40" />
      </div>
      <div>
        <h2
          className={cn(
            `text-base text-center font-medium text-primaryText ${textSize}`
          )}
        >
          {text}
        </h2>
      </div>
    </div>
  );
};

export default NotFound;
