import React, { useEffect, useState } from "react";

function useScreenType(customThreshold) {
  const [screenType, setScreenType] = useState("");
  function checkScreenWidth() {
    const screenWidth = window.innerWidth;
    const desktopThreshold = customThreshold || 768; // Adjust this value based on your design considerations

    // Update screen type based on the width
    if (screenWidth >= desktopThreshold) {
      setScreenType("desktop");
    } else {
      setScreenType("mobile");
    }
  }
  useEffect(() => {
    // Event listener for window resize
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return screenType;
}
export const useIsWindowBelowWidth = (width) => {
  const [isWindowBelowWidth, setIsWindowBelowWidth] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      if (window && window.innerWidth <= width) {
        setIsWindowBelowWidth(true);
      } else {
        setIsWindowBelowWidth(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return isWindowBelowWidth;
};
export default useScreenType;
