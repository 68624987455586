import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import AllRoutes from "./AllRoutes";
import ReportBug from "./components/job-portal/components/common/ReportBug";
import { setAppConfig } from "./redux/slices/appConfigSlice";
function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.isLoggedIn
  );

  useEffect(() => {
    dispatch(setAppConfig());
  }, [isLoggedIn]);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <AllRoutes />
      <ReportBug />
    </div>
  );
}

export default App;
