import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import restructureData from "../../helper/restructureData";
import { axiosClient } from "../../utils/axiosClient";
import FilterData from "../../helper/filterData";
import toast from "react-hot-toast";
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE_LIMIT,
} from "../../components/explore-problems/Imports";
import {
  ASSIGNMENT_TAB,
  BOOKMARK_WEEK_DRIP_TYPE,
} from "../../components/week-drip/helpers/constant";
import { setUpdatedBookmarkedData } from "./dripSlice";
import {
  PROBLEM_TABS,
  VALID_EXPLORE_PROBLEM_TAB,
  problemTabBookmark,
} from "../../components/explore-problems/helpers/constant";
export const fetchProblems = createAsyncThunk(
  "problems/fetch",

  async ({
    batchId,
    sort,
    topicArray,
    difficultyArray,
    problemTypeArray,
    search,
    page,
    tab,
  }) => {
    const response = await axiosClient.post(
      `classroom/classes/${batchId}/allProblems`,
      {
        sort,
        topicArray,
        difficultyArray,
        problemTypeArray,
        search,
      },
      {
        params: { limit: DEFAULT_PER_PAGE_LIMIT, page, tab },
      }
    );
    return response;
  }
);
export const updateProblemBookmarkStatus = createAsyncThunk(
  "problem/bookmark",
  async (
    { batchId, offeringId, bookmarkStatus, type, currentTab },
    { getState, dispatch }
  ) => {
    const updatedProblemsData = [];
    const problemsList =
      type === BOOKMARK_WEEK_DRIP_TYPE
        ? getState().dripReducer.dripDataById.data[
            currentTab === ASSIGNMENT_TAB ? "assignmentData" : "homeworkData"
          ]
        : getState().problemsListReducer[problemTabBookmark[currentTab]];

    const data = await axiosClient.put(
      `/classroom/codeeditor/${batchId}/problems/${offeringId}/bookmark`,
      {
        bookmarked: bookmarkStatus,
      }
    );
    for (let i = 0; i < problemsList.length; i++) {
      const problem = { ...problemsList[i] };
      if (problem.id === offeringId) {
        problem.hasBookmarked = bookmarkStatus;
      }
      updatedProblemsData.push(problem);
    }
    if (type === BOOKMARK_WEEK_DRIP_TYPE) {
      dispatch(
        setUpdatedBookmarkedData({
          updatedProblemsData,
          updateDataType:
            currentTab === ASSIGNMENT_TAB ? "assignmentData" : "homeworkData",
        })
      );
    }
    return { data, updatedProblemsData };
  }
);
const initialState = {
  status: "loading",
  error: null,
  totalProblems: 0,
  problemsList: [],
  solvedProblemList: [],
  unsolvedProblemList: [],
  bookmarkedProblemList: [],
  bookmarkStatus: {
    status: "idle",
    error: null,
    data: null,
  },
};
const problemsListSlice = createSlice({
  name: "problemsListSlice",
  initialState,
  reducers: {
    resetProblemsState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProblems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProblems.fulfilled, (state, action) => {
        const page = action.meta.arg.page;
        const problemsArray = action.payload.data.allProblems;
        const data = restructureData(problemsArray);
        state.problemsList =
          action.meta.arg.tab === VALID_EXPLORE_PROBLEM_TAB.allProblems
            ? page === DEFAULT_PAGE
              ? data
              : [...state.problemsList, ...data]
            : state.problemsList;

        const { unsolvedProblems } = new FilterData(
          state.problemsList
        ).filterByUnsolvedStatus();

        state.solvedProblemList =
          action.meta.arg.tab === VALID_EXPLORE_PROBLEM_TAB.solvedProblems
            ? page === DEFAULT_PAGE
              ? data
              : [...state.solvedProblemList, ...data]
            : [];
        state.unsolvedProblemList = unsolvedProblems;
        state.bookmarkedProblemList =
          action.meta.arg.tab === VALID_EXPLORE_PROBLEM_TAB.boomarkedProblems
            ? page === DEFAULT_PAGE
              ? data
              : [...state.bookmarkedProblemList, ...data]
            : [];
        state.totalProblems = action.payload.data.totalEntries;
        state.status = "idle";
      })
      .addCase(fetchProblems.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
        toast.error(action.error.message);
      })
      .addCase(updateProblemBookmarkStatus.pending, (state, action) => {
        state.bookmarkStatus.status = "loading";
      })
      .addCase(updateProblemBookmarkStatus.fulfilled, (state, action) => {
        if (action.meta.arg.type !== BOOKMARK_WEEK_DRIP_TYPE) {
          state[problemTabBookmark[action.meta.arg.currentTab]] =
            action.payload.updatedProblemsData;

          if ((action.meta.arg.currentTab, PROBLEM_TABS[0].paramsValue)) {
            const { unsolvedProblems } = new FilterData(
              state.problemsList
            ).filterByUnsolvedStatus();
            state.unsolvedProblemList = unsolvedProblems;
          }
        }

        state.bookmarkStatus.status = "idle";
      })
      .addCase(updateProblemBookmarkStatus.rejected, (state, action) => {
        state.bookmarkStatus.status = "error";
        state.bookmarkStatus.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});
export const { resetProblemsState } = problemsListSlice.actions;
export default problemsListSlice.reducer;
