import { DATE_OPTIONS } from "../../../helper/GlobalVariables";
import { formatDuration } from "../../../utils/dateFormatter";
import { getCommonFieldsInOfferingsJSX } from "../../common/OfferingJsx";
import ActionButton from "../components/ActionButton";
import { Description, Skeleton } from "../import";

export function restructureLecturesData(lectures, week) {
  return lectures.map((lecture, idx) => {
    return {
      id: lecture.id,
      slug: lecture.slug,
      title: `${week}.${idx + 1} ` + lecture.video_lecture.title,
      duration: formatDuration(lecture.video_lecture.duration),
      type: lecture.offeringType,
      hasCompleted: lecture.hasWatched,
    };
  });
}

export function restructureLiveSessionData(liveSessions) {
  return liveSessions.map((liveSession) => {
    return {
      id: liveSession.id,
      slug: liveSession.slug,
      title: liveSession.title,
      duration: liveSession.duration
        ? formatDuration(liveSession.duration)
        : "_ _",
      type: liveSession.type,
      timing: liveSession.startTime,
      hasCompleted: liveSession.hasWatched,
    };
  });
}
export function restructureLiveSessionsDataToJsx(data, batchSlug, weekSlug) {
  return data.map((liveSession) => {
    return {
      ...getCommonFieldsInOfferingsJSX(liveSession, batchSlug, weekSlug),
      duration: (
        <Description weight="md" size="sm">
          {liveSession.duration}
        </Description>
      ),
      timing: (
        <Description weight="md" size="sm">
          {new Date(liveSession.timing).toLocaleString("en-US", {
            ...DATE_OPTIONS,
            weekday: "short",
          })}
        </Description>
      ),
      actions: <ActionButton {...liveSession} />,
    };
  });
}
export function restructureLecturesDataToJsx(data, batchSlug, weekSlug) {
  return data.map((lecture) => {
    return {
      ...getCommonFieldsInOfferingsJSX(lecture, batchSlug, weekSlug),
      duration: (
        <Description weight="md" size="sm">
          {lecture.duration}
        </Description>
      ),
    };
  });
}

export const lectureSkeletonTemplate = {
  id: 1,
  status: <Skeleton variant="avatar" size="sm" />,
  title: <Skeleton variant="input" size="full" className="w-[90%]" />,
  type: <Skeleton variant="avatar" size="sm" />,
  duration: <Skeleton variant="input" size="full" className="w-[80%]" />,
};
