import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSlugId } from "../../helper/restructureData";
import {
  fetchSearchResults,
  resetSearchResult,
} from "../../redux/slices/batchConfigSlice";
import SearchOfferingType from "./components/SearchOfferingType";
import SearchResult from "./components/SearchResult";
import SearchResultSkeleton from "./components/SearchResultSkeleton";
import { DEFAULT_SEARCH_TYPE, searchTypeOptions } from "./helpers/constant";
import { IconWrapper, InputText, NotFound, PopupLayout, Text } from "./imports";

let TIMEOUT_ID;
const GlobalSearch = () => {
  const { batchSlug } = useParams();
  const batchId = useSlugId(batchSlug);
  const dispatch = useDispatch();
  const searchModalRef = useRef(null);
  const searchInputRef = useRef(null);
  const [isSearchModalActive, setIsSearchModalActive] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchType, setSearchType] = useState(DEFAULT_SEARCH_TYPE);
  const { data, status, error } = useSelector(
    (state) => state.batchConfigReducer.searchResults
  );
  function searchInputChangeHandler(e) {
    const value = e.target.value;
    setSearchInputValue(value);
  }
  function typeChangeHandler(type) {
    setSearchType(type);
    searchInputRef.current.focus();
  }
  function inputValueClearHandler() {
    setSearchInputValue("");
  }
  function searchModalHandler() {
    setIsSearchModalActive((prev) => !prev);
  }

  useEffect(() => {
    if (searchInputValue.length !== 0) {
      TIMEOUT_ID = setTimeout(() => {
        dispatch(
          fetchSearchResults({
            batchId,
            search: searchInputValue,
            offeringTypes: [searchType],
          })
        );
      }, 1000);
    }
    return () => clearTimeout(TIMEOUT_ID);
  }, [searchInputValue, searchType]);

  useEffect(() => {
    if (!isSearchModalActive) {
      setSearchInputValue("");
      dispatch(resetSearchResult());
    }
  }, [isSearchModalActive]);
  return (
    <>
      <div className="relative group max-md:hidden t-flex" ref={searchModalRef}>
        <button
          className="p-2 transition-colors rounded-full shadow-sm bg-neutral-100 hover:bg-neutral-200"
          onClick={searchModalHandler}
        >
          <IconWrapper icon="search" className="text-blue-500" />
        </button>

        <PopupLayout
          ref={searchModalRef}
          isPopupActive={isSearchModalActive}
          setIsPopupActive={setIsSearchModalActive}
        >
          <div className="group-aria-[expanded=true]:scale-100 scale-75 origin-top transition-all absolute w-[450px] top-2/4 left-2/4 -translate-x-2/4 translate-y-10 bg-neutral-100 p-4 rounded shadow-2xl border border-border-300">
            <div className="flex-col gap-4 t-flex">
              <div>
                <InputText
                  suffixIcon="close"
                  suffixIconProps={{
                    size: "20",
                    className: `bg-neutral-600 rounded-full p-[2px] ${
                      searchInputValue.length > 0 ? "block" : "hidden"
                    }`,
                  }}
                  onClear={inputValueClearHandler}
                  ref={searchInputRef}
                  size="md"
                  variant="primary"
                  icon="search"
                  className="w-full "
                  placeholder="Search by problem, lecture, mcq name"
                  iconSize="20"
                  value={searchInputValue}
                  onChange={searchInputChangeHandler}
                />
              </div>
              <div className="border-t border-t-border-300"></div>
              <div className="flex-col items-start gap-2 t-flex">
                <div className="px-2">
                  <Text size="sm" variant="primary">
                    Select search type
                  </Text>
                </div>
                <div className="items-center justify-around w-full t-flex">
                  {searchTypeOptions.map((option) => (
                    <SearchOfferingType
                      key={option.id}
                      {...option}
                      onClick={typeChangeHandler}
                      searchType={searchType}
                    />
                  ))}
                </div>
                {status === "loading" ? (
                  <SearchResultSkeleton />
                ) : (
                  <div className="flex-col w-full overflow-y-scroll max-h-[280px] t-flex global-search-wrapper">
                    {data.map((searchResult, i) => (
                      <SearchResult
                        setIsSearchModalActive={setIsSearchModalActive}
                        key={i}
                        {...searchResult}
                      />
                    ))}
                  </div>
                )}
                {status === "idle" && data.length === 0 && error && (
                  <div className="w-full">
                    <NotFound
                      text={error}
                      textSize="text-xs"
                      className="w-24"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </PopupLayout>
      </div>
    </>
  );
};

export default GlobalSearch;
