import React, { useRef, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import "../styles/transaction-details.scss";
import TransactionModel from "./TransactionModal";
import PopupLayout from "../../../layout/PopupLayout";
const TransactionDetails = ({ user }) => {
  const modelRef = useRef(null);
  const [isModelActive, setIsModelActive] = useState(false);
  return (
    <div className="transaction__model__container" ref={modelRef}>
      <button
        className="icon-wrapper flex admin__hover__button"
        onClick={() => setIsModelActive(true)}
      >
        <MdKeyboardArrowLeft />
      </button>
      <PopupLayout
        setIsPopupActive={setIsModelActive}
        isPopupActive={isModelActive}
        ref={modelRef}
      >
        <TransactionModel data={user} setIsModelActive={setIsModelActive} />
      </PopupLayout>
    </div>
  );
};

export default TransactionDetails;
