import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import DiscordDummy1 from "../../../img/discord-dummy1.png";
import DiscordDummy2 from "../../../img/discord-dummy2.png";
import Discord from "../../../img/discord.png";
import CompanyLogo from "../companyLogo";
import { CTALink, SidebarLink } from "../components/ui/CTALink";
import IconWrapper from "../components/ui/IconWrapper";
import { Text } from "../components/ui/Typography";
import { DISCORD_LINK } from "../helpers/constant";
import { cn } from "../helpers/utils";
import { MiniSidebarPanelLink } from "./MiniSidebar";
const SidebarPanelSlideButton = ({ sidePanelCollapseHandler }) => {
  return (
    <button
      onClick={sidePanelCollapseHandler}
      className="items-center justify-center t-flex group"
    >
      <IconWrapper
        icon="sidePanel"
        size="25"
        className="text-primaryText group-hover:text-primaryText"
      />
    </button>
  );
};
const Sidebar = (props) => {
  const {
    isSidebarPanelCollapsed,
    isOverlayNavbar,
    toggleOverlaySidebar,
    sidebarConfig,
    setIsSidebarPanelCollapsed,
  } = props;
  const { pathname } = useLocation();
  const themeStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isDarkMode
  );

  function sidePanelCollapseHandler() {
    setIsSidebarPanelCollapsed((prev) => !prev);
  }
  return (
    <div
      className={cn(
        "relative h-full transition-all duration-200 overflow-hidden w-64",
        isSidebarPanelCollapsed && "w-[61px]"
      )}
    >
      <div
        className={cn(
          "items-center p-4 t-flex",
          isSidebarPanelCollapsed
            ? "justify-center mb-4"
            : "justify-between gap-4"
        )}
      >
        <div className="items-center gap-4 t-flex">
          {!isSidebarPanelCollapsed && (
            <Link to="/" className="items-center t-flex ">
              <CompanyLogo size={110} islight={!themeStatus} />
            </Link>
          )}
        </div>
        {isOverlayNavbar && (
          <button onClick={() => toggleOverlaySidebar(false)}>
            <IconWrapper
              icon={"close"}
              size={22}
              className={"text-primaryText"}
            />
          </button>
        )}{" "}
        {!isOverlayNavbar && (
          <SidebarPanelSlideButton
            sidePanelCollapseHandler={sidePanelCollapseHandler}
          />
        )}
      </div>
      <div
        className={
          isSidebarPanelCollapsed
            ? "p-2"
            : "p-4 t-flex flex-col h-[calc(100%-59px)] justify-between"
        }
      >
        <ul className="flex-col gap-3 t-flex">
          {sidebarConfig.length > 0 &&
            sidebarConfig.map((data, index) =>
              !isSidebarPanelCollapsed && data.label === "Community" ? null : (
                <div key={data.id} className="flex-col t-flex">
                  {index !== 0 && isSidebarPanelCollapsed && (
                    <div className="mb-3 border-t border-border-500"></div>
                  )}
                  {!isSidebarPanelCollapsed && (
                    <div className="mb-3">
                      <span className="text-xs font-medium select-none uppercase tracking-[1px] text-secondaryText">
                        {data.label}
                      </span>
                    </div>
                  )}
                  <div className="flex-col gap-2 t-flex">
                    {data?.tabs?.map((tab) =>
                      isSidebarPanelCollapsed ? (
                        <MiniSidebarPanelLink tab={tab} />
                      ) : (
                        <li className="overflow-hidden" key={tab.id}>
                          <SidebarLink
                            aria-disabled={tab.isLocked}
                            onClick={
                              isOverlayNavbar
                                ? () => toggleOverlaySidebar(false)
                                : null
                            }
                            variant={"primary"}
                            size={"sm"}
                            {...tab}
                            icon={tab.icon}
                            target={tab.target}
                            className={cn(
                              "[&:not(.active)]:hover:bg-navbar-exception-hover transition-colors duration-300 font-semibold [&.active]:pointer-events-none",
                              pathname.includes(tab.activeHref) ? "active" : ""
                            )}
                          />
                        </li>
                      )
                    )}
                  </div>
                </div>
              )
            )}
        </ul>
        <div
          className={cn(
            "p-2 rounded-lg t-flex flex-col gap-3 items-center ",
            isSidebarPanelCollapsed ? "hidden" : ""
          )}
        >
          <div className="items-center justify-center t-flex">
            <div>
              <img
                src={DiscordDummy1}
                alt="student"
                className="w-10 h-10 mt-4"
              />
            </div>
            <div>
              <img src={DiscordDummy2} alt="student" className="w-12 h-12" />
            </div>
            <div>
              <img src={Discord} alt="discord" className="w-10 h-10 mt-4" />
            </div>
          </div>
          <div>
            <Text size="sm" className="text-center" weight="md">
              Join Coding Shuttle community to learn together
            </Text>
          </div>
          <CTALink
            target="_blank"
            to={DISCORD_LINK}
            size="xs"
            variant="primary"
            className="max-w-max"
          >
            Join Discord
          </CTALink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
