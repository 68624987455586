import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import withAddToGoogleCalender from "../../common/action-button-hoc/AddToGoogleCalender";
import { getOfferingRoute } from "../../explore-problems/helpers/restructureProblems";
import { InfoTooltip } from "../../job-portal/components/ui/Tooltip";
import { CTAButton, IconWrapper } from "../import";

const buttonType = {
  recording: {
    variant: "primary",
    text: "Watch Recording",
    showAddToCalender: false,
  },
  live: {
    variant: "destructive",
    text: "Watch Live",
    showAddToCalender: true,
  },
};

const CalenderIconButton = (props) => {
  const {
    onAddToCalenderButtonClick,
    isDisabled,
    alreadyAdded,
    hasAddedToCalender,
  } = props;
  return (
    <>
      <InfoTooltip
        tooltipText={
          hasAddedToCalender || alreadyAdded
            ? "Added To Your Google Calender"
            : "Add To Your Google Calender"
        }
      >
        <button disabled={isDisabled} onClick={onAddToCalenderButtonClick}>
          <IconWrapper
            icon={
              hasAddedToCalender || alreadyAdded
                ? "checkCalender"
                : "addCalender"
            }
            size="20"
            className={` ${
              hasAddedToCalender || alreadyAdded
                ? "text-success-400"
                : "text-blue-500"
            }`}
          />
        </button>
      </InfoTooltip>
    </>
  );
};
const ActionButton = (props) => {
  const { type, id, slug, title, timing } = props;
  const { batchSlug, weekSlug } = useParams();
  const navigate = useNavigate();
  function navigationHandler() {
    navigate(getOfferingRoute(batchSlug, type, slug, weekSlug));
  }
  const CalenderButton = withAddToGoogleCalender({
    title,
    startTimeISO: timing,
    type: "liveClass",
    resourceId: id,
    WrapperComponent: CalenderIconButton,
  });
  return (
    <div className="items-center gap-4 t-flex">
      <CTAButton
        onClick={navigationHandler}
        variant={buttonType[type].variant}
        size="xs"
        className="shrink-0 whitespace-nowrap"
      >
        {buttonType[type].text}
      </CTAButton>
      {buttonType[type].showAddToCalender && <CalenderButton />}
    </div>
  );
};

export default ActionButton;
