import React, { useState } from "react";
import toast from "react-hot-toast";
import { GrFormClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedReferralStatusData } from "../../../../../redux/slices/adminSlice";
import { axiosClient } from "../../../../../utils/axiosClient";
import Dropdown from "../../../components/dropdown/Dropdown";
import "../../../leads/lib/styles/edit-lead-status.scss";
const EditReferralStatus = (props) => {
  const { setIsActive, referalId } = props;
  const dispatch = useDispatch();
  const referalStatuses = useSelector(
    (state) => state.adminReducer.referrals.referralStatuses
  );
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("Select Status");
  function cancelHandler() {
    setIsActive(false);
  }
  async function submitHandler() {
    try {
      setIsLoading(true);
      await axiosClient.patch(`/admin/referrals/${referalId}/status`, {
        status: dropdownValue?.name,
      });
      toast.success(`Status updated to ${dropdownValue?.name}`);
      dispatch(
        setUpdatedReferralStatusData({
          targetId: referalId,
          status: dropdownValue?.name,
        })
      );
      setIsActive(false);
    } catch (error) {
      toast.error(`Error: ${error?.message}`);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div className="flex admin__model__container__message">
      <div className="model__content__container">
        <div className="flex model__header">
          <div className="heading">
            <h2 className="admin__panel__secondary__heading">
              Edit Referral Status
            </h2>
          </div>
          <button className="flex admin__hover__button" onClick={cancelHandler}>
            <GrFormClose />
          </button>
        </div>
        <div className="model__body">
          <Dropdown
            label="Referral Status"
            setDropdownValue={setDropdownValue}
            dropdownValue={dropdownValue}
            data={referalStatuses}
          />
        </div>
        <div className="model__footer">
          <div className="flex button__wrapper">
            <button className="cancel__button" onClick={cancelHandler}>
              <span>Cancel</span>
            </button>
            <button
              className="flex admin__hover__button submit__button"
              onClick={submitHandler}
              disabled={typeof dropdownValue !== "object" || isLoading}
            >
              {isLoading && <span className="loading__spinner"></span>}
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReferralStatus;
