import React from "react";
import { CTAButton, Description, Title } from "./common/import";
import { ReportBugTrigger } from "./job-portal/components/common/ReportBug";
import errorBoundaryImage from "../img/error-boundary.png";
import { HEADER_HEIGHT } from "./job-portal/layouts/ClassroomLayout";
const ReportBugButton = (props) => {
  const { onClick } = props;
  return (
    <CTAButton
      size="xs"
      variant="destructive"
      icon="bug"
      onClick={onClick}
      iconProps={{ size: 18 }}
    >
      Report Bug
    </CTAButton>
  );
};
const ErrorBoundaryFallback = (props) => {
  const { error, resetErrorBoundary } = props;
  return (
    <div
      className={`flex-col items-center justify-center p-8 max-sm:p-2 h-[calc(100vh-${HEADER_HEIGHT}px)] gap-4 t-flex`}
    >
      <div className="flex-col items-center justify-center gap-2 t-flex">
        <div className="w-[300px]">
          <img
            src={errorBoundaryImage}
            className="w-full"
            alt="something went wrong"
          />
        </div>
        <div className="flex-col items-center justify-center gap-1 t-flex">
          <Title size="2xl" weight="bold" className="text-danger-400">
            Oops! Something went wrong
          </Title>
          <Description size="sm" weight="md" className="text-center">
            Error: {error.message}
          </Description>
        </div>
      </div>
      <div className="items-center justify-center gap-6 t-flex">
        <div>
          <CTAButton
            onClick={resetErrorBoundary}
            size="xs"
            variant="primary"
            icon="load"
            iconProps={{ size: 16 }}
          >
            Try Again
          </CTAButton>
        </div>
        <div>
          <ReportBugTrigger WrapperComponent={ReportBugButton} />
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
