import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isArray } from "lodash";
import toast from "react-hot-toast";
import {
  restructureLecturesData,
  restructureLiveSessionData,
} from "../../components/week-drip/helpers/restructureDripData";
import restructureData from "../../helper/restructureData";
import { axiosClient } from "../../utils/axiosClient";

export const fetchDripData = createAsyncThunk(
  "fetch/dripData",
  async ({ batchId }) => {
    const response = await axiosClient.get(
      `/classroom/classes/${batchId}/getDrips`
    );
    return response.data;
  }
);
export const fetchDripDataById = createAsyncThunk(
  "fetch/dripDataById",
  async ({ batchId, dripId, resources }) => {
    const response = await axiosClient.get(
      `/classroom/classes/${batchId}/getDrips/${dripId}?resources=${resources.join(
        ","
      )}`
    );
    return response.data;
  }
);

export const fetchDripDataByIdConfig = createAsyncThunk(
  "fetch/dripDataByIdConfig",
  async ({ batchId, dripId, setSearchParams }) => {
    const response = await axiosClient.get(
      `/classroom/classes/${batchId}/getDrips/config/${dripId}`
    );
    if (
      response?.data?.tabs &&
      isArray(response?.data?.tabs) &&
      response?.data?.tabs?.length > 0 &&
      setSearchParams
    ) {
      setSearchParams({ tab: response?.data?.tabs[0] }, { replace: true });
    }
    return response.data;
  }
);

//to fetch data of MCQ by offering ID:=>
export const fetchMcqData = createAsyncThunk("fetch/mcqData", async (data) => {
  const response = await axiosClient.get(
    `/classroom/codeeditor/${data.batchId}/problems/${data.mcqId}`
  );
  return response.data;
});

//to fetch solution status of mcq
export const fetchMcqSolution = createAsyncThunk(
  "fetch/mcq/solution",
  async ({ offeringId, batchId }) => {
    const response = await axiosClient(
      `/classroom/codeeditor/${batchId}/problems/${offeringId}/solution`
    );
    return response.data;
  }
);
//to fetch complete solution of mcq
export const fetchCompleteMcqSolution = createAsyncThunk(
  "fetch/mcq/completeSolution",
  async ({ offeringId, batchId, resourceType }) => {
    const response = await axiosClient.post(
      `/classroom/codeeditor/${batchId}/problems/${offeringId}/solution/unlock`,
      { resourceType }
    );

    return response.data;
  }
);
// to submit MCQ answer
export const validateMcqAnswer = createAsyncThunk(
  "post/mcqAnswer",
  async ({ batchId, offeringId, mcqAnswerId }) => {
    const response = await axiosClient.post(
      `/classroom/evaluate/${batchId}/problems/${offeringId}`,
      {
        answers: mcqAnswerId,
      }
    );
    return response.data;
  }
);

const mcqInitialStates = {
  mcqData: {
    status: "idle",
    data: null,
    prevOffering: null,
    nextOffering: null,
  },
  mcqSolution: {
    data: "",
    status: "",
    error: "",
  },
  mcqCompleteSolution: {
    isLocked: true,
    data: "",
    status: "",
    error: "",
  },
  mcqAnswerVerdict: {
    data: "",
    status: "idle",
    error: "",
  },
};

const dripSlice = createSlice({
  name: "dripSlice",
  initialState: {
    classesData: [],

    status: "idle",
    isModelActive: false,

    dripDataById: {
      data: {
        lecturesData: [],
        assignmentData: [],
        homeworkData: [],
        liveSessionData: [],
      },
      status: "idle",
    },

    dripConfig: {
      data: null,
      status: "idle",
    },

    mcqStates: { ...mcqInitialStates },
  },
  reducers: {
    resetMcqStates: (state) => {
      state.mcqStates = mcqInitialStates;
    },
    setModelActive: (state, action) => {
      state.isModelActive = action.payload;
    },
    resetMcqVerdict: (state) => {
      state.mcqStates.mcqAnswerVerdict = {
        data: "",
        status: "idle",
        error: "",
      };
    },
    setUpdatedBookmarkedData: (state, action) => {
      state.dripDataById.data[action.payload.updateDataType] =
        action.payload.updatedProblemsData;
    },
    clearWeekDrip: (state) => {
      state.dripConfig.data = null;
      state.dripDataById.data = {
        lecturesData: [],
        assignmentData: [],
        homeworkData: [],
        liveSessionData: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDripData.fulfilled, (state, action) => {
        const { unlockedDrips } = action.payload;
        const prepModules = unlockedDrips.filter((drip) => {
          return drip.week < 0;
        });
        action.payload.unlockedDrips = unlockedDrips.filter((drip) => {
          return drip.week > 0;
        });
        action.payload.prepModules = prepModules;
        state.classesData = action.payload;
        state.status = "idle";
      })
      .addCase(fetchDripData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMcqData.fulfilled, (state, action) => {
        state.mcqStates.mcqData.data = action.payload.problem;
        state.mcqStates.mcqData.prevOffering = action.payload.prevOffering;
        state.mcqStates.mcqData.nextOffering = action.payload.nextOffering;
        state.mcqStates.mcqData.status = "idle";
      })
      .addCase(fetchMcqData.pending, (state, action) => {
        state.mcqStates.mcqData.status = "loading";
      })
      .addCase(fetchMcqData.rejected, (state, action) => {
        state.mcqStates.mcqData.error = action.error;
        state.mcqStates.mcqData.status = "error";
        toast.error(action.error.message);
      })
      .addCase(validateMcqAnswer.fulfilled, (state, action) => {
        state.mcqStates.mcqAnswerVerdict.data = action.payload;
        state.mcqStates.mcqAnswerVerdict.status = "idle";
      })
      .addCase(validateMcqAnswer.rejected, (state, action) => {
        state.mcqStates.mcqAnswerVerdict.error = action.error;
        state.mcqStates.mcqAnswerVerdict.status = "error";
        toast.error(action.error.message);
      })
      .addCase(validateMcqAnswer.pending, (state) => {
        state.mcqStates.mcqAnswerVerdict.status = "loading";
      })
      .addCase(fetchMcqSolution.fulfilled, (state, action) => {
        state.mcqStates.mcqCompleteSolution.isLocked =
          action.payload.completeSolutionUnlocked;
        state.mcqStates.mcqCompleteSolution.data = action.payload;
        state.mcqStates.mcqSolution.data = action.payload;
        state.mcqStates.mcqSolution.status = "idle";
      })
      .addCase(fetchMcqSolution.pending, (state) => {
        state.mcqStates.mcqSolution.status = "loading";
      })
      .addCase(fetchMcqSolution.rejected, (state, action) => {
        state.mcqStates.mcqSolution.error = action.error;
        state.mcqStates.mcqSolution.status = "error";
        toast.error(action.error.message);
      })
      .addCase(fetchCompleteMcqSolution.fulfilled, (state, action) => {
        state.mcqStates.mcqCompleteSolution.isLocked =
          action.payload.completeSolutionUnlocked;
        state.mcqStates.mcqCompleteSolution.data = action.payload;
        state.mcqStates.mcqCompleteSolution.status = "idle";
      })
      .addCase(fetchCompleteMcqSolution.pending, (state) => {
        state.mcqStates.mcqCompleteSolution.status = "loading";
      })
      .addCase(fetchCompleteMcqSolution.rejected, (state, action) => {
        state.mcqStates.mcqCompleteSolution.error = action.error;
        state.mcqStates.mcqCompleteSolution.status = "error";
        toast.error(action.error.message);
      })
      .addCase(fetchDripDataById.fulfilled, (state, action) => {
        const homeworkData = action.payload.classContent.homeworkData;
        const assignmentData = action.payload.classContent.assignmentData;
        const lecturesData = action.payload.classContent.lectures;
        const liveSessionData = action.payload.classContent.liveClasses;
        const week = Math.abs(action.payload.week);

        if (homeworkData) {
          state.dripDataById.data.homeworkData = restructureData(homeworkData);
        } else if (assignmentData) {
          state.dripDataById.data.assignmentData =
            restructureData(assignmentData);
        } else {
          state.dripDataById.data.lecturesData = restructureLecturesData(
            lecturesData,
            week
          );
          state.dripDataById.data.liveSessionData =
            restructureLiveSessionData(liveSessionData);
        }
        state.dripDataById.status = "idle";
      })
      .addCase(fetchDripDataById.pending, (state, action) => {
        if (action.meta.arg.isIntitallyLoaded) return;
        if (!action.meta.arg.isIntitallyLoaded) {
          state.dripDataById.status = "loading";
        }
      })
      .addCase(fetchDripDataById.rejected, (state, action) => {
        state.dripDataById.error = action.error;
        state.dripDataById.status = "error";
        toast.error(action.error.message);
      })
      .addCase(fetchDripDataByIdConfig.fulfilled, (state, action) => {
        state.dripConfig.data = action.payload;
        state.dripConfig.status = "idle";
      })
      .addCase(fetchDripDataByIdConfig.pending, (state, action) => {
        if (action.meta.arg.isIntitallyLoaded) return;
        if (!action.meta.arg.isIntitallyLoaded) {
          state.dripConfig.status = "loading";
        }
      })
      .addCase(fetchDripDataByIdConfig.rejected, (state, action) => {
        state.dripConfig.error = action.error;
        state.dripConfig.status = "error";
        toast.error(action.error.message);
      });
  },
});
export const {
  setModelActive,
  setUpdatedBookmarkedData,
  resetMcqStates,
  resetMcqVerdict,
  clearWeekDrip,
} = dripSlice.actions;
export default dripSlice.reducer;
