export const completeSolutionMeta = [
  {
    label: `C++`,
    key: "1",
    code: "",
    dataIndex: "cpp",
  },
  {
    label: `Java`,
    key: "2",
    code: "",
    dataIndex: "java",
  },
  {
    label: `Python`,
    key: "3",
    code: "",
    dataIndex: "python",
  },
];
