import InfoIconWithTooltip from "../../common/InfoIconWithTooltip";
import DifficultyDropdown from "../components/DifficultyDropdown";
import TopicsDropdown from "../components/TopicsDropdown";
export const FILTER_DROPDOWN_COMPONENT_REF = {
  TOPIC_DROPDWON: TopicsDropdown,
  DIFFCULTY_DROPDOWN: DifficultyDropdown,
};

export const exploreProblemsFiltersMetaData = [
  {
    key: 1,
    label: "Topics",
    dataIndex: "topics",
    componentRef: "TOPIC_DROPDWON",
    position: { left: "0" },
    placeholder: "Find topic name...",
    metaOptions: [
      { key: 1, label: "Abstraction", value: "Abstraction" },
      { key: 2, label: "Array", value: "Array" },
      { key: 3, label: "Backtracking", value: "Backtracking" },
      {
        key: 4,
        label: "Biconnected Component",
        value: "Biconnected Component",
      },
      { key: 5, label: "Binary Indexed Tree", value: "Binary Indexed Tree" },
      { key: 6, label: "Binary Search", value: "Binary Search" },
      { key: 7, label: "Binary Search Tree", value: "Binary Search Tree" },
      { key: 8, label: "Binary Tree", value: "Binary Tree" },
      { key: 9, label: "Bit Manipulation", value: "Bit Manipulation" },
      { key: 10, label: "Bitmask", value: "Bitmask" },
      { key: 11, label: "Brainteaser", value: "Brainteaser" },
      { key: 12, label: "Breadth-First Search", value: "Breadth-First Search" },
      { key: 13, label: "Bucket Sort", value: "Bucket Sort" },
      { key: 14, label: "Classess and Objects", value: "Classess and Objects" },
      { key: 15, label: "Collection Framework", value: "Collection Framework" },
      { key: 16, label: "Combinatorics", value: "Combinatorics" },
      { key: 17, label: "Concurrency", value: "Concurrency" },
      {
        key: 18,
        label: "Conditional Statements",
        value: "Conditional Statements",
      },
      { key: 19, label: "Counting", value: "Counting" },
      { key: 20, label: "Counting Sort", value: "Counting Sort" },
      { key: 21, label: "Data Stream", value: "Data Stream" },
      { key: 22, label: "Database", value: "Database" },
      { key: 23, label: "Depth-First Search", value: "Depth-First Search" },
      { key: 24, label: "Design", value: "Design" },
      { key: 25, label: "Divide and Conquer", value: "Divide and Conquer" },
      { key: 26, label: "Doubly-Linked List", value: "Doubly-Linked List" },
      { key: 27, label: "Dynamic Programming", value: "Dynamic Programming" },
      { key: 28, label: "Encapsulation", value: "Encapsulation" },
      { key: 29, label: "Enumeration", value: "Enumeration" },
      { key: 30, label: "Eulerian Circuit", value: "Eulerian Circuit" },
      { key: 31, label: "Exception Handling", value: "Exception Handling" },
      { key: 32, label: "Functions", value: "Functions" },
      { key: 33, label: "Game Theory", value: "Game Theory" },
      { key: 34, label: "Generics", value: "Generics" },
      { key: 35, label: "Geometry", value: "Geometry" },
      { key: 36, label: "Graph", value: "Graph" },
      { key: 37, label: "Greedy", value: "Greedy" },
      { key: 38, label: "Hash Function", value: "Hash Function" },
      { key: 39, label: "Hash Table", value: "Hash Table" },
      {
        key: 40,
        label: "Heap (Priority Queue)",
        value: "Heap (Priority Queue)",
      },
      { key: 41, label: "Inheritence", value: "Inheritence" },
      { key: 42, label: "Interactive", value: "Interactive" },
      { key: 43, label: "Iterator", value: "Iterator" },
      { key: 44, label: "Java Basics", value: "Java Basics" },
      { key: 45, label: "Line Sweep", value: "Line Sweep" },
      { key: 46, label: "Linked List", value: "Linked List" },
      { key: 47, label: "List", value: "List" },
      { key: 48, label: "Loops", value: "Loops" },
      { key: 49, label: "Math", value: "Math" },
      { key: 50, label: "Matrix", value: "Matrix" },
      { key: 51, label: "Memoization", value: "Memoization" },
      { key: 52, label: "Merge Sort", value: "Merge Sort" },
      {
        key: 53,
        label: "Minimum Spanning Tree",
        value: "Minimum Spanning Tree",
      },
      { key: 54, label: "Monotonic Queue", value: "Monotonic Queue" },
      { key: 55, label: "Monotonic Stack", value: "Monotonic Stack" },
      { key: 56, label: "Number Theory", value: "Number Theory" },
      { key: 57, label: "OOPS Concept", value: "OOPS Concept" },
      { key: 58, label: "Ordered Set", value: "Ordered Set" },
      { key: 59, label: "Polymorphism", value: "Polymorphism" },
      { key: 60, label: "Prefix Sum", value: "Prefix Sum" },
      {
        key: 61,
        label: "Probability and Statistics",
        value: "Probability and Statistics",
      },
      { key: 62, label: "Queue", value: "Queue" },
      { key: 63, label: "Quickselect", value: "Quickselect" },
      { key: 64, label: "Radix Sort", value: "Radix Sort" },
      { key: 65, label: "Randomized", value: "Randomized" },
      { key: 66, label: "Recursion", value: "Recursion" },
      { key: 67, label: "Rejection Sampling", value: "Rejection Sampling" },
      { key: 68, label: "Reservoir Sampling", value: "Reservoir Sampling" },
      { key: 69, label: "Rolling Hash", value: "Rolling Hash" },
      { key: 70, label: "Segment Tree", value: "Segment Tree" },
      { key: 71, label: "Shell", value: "Shell" },
      { key: 72, label: "Shortest Path", value: "Shortest Path" },
      { key: 73, label: "Simulation", value: "Simulation" },
      { key: 74, label: "Sliding Window", value: "Sliding Window" },
      { key: 75, label: "Sorting", value: "Sorting" },
      { key: 76, label: "Stack", value: "Stack" },
      { key: 77, label: "String", value: "String" },
      { key: 78, label: "String Matching", value: "String Matching" },
      {
        key: 79,
        label: "Strongly Connected Component",
        value: "Strongly Connected Component",
      },
      { key: 80, label: "Suffix Array", value: "Suffix Array" },
      { key: 81, label: "Topological Sort", value: "Topological Sort" },
      { key: 82, label: "Tree", value: "Tree" },
      { key: 83, label: "Trie", value: "Trie" },
      { key: 84, label: "Two Pointers", value: "Two Pointers" },
      { key: 85, label: "Union Find", value: "Union Find" },
    ],
  },
  {
    key: 2,
    label: "Difficulty",
    dataIndex: "difficulty",
    componentRef: "DIFFCULTY_DROPDOWN",
    position: { left: "0" },
    metaOptions: [
      {
        key: 6,
        value: "easy",
        label: "Easy",
      },
      {
        key: 7,
        value: "medium",
        label: "Medium",
      },
      {
        key: 8,
        value: "hard",
        label: "Hard",
      },
    ],
  },
  {
    key: 3,
    label: "Problem Type",
    dataIndex: "problemType",
    componentRef: "DIFFCULTY_DROPDOWN",
    position: { left: "0" },
    metaOptions: [
      {
        key: 9,
        value: "coding",
        label: "Coding Problem",
      },
      {
        key: 10,
        value: "mcq",
        label: "MCQ Problem",
      },
    ],
  },
];
export const VALID_PARAMS = {
  topics: "filter.topics",
  difficulty: "filter.difficulty",
  problemType: "filter.problemType",
};
export const sortMetaData = [
  {
    key: 1,
    value: "desc",
    type: "createdAt",
    label: "Latest Problems",
  },
  {
    key: 2,
    value: "asc",
    type: "createdAt",
    label: "Oldest Problems",
  },
  {
    key: 3,
    value: "desc",
    type: "maxScore",
    label: "Highest Max Score",
  },
  {
    key: 4,
    value: "asc",
    type: "maxScore",
    label: "Lowest Max Score",
  },
  {
    key: 5,
    value: "asc",
    type: "difficulty",
    label: "Easy to Hard",
  },
  {
    key: 6,
    value: "desc",
    type: "difficulty",
    label: "Hard to Easy",
  },
];
export const VALID_SORT_PARAMS = {
  [`${sortMetaData[0].type}-${sortMetaData[0].value}`]: sortMetaData[0].label,
  [`${sortMetaData[1].type}-${sortMetaData[1].value}`]: sortMetaData[1].label,
  [`${sortMetaData[2].type}-${sortMetaData[2].value}`]: sortMetaData[2].label,
  [`${sortMetaData[3].type}-${sortMetaData[3].value}`]: sortMetaData[3].label,
  [`${sortMetaData[4].type}-${sortMetaData[4].value}`]: sortMetaData[4].label,
  [`${sortMetaData[5].type}-${sortMetaData[5].value}`]: sortMetaData[5].label,
};
export const DEFAULT_SORTBY = sortMetaData[0];

export const problemHeader = [
  {
    id: 0,
    label: "Status",
    dataIndex: "status",
    size: "7%",
  },
  {
    id: 1,
    label: "Name of Problem",
    dataIndex: "title",
    size: "300px",
  },
  {
    id: 2,
    label: "Type",
    dataIndex: "type",
    size: "7%",
  },
  {
    id: 3,
    label: "Topic",
    dataIndex: "topic",
    size: "180px",
  },
  {
    id: 4,
    label: "Difficulty",
    dataIndex: "difficulty",
    size: "10%",
  },
  {
    id: 5,
    label: "Your Score",
    dataIndex: "score",
    size: "10%",
  },
  {
    id: 6,
    label: (
      <InfoIconWithTooltip
        text="SolvedBy"
        tooltipText="The SolvedBy count gets updated every 30 minutes."
      />
    ),
    dataIndex: "solvedBy",
    size: "10%",
  },
  {
    id: 7,
    label: "Actions",
    dataIndex: "actions",
    size: "10%",
  },
];

export const PROBLEM_TABS = [
  {
    label: "All Problems",
    id: 0,
    paramsValue: "allProblems",
  },
  {
    label: "Solved Problems",
    id: 1,
    paramsValue: "solvedProblems",
  },
  {
    label: "Bookmarked Problems",
    id: 2,
    paramsValue: "boomarkedProblems",
  },
];

export const difficultyType = {
  a: "Easy",
  b: "Medium",
  c: "Hard",
  easy: "Easy",
  medium: "Medium",
  hard: "Hard",
};
export const difficultyDataIndex = {
  easy: "a",
  medium: "b",
  hard: "c",
};
export const offeringTypeIcon = {
  mcq: "mcq",
  problem: "coding",
  lecture: "lecture",
};
export const problemTypes = {
  mcq: "mcq",
  coding: "problem",
};

export const PROGRESS_BACKGROUND =
  "radial-gradient(circle at 10% 20%, rgb(15, 213, 172) 0%, rgb(34, 182, 198) 100.2%)";

export const difficultyTypeColorVariant = {
  Easy: "border-success-700 text-success-400 bg-success-600",
  Medium: "border-warning-700 text-warning-400 bg-warning-600",
  pending: "border-warning-700 text-warning-400 bg-warning-600",
  attempted: "border-blue-500 text-blue-500 bg-blue-600",
  solved: "border-success-700 text-success-400 bg-success-600",
  Hard: "border-danger-700 text-danger-400 bg-danger-600",
  a: "border-success-700 text-success-400 bg-success-600",
  b: "border-warning-700 text-warning-400 bg-warning-600",
  c: "border-danger-700 text-danger-400 bg-danger-600",
};
export const DEFAULT_ACTIVE_TAB = PROBLEM_TABS[0].paramsValue;
export const SOLVED_PROBLEM_TAB = PROBLEM_TABS[1].paramsValue;
export const BOOKMARKED_PROBLEM_TAB = PROBLEM_TABS[2].paramsValue;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE_LIMIT = 100;
export const VALID_EXPLORE_PROBLEM_TAB = {
  allProblems: "all",
  solvedProblems: "solved",
  boomarkedProblems: "bookmarked",
};
export const problemToggleTypeTabs = [
  {
    id: 1,
    label: "All Problems",
    value: "problemsList",
  },
  {
    id: 2,
    label: "Unsolved Problems",
    value: "unsolvedProblemList",
  },
];
export const DEFAULT_PROBLEM_TOGGLE_TYPE = problemToggleTypeTabs[0].value;
export const UNSOLVED_PROBLEM_TOGGLE_TYPE = problemToggleTypeTabs[1].value;
export const problemTabBookmark = {
  [PROBLEM_TABS[0].paramsValue]: "problemsList",
  [PROBLEM_TABS[1].paramsValue]: "solvedProblemList",
  [PROBLEM_TABS[2].paramsValue]: "bookmarkedProblemList",
};

export const relatedOfferingVariant =
  "border-blue-700 text-blue-400 bg-blue-600";
