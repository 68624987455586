import { useEffect, useState } from "react";

export function useCountdown(futureDate, setIs15MinLeftToStart) {
  const [countDown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
    intialValues: true,
    isCountdownCompleted: false,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const currDate = new Date();
      const diffInMs = futureDate - currDate;
      const is15MinLeft =
        futureDate.valueOf() - currDate.valueOf() <= 15 * 60 * 1000;
      if (is15MinLeft && setIs15MinLeftToStart) {
        setIs15MinLeftToStart(true);
      }
      if (futureDate.valueOf() <= new Date().valueOf()) {
        if (countDown.isCountdownCompleted) {
          return clearInterval(interval);
        }
        setCountdown((prev) => ({
          ...prev,
          isCountdownCompleted: true,
          intialValues: false,
        }));
        return;
      }
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      const diffInHours = Math.floor(
        (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffInMinutes = Math.floor(
        (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
      );
      const diffInSeconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

      setCountdown({
        days: diffInDays < 10 ? `0${diffInDays}` : diffInDays,
        hours: diffInHours < 10 ? `0${diffInHours}` : diffInHours,
        minutes: diffInMinutes < 10 ? `0${diffInMinutes}` : diffInMinutes,
        seconds: diffInSeconds < 10 ? `0${diffInSeconds}` : diffInSeconds,
        intialValues: false,
        isCountdownCompleted: false,
      });
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [futureDate]);
  return { countDown };
}
