export default class FilterData {
  constructor(data) {
    this.data = data;
    this.unsolvedProblems = [];
  }
  filterByUnsolvedStatus() {
    for (let i = 0; i < this.data.length; i++) {
      if (!this.data[i].hasCompleted) {
        this.unsolvedProblems.push(this.data[i]);
      }
    }
    return this;
  }
}

export function dateToString(date) {
  return new Date(date).toLocaleDateString("en-IN");
}
export function dateToDateString(date) {
  return new Date(date).toDateString("en-IN");
}
