import React from "react";
import { AiOutlineYoutube } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn, FaTelegramPlane } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import CompanyLogo from "../companyLogo";
import "./footer.scss";
const companyLinks = [
  {
    label: "About Us",
    link: "/about",
  },

  {
    label: "Terms & Conditions",
    link: "/termsofuse",
  },
  {
    label: "Privacy Policy",
    link: "/privacypolicy",
  },
  {
    label: "Pricing & Refund Policy",
    link: "/refundpolicy",
  },
  {
    label: "Contact Us",
    link: "/contact",
  },
];

const socialLinks = [
  {
    icon: <AiOutlineYoutube size={35} />,
    link: "https://www.youtube.com/@codingshuttle",
  },
  {
    icon: <FaLinkedinIn size={28} />,
    link: "https://www.linkedin.com/company/coding-shuttle/",
  },
  {
    icon: <BsInstagram size={28} />,
    link: "https://www.instagram.com/codingshuttle/",
  },
  {
    icon: <FaTelegramPlane size={28} />,
    link: "https://telegram.me/realanujbhaiya",
  },
];

const Footer = () => {
  const { usernameSlug, batchSlug } = useParams();
  return (
    <div
      className={`footer-container ${usernameSlug ? "dark-theme-footer" : ""} ${
        batchSlug ? "checkout-footer" : ""
      }`}
    >
      <footer className="footer-section container-wrapper">
        <div className="flex inner-footer">
          <div className="footer-logo-side">
            <div className="footer-logo">
              <Link to={"/"}>
                <CompanyLogo islight={true} size={150} />
              </Link>
              <span>#BetterEveryday</span>
            </div>
          </div>
          <div className="company-info-link">
            <h6>company</h6>
            <ul className="flex">
              {companyLinks.map((list, i) => (
                <Link
                  to={`${process.env.REACT_APP_LANDING_PAGE_URL}${list.link}`}
                  key={i}
                >
                  <span>{list.label}</span>
                </Link>
              ))}
            </ul>
          </div>

          <div className="company-socials-links">
            <h6>follow us on</h6>
            <ul className="flex">
              {socialLinks.map((social, i) => (
                <a
                  className="social-icons telegram"
                  href={social.link}
                  rel="noreferrer"
                  target="_blank"
                  key={i}
                >
                  {social.icon}
                </a>
              ))}
            </ul>
          </div>
        </div>
        <div className="sub-footer">
          <span>Copyright © NGU Education Pvt. Ltd.</span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
