export const dropdownDataset = [
  {
    id: 1,
    index: "name",
    title: "Course",
    label: "batches",
    options: [],
  },
  {
    id: 2,
    index: "name",
    title: "Bundles",
    label: "bundles",
    options: [],
  },
  {
    id: 3,
    title: "Status",
    index: "status",
    label: "status",
    options: [
      {
        id: 1,
        status: "completed",
        label: "Completed",
      },
      {
        id: 2,
        status: "refunded",
        label: "Refunded",
      },
      {
        id: 3,
        status: "failed",
        label: "Failed",
      },
      {
        id: 4,
        status: "pending",
        label: "Pending",
      },
      {
        id: 5,
        status: "admin_override",
        label: "Admin Override",
      },
    ],
  },
  {
    id: 4,
    index: "couponCode",
    title: "Coupon code",
    label: "couponCodes",
    options: [],
  },
  {
    id: 5,
    title: "Time period",
    isDate: true,
    filterBasis: "timePeriod",
    options: [
      {
        id: "startDate#1",
        label: "From :",
        name: "startDate",
        dataLabel: "timePeriod",
      },
      {
        id: "endDate#1",
        label: "To :",
        name: "endDate",
        dataLabel: "timePeriod",
      },
    ],
  },
];

export const tableHeader = [
  {
    label: "date",
    dataIndex: "paidAt",
    id: 1,
  },
  {
    label: "batch",
    dataIndex: "batchName",
    id: 2,
  },
  {
    label: "amount",
    dataIndex: "price",
    id: 3,
  },
  {
    label: "coupon",
    dataIndex: "couponTitle",
    id: 4,
  },
  {
    label: "user",
    dataIndex: "name",
    id: 5,
    withImage: true,
  },
  {
    label: "payment status",
    dataIndex: "status",
    id: 6,
  },

  {
    label: "more",
    dataIndex: "action",
    id: 7,
  },
];
