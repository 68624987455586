export const searchTypeOptions = [
  {
    id: 1,
    label: "Lectures",
    type: "lecture",
    icon: "lecture",
    variant: "teal-500",
  },
  {
    id: 2,
    label: "Problems",
    type: "problem",
    icon: "coding",
    variant: "primary-500",
  },
  {
    id: 3,
    label: "MCQs",
    type: "mcq",
    icon: "mcq",
    variant: "secondary-500",
  },
];

export const OFFERING_ICON_VARIANT = {
  problem: "primary-500",
  mcq: "secondary-500",
  lecture: "teal-500",
};
export const DEFAULT_SEARCH_TYPE = searchTypeOptions[0].type;
