import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axiosClient";
import { toast } from "react-hot-toast";

const errorNotify = (message) => {
  toast.error(message);
};

export const fetchQuickCompilerData = createAsyncThunk(
  "fetch/quickCompilerData",
  async (uid) => {
    try {
      const response = await axiosClient(`/classroom/quickCompiler/${uid}`);
      return response.data.entry;
    } catch (err) {
      errorNotify(err.message);
    }
  }
);

const quickCompilerSlice = createSlice({
  name: "quickCompilerSlice",
  initialState: {
    defaultCode: {
      cpp: '#include <iostream>\n\nint main() {\n    std::cout << "Hello World in CPP";\n    return 0;\n}',
      java: 'class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello World in Java"); \n    }\n}',
      python: 'print("Hello World in Python")',
    },
    code: "",
    isEditable: true,
    isOwner: true,
    isCodeSaved: false,
    language: "",
    stdin: "",
    uid: null,
    user: null,
    error: null,
    stdout: {},
  },
  reducers: {
    updateQcCode: (state, action) => {
      const { language, value } = action.payload;
      state.defaultCode[language] = value;
      state.code = value;
    },
    updateQcLanguage: (state, action) => {
      state.language = action.payload;
    },
    updateStdin: (state, action) => {
      state.stdin = action.payload;
    },
    updateStdout: (state, action) => {
      state.stdout = action.payload;
    },
    updateIsEditable: (state, action) => {
      state.isEditable = action.payload;
    },
    updateIsCodeSaved: (state, action) => {
      state.isCodeSaved = action.payload;
    },
    resetPersistedValues: (state, action) => {
      state.uid = null;
      state.user = null;
      state.isOwner = true;
      state.isEditable = true;
    },
    resetAllCodes: (state) => {
      state.defaultCode.cpp =
        '#include <iostream>\n\nint main() {\n    std::cout << "Hello World in CPP";\n    return 0;\n}';
      state.defaultCode.java =
        'class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello World in Java"); \n    }\n}';
      state.defaultCode.python = 'print("Hello World in Python")';
    },
    resetDefaultCode: (state, action) => {
      const { language } = action.payload;
      switch (language) {
        case "cpp":
          state.defaultCode.cpp =
            '#include <iostream>\n\nint main() {\n    std::cout << "Hello World in CPP";\n    return 0;\n}';
          break;
        case "java":
          state.defaultCode.java =
            'class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello World in Java"); \n    }\n}';
          break;
        case "python":
          state.defaultCode.python = 'print("Hello World in Python")';
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuickCompilerData.fulfilled, (state, action) => {
      const { code, isEditable, isOwner, language, stdin, uid, user } =
        action.payload;
      state.code = code;
      state.defaultCode[language] = code;
      state.isEditable = isEditable;
      state.isOwner = isOwner;
      state.language = language;
      state.stdin = stdin;
      state.uid = uid;
      state.user = user;
      state.status = "idle";
    });
  },
});

export default quickCompilerSlice.reducer;
export const {
  updateQcCode,
  updateQcLanguage,
  updateStdin,
  updateStdout,
  resetDefaultCode,
  resetPersistedValues,
  updateIsEditable,
  resetAllCodes,
  updateIsCodeSaved,
} = quickCompilerSlice.actions;
