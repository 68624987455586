import React from "react";
import "./confirmation-model.scss";
import { ICON } from "./constant";
const ConfirmModel = ({
  setIsActive,
  onClick,
  status,
  isDisabled,
  text,
  targetName,
  buttonText,
  iconType,
}) => {
  return (
    <div className="flex confirm__model__wrapper">
      <div className="confirm__model__inner">
        <div className="flex model__body">
          <div className="flex delete__icon__wrapper">{ICON[iconType]}</div>
          <div className="flex text__wrapper">
            <div className="heading admin__panel__secondary__heading">
              <h4>{text}</h4>
            </div>
            <div className="sub__text admin__panel__md__text">
              <p>{targetName}</p>
            </div>
          </div>
        </div>
        <div className="model__footer">
          <div className="flex button__wrapper">
            <button
              className="flex cancel__button"
              onClick={() => setIsActive(false)}
            >
              <span>Cancel</span>
            </button>
            <button
              className="flex admin__hover__button submit__button"
              onClick={onClick}
              disabled={status === "loading" || isDisabled}
            >
              {status === "loading" && (
                <span className="loading__spinner"></span>
              )}
              <span>{buttonText}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModel;
