import React from "react";

export default function BatchItem({ batchName, batchImg }) {
  return (
    <div className="batch-item">
      <img src={batchImg} alt="" />
      <h3>{batchName}</h3>
    </div>
  );
}
