import React from "react";
import ReactDOM from "react-dom/client";
import "./style.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  const history = createBrowserHistory();
  history.replace(path);
}

if (process.env.NODE_ENV != "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_CONNECTION_URL,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          "https://codingshuttle.com",
          "https://stage.codingshuttle.com",
        ],
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>
);
