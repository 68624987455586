import React from "react";
import { Checkbox } from "../../job-portal/components/ui/Input";

const DifficultyDropdown = (props) => {
  const { metaOptions, onChange, activeFilters, filterType } = props;
  return (
    <form className="flex-col w-full gap-3 min-w-max t-flex">
      {metaOptions.map((option) => (
        <Checkbox
          htmlFor={option.label}
          value={option.value}
          id={option.label}
          key={option.key}
          label={option.label}
          size="sm"
          name={filterType}
          data-option-key={option.key}
          onChange={onChange}
          className={
            activeFilters.includes(option.value)
              ? "input-checked bg-primary-500"
              : ""
          }
          aria-label={option.label}
          checked={activeFilters.includes(option.value)}
        />
      ))}
    </form>
  );
};

export default DifficultyDropdown;
