import { cva } from "class-variance-authority";
import React from "react";
import { cn } from "../../helpers/utils";
import IconWrapper from "./IconWrapper";
import SpinnerLoader from "./Loader";

export const buttonVariants = cva(
  [
    "rounded border border-transparent relative t-flex justify-center items-center group  cursor-pointer font-medium text-primaryText transition-colors  [&:is(.active-loading)]:text-opacity-30  duration-200 gap-2 disabled:cursor-auto",
  ],
  {
    variants: {
      variant: {
        primary: [
          "bg-primary-500 text-zinc-50 hover:bg-primary-700 enabled:hover:text-opacity-80  active:bg-primary-700 disabled:bg-disabledPrimary disabled:opacity-70",
        ],
        secondary: [
          "text-primaryText border border-border-500 hover:bg-zinc-200 dark:hover:bg-zinc-800  ",
        ],
        outline: [
          "border-primary-500 [&:not(:disabled)]:hover:bg-primary-500 [&:not(:disabled)]:hover:text-light disabled:opacity-50",
        ],

        success: [
          "bg-success text-light hover:bg-success [&:not(:disabled)]:hover:bg-success-700",
        ],
        link: [
          "text-xs rounded-none text-primaryText border-b-2 hover:border-b-border-500 border-b-transparent",
        ],
        destructive: [
          "bg-danger-400 hover:opacity-80 text-[#FAFAFA] disabled:opacity-70 transition-opacity",
        ],
        neutral: [
          "bg-blue-500 hover:opacity-80 text-[#FAFAFA] disabled:opacity-70 transition-opacity",
        ],
      },
      size: {
        xxs: ["text-xs py-1.5 px-2"],
        xs: ["text-xs py-2 px-4"],
        sm: ["text-sm py-2 px-6"],
        md: ["text-sm py-2.5 px-6"],
        lg: ["text-base py-2.5 px-4"],
      },
      weight: {
        semibold: ["font-semibold"],
      },
    },
  }
);
export const CTAButton = ({
  variant,
  size,
  children,
  className,
  icon,
  iconProps,
  weight,
  textProps,
  isLoading,
  loaderSize,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cn(
        buttonVariants({ variant, size, weight }),
        className,
        isLoading && !icon && "active-loading"
      )}
    >
      {icon && isLoading && (
        <SpinnerLoader
          size={loaderSize || iconProps?.size || 22}
          className="border-white border-r-transparent"
        />
      )}

      {icon && !isLoading && <IconWrapper icon={icon} {...iconProps} />}
      <span {...textProps}>{children}</span>
      {!icon && isLoading && (
        <SpinnerLoader
          size={loaderSize || iconProps?.size || 22}
          className="absolute border-white border-r-transparent"
        />
      )}
    </button>
  );
};
