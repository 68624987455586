import { cva } from "class-variance-authority";
import React, { cloneElement, forwardRef, useRef, useState } from "react";
import PopupLayout from "../../../admin/layout/PopupLayout";
import { cn } from "../../helpers/utils";
import DropdownLayout from "../../layouts/DropdownLayout";
import IconWrapper from "./IconWrapper";
import { Text } from "./Typography";

const dropdownTriggervariant = cva([""], {
  variants: {
    variant: {
      primary: [
        "transition-colors rounded bg-neutral-400 hover:bg-neutral-500 focus:bg-neutral-500",
      ],
      ghost: [""],
    },
    size: {
      md: ["px-4 py-2"],
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});
const DropdownTrigger = forwardRef((props, ref) => {
  const { className, variant, size, text, ...restProps } = props;
  return (
    <div
      role="button"
      className={cn(dropdownTriggervariant({ variant, size }), className)}
      tabIndex="0"
      {...restProps}
      ref={ref}
    >
      <div className="flex-shrink-0 gap-2 t-flex">
        <Text variant="primary" size="sm" weight="md">
          {text || "Topics"}
        </Text>
        <div className="items-center justify-center t-flex">
          <IconWrapper
            icon="upArrow"
            size="18"
            className="rotate-180 text-primaryText"
          />
        </div>
      </div>
    </div>
  );
});

const DropdownContentLayout = forwardRef((props, ref) => {
  const { children, className, setIsDropdownOpen } = props;
  return (
    <div
      aria-label="dropdown-content"
      className={cn("flex flex-col gap-4 p-3 bg-neutral-100", className)}
    >
      {React.Children.map(children, (child) => {
        // Clone each child element and add additionalProp to its props
        return cloneElement(child, { setIsDropdownOpen, ref });
      })}
    </div>
  );
});

const Dropdown2 = (props) => {
  const {
    variant,
    size,
    className,
    text,
    children,
    contentPosition,
    contentLayoutStyle,
    TriggerComponent,
    triggerComponentProps,
    dropdownInnerClassName,
  } = props;
  const headlessuiRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  function dropdownStateHandler() {
    setIsDropdownOpen((prev) => !prev);
  }
  return (
    <div className="relative group" aria-label="dropdown" ref={headlessuiRef}>
      {TriggerComponent ? (
        <TriggerComponent
          onClick={dropdownStateHandler}
          {...triggerComponentProps}
        />
      ) : (
        <DropdownTrigger
          variant={variant}
          size={size}
          className={className}
          text={text}
          onClick={dropdownStateHandler}
        />
      )}

      <PopupLayout
        isPopupActive={isDropdownOpen}
        ref={headlessuiRef}
        setIsPopupActive={setIsDropdownOpen}
      >
        <DropdownLayout
          style={{ ...contentPosition }}
          className={dropdownInnerClassName}
          ref={dropdownContentRef}
        >
          <DropdownContentLayout
            className={contentLayoutStyle}
            setIsDropdownOpen={setIsDropdownOpen}
            ref={dropdownContentRef}
          >
            {children}
          </DropdownContentLayout>
        </DropdownLayout>
      </PopupLayout>
    </div>
  );
};

export default Dropdown2;
