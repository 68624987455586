import { configureStore } from "@reduxjs/toolkit";
import currentProblemSlice from "./slices/currentProblemSlice";
import dripSlice from "./slices/dripSlice";
import problemsListSlice from "./slices/ProblemListSlice";
import storage from "redux-persist/lib/storage";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import quickCompilerSlice from "./slices/quickCompilerSlice";
import appConfigSlice from "./slices/appConfigSlice";
import batchConfigSlice from "./slices/batchConfigSlice";
import { combineReducers } from "redux";
import quickCompilerSliceNonPersisted from "./slices/quickCompilerSliceNonPersisted";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import adminSlice from "./slices/adminSlice";
import jobPortalSlice from "./slices/jobPortalSlice";
import contestSlice from "./slices/contestSlice";
import mockSeriesSlice from "./slices/mockSeriesSlice";
const migrations = {
  0: (state) => {
    return { state };
  },
  1: (state) => {
    return {
      ...state,
      batchConfig: { showJobPortal: null, showLeaderboard: null },
    };
  },
  1.1: (state) => {
    return {
      ...state,
      batchConfig: { isReportBugModalOpen: false },
    };
  },
  1.2: (state) => {
    return {
      ...state,
      videoPlayerConfig: {
        playbackRate: 1,
      },
    };
  },
};
const quickCompilerMigrations = {
  0: (state) => {
    return { state };
  },
};
const appPersistConfig = {
  key: "root",
  storage: storage,
  version: 1.1,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, {
    debug: process.env.NODE_ENV !== "production",
  }),
};
const quickCompilerpersistConfig = {
  key: "root",
  storage: storage,
  version: 0,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(quickCompilerMigrations, {
    debug: process.env.NODE_ENV !== "production",
  }),
};

const rootReducer = combineReducers({
  appConfigReducer: persistReducer(appPersistConfig, appConfigSlice),
  quickCompilerReducer: persistReducer(
    quickCompilerpersistConfig,
    quickCompilerSlice
  ),
});

// const sitePersistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    currentProblemReducer: currentProblemSlice,
    problemsListReducer: problemsListSlice,
    dripReducer: dripSlice,
    quickCompilerReducerNp: quickCompilerSliceNonPersisted,
    persistedReducer: rootReducer,
    batchConfigReducer: batchConfigSlice,
    adminReducer: adminSlice,
    jobPortalReducer: jobPortalSlice,
    contestReducer: contestSlice,
    mockSeriesReducer: mockSeriesSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
