export function handlePopupOutsideClick(
  event,
  dropdownRef,
  setIsActive,
  modelKey
) {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    if (modelKey) {
      setIsActive((prev) => {
        return { ...prev, [modelKey]: false };
      });
    } else {
      setIsActive(false);
    }
  }
}

export function tooglePopupState(setActive, state, key) {
  if (key) {
    setActive((prev) => {
      return { ...prev, [key]: !prev[key] };
    });
    return;
  }
  setActive(!state);
}
export function openPopupHandler(setActive) {
  setActive(true);
}
export function closepopupHandler(setActive, modelKey) {
  if (modelKey) {
    setActive((prev) => {
      return { ...prev, [modelKey]: false };
    });
    return;
  }
  setActive(false);
}
