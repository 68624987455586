import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { EditProfileInput } from "../../../../pages/settings/components/EditProfileInput";
import { setIsReportBugModalOpen } from "../../../../redux/slices/appConfigSlice";
import { CTAButton } from "../ui/CTAButton";
import IconWrapper from "../ui/IconWrapper";
import { DialogModal } from "../ui/Modal";
import { Description, Text, Title } from "../ui/Typography";
import { InfoTooltip } from "../ui/Tooltip";
import Dropzone from "react-dropzone";
import { cn } from "../../helpers/utils";
import Badge from "../ui/Badges";
import ReCAPTCHA from "react-google-recaptcha";
import { axiosClient } from "../../../../utils/axiosClient";

const MAX_FILE_SIZE = 5 * 1024 * 1000; // 5MB
export const ReportBugTrigger = (props) => {
  const { WrapperComponent } = props;

  const dispatch = useDispatch();
  const { isReportBugModalOpen } = useSelector(
    (state) => state.persistedReducer.appConfigReducer
  );
  function reportBugModalHandler() {
    dispatch(setIsReportBugModalOpen(!isReportBugModalOpen));
  }
  return <WrapperComponent onClick={reportBugModalHandler} />;
};
export const ReportBugButton = (props) => {
  const { onClick } = props;
  return (
    <button
      className="p-2 rounded-full group bg-neutral-100 hover:bg-neutral-200"
      onClick={onClick}
    >
      <InfoTooltip tooltipText="Report Bug">
        <IconWrapper
          icon="bug"
          size="22"
          className="text-blue-500 transition-colors duration-300"
        />
      </InfoTooltip>
    </button>
  );
};

export const ImageUploader = (props) => {
  const { setError, setImageFile } = props;
  const [isDraggingActive, setIsDraggingActive] = useState(false);
  function imageUploadHandler(acceptedFiles) {
    try {
      setIsDraggingActive(false);
      setImageFile(acceptedFiles[0]);
    } catch (error) {
      setError(error.message);
    }
  }

  function dragEnterHandler() {
    setIsDraggingActive(true);
  }

  function dragLeaveHandler() {
    setIsDraggingActive(false);
  }
  return (
    <div className="flex-col justify-center gap-1.5 t-flex">
      <label htmlFor="image">
        <Text size="sm" weight="md" className="justify-start gap-1 t-flex">
          Image
          <Badge
            size="xxs"
            variant="secondary"
            className="bg-teal-100 dark:bg-teal-900 text-teal-500 w-max font-medium py-[2px] px-1.5 [&>span]:leading-[1.3]"
            border="rounded"
          >
            Optional
          </Badge>
        </Text>
      </label>
      <Dropzone
        onError={(err) => setError(err.message)}
        onDragLeave={dragLeaveHandler}
        onDragEnter={dragEnterHandler}
        multiple={false}
        maxSize={MAX_FILE_SIZE}
        onDrop={imageUploadHandler}
        accept={{ "image/*": [".jpeg", ".png", ".jpg"] }}
      >
        {({ acceptedFiles, getRootProps, getInputProps }) => {
          return (
            <div
              {...getRootProps()}
              className={cn(
                "flex-col items-center gap-4 px-2 py-6 border-2 border-dashed rounded-md t-flex bg-primary-100  border-primary-300",
                isDraggingActive ? "border-success-400 " : ""
              )}
            >
              <input
                {...getInputProps()}
                id="image"
                accept="image/png, image/jpeg, image/jpg"
              />
              <div>
                <IconWrapper
                  icon="uploadCloud"
                  size="45"
                  className={cn(
                    "text-primary-500",
                    isDraggingActive ? "animate-bounce text-success-400" : ""
                  )}
                />
              </div>
              <div>
                <Description
                  size="sm"
                  variant="secondary"
                  weight="lg"
                  className="justify-center gap-1 t-flex"
                >
                  Drag & Drop image or
                  <Text
                    size="sm"
                    weight="lg"
                    className="leading-none underline underline-offset-1 text-primary-500 "
                  >
                    Browse
                  </Text>
                </Description>
              </div>
              {acceptedFiles && acceptedFiles[0] && (
                <div className="items-center gap-2 p-1 border rounded bg-neutral-100 t-flex border-primary-500">
                  <div>
                    <IconWrapper
                      icon="image"
                      size="16"
                      className="text-primary-500"
                    />
                  </div>
                  <div>
                    <Text size="xs" weight="md">
                      {acceptedFiles[0].name}
                    </Text>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};
const ReportBugForm = (props) => {
  const { closeModal } = props;
  const recaptchaRef = useRef(null);
  const [reCaptchaVerificationToken, setReCaptchaVerificationToken] =
    useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState(null);
  const isDarkTheme = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isDarkMode
  );
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isValid },
    getValues,
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      pageLink: "",
    },
  });
  async function reportBugSubmitHandler() {
    const { title, description, pageLink } = getValues();

    try {
      setError(null);
      const formData = new FormData();
      formData.append("image", imageFile);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("pageLink", pageLink);
      formData.append("ReCAPTCHA", reCaptchaVerificationToken);
      await axiosClient.post(`/contact/bug`, formData);
    } catch (error) {
      setError(error.message);
    } finally {
      recaptchaRef.current && recaptchaRef.current.reset();
    }
  }

  function reCaptchaChangeHandler(captchaToken) {
    setReCaptchaVerificationToken(captchaToken);
  }
  useEffect(() => {
    return () => {
      recaptchaRef.current && recaptchaRef.current.reset();
    };
  }, []);
  const isDisabled = isSubmitting || !isValid || !reCaptchaVerificationToken;
  if (!error && isSubmitSuccessful) {
    return (
      <div className="flex-col items-center gap-4 t-flex">
        <div className="items-center justify-center p-2 border-4 rounded-full border-success-400 t-flex">
          <IconWrapper icon="check" size="40" className="text-success-400" />
        </div>
        <div>
          <Description size="md" variant="secondary" className="text-center">
            Thankyou for reporting the bug. It's very helpful for us!
          </Description>
        </div>
        <div>
          <CTAButton variant="primary" size="xs" onClick={closeModal}>
            Close Bug Report Panel
          </CTAButton>
        </div>
      </div>
    );
  }
  return (
    <div>
      {error && (
        <div className="gap-1.5 p-2 border mb-4 rounded-md border-danger-400 t-flex bg-red-100 ">
          <div>
            <IconWrapper icon="alert" size="16" className="text-danger-700" />
          </div>
          <div className="flex-col gap-0.5 t-flex">
            <div>
              <Text
                size="xs"
                weight="lg"
                className="items-center justify-start text-danger-700"
              >
                An Error Occurred!
              </Text>
            </div>
            <div>
              <Description size="xs" weight="md" className="text-danger-400">
                {`${
                  error || "Something went wrong"
                }. Please try again or contact us on Discord.`}
              </Description>
            </div>
          </div>
        </div>
      )}

      <form
        className="flex-col gap-4 t-flex"
        onSubmit={handleSubmit(reportBugSubmitHandler)}
      >
        <div className="flex-col justify-center gap-1.5 t-flex">
          <label htmlFor="title">
            <Text size="sm" weight="md" className="justify-start">
              Title
            </Text>
          </label>
          <EditProfileInput
            id="title"
            name="title"
            {...register("title", { maxLength: 100, required: true })}
            placeholder="Write a title here..."
            className="bg-transparent border-border-500"
          />
        </div>
        <div className="flex-col justify-center gap-1.5 t-flex">
          <label htmlFor="pageUrl">
            <Text size="sm" weight="md" className="justify-start">
              Page URL
            </Text>
          </label>
          <EditProfileInput
            id="pageUrl"
            name="pageUrl"
            {...register("pageLink", { required: true })}
            placeholder={`${window.location.origin}/classroom/...`}
            className="bg-transparent border-border-500"
          />
        </div>
        <div className="flex-col justify-center gap-1.5 t-flex">
          <label htmlFor="description">
            <Text size="sm" weight="md" className="justify-start">
              Description
            </Text>
          </label>
          <textarea
            {...register("description", { maxLength: 1000, required: true })}
            placeholder="Write description about the issue..."
            type="text"
            id="description"
            spellCheck="false"
            name="description"
            rows="5"
            className="px-2 py-2.5  resize-none text-sm font-medium  rounded outline-none appearance-none  border text-primaryText placeholder:neutral-400 placeholder:font-thin border-border-500 focus:ring-primary-500 focus:ring-2 focus:border-transparent bg-transparent"
          />
        </div>
        <ImageUploader setImageFile={setImageFile} setError={setError} />
        <div className="min-h-[80px]">
          <ReCAPTCHA
            theme={isDarkTheme ? "dark" : "light"}
            ref={recaptchaRef}
            className="recaptcha-container"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={reCaptchaChangeHandler}
          />
        </div>
        <div>
          <CTAButton
            disabled={isDisabled}
            type="submit"
            variant="destructive"
            isLoading={isSubmitting}
            size="md"
            className="w-full text-base"
            icon="bug"
            iconProps={{ size: 20 }}
          >
            Report
          </CTAButton>
        </div>
      </form>
    </div>
  );
};
const ReportBug = () => {
  const dispatch = useDispatch();
  const { isReportBugModalOpen } = useSelector(
    (state) => state.persistedReducer.appConfigReducer
  );
  function reportBugModalHandler() {
    dispatch(setIsReportBugModalOpen(false));
  }
  return (
    <DialogModal
      isDisabled={false}
      type="default"
      isOpen={isReportBugModalOpen}
      isDispatcher
      hasTheme
      dispatchFunction={reportBugModalHandler}
      overlayClassName="bg-opacity-60"
      wrapperClassName="max-w-[500px]"
    >
      <div className="w-full p-4 border rounded-lg shadow-lg bg-neutral-100 border-border-500">
        <div className="items-center justify-between mb-4 t-flex">
          <Title size="lg">Report a bug</Title>
          <button className="group" onClick={reportBugModalHandler}>
            <IconWrapper
              icon="close"
              size="25"
              className="group-hover:text-danger-400 text-primaryText"
            />
          </button>
        </div>

        <ReportBugForm closeModal={reportBugModalHandler} />
      </div>
    </DialogModal>
  );
};

export default ReportBug;
