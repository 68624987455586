import { cva } from "class-variance-authority";
import React, { forwardRef, useEffect, useRef } from "react";
import { cn } from "../../helpers/utils";
import { Text } from "./Typography";

export const VALID_TAB_TYPES = {
  LINE: "line",
  BLOCK: "block",
};
const DEFAULT_ACTIVE_TAB_KEY = 0;
const tabLineVariants = cva(
  [
    "font-medium border t-flex items-center justify-center w-full border-transparent",
  ],
  {
    variants: {
      variant: {
        outline: [
          "border-borderColor group [&.active]:bg-primary-500  [&:not(.active)]:hover:bg-neutral-400",
        ],
      },
      size: {
        xs: "text-xs py-2 px-3",
        sm: "text-sm p-2.5",
      },
    },
  }
);

export const TabButton = forwardRef((props, sliderRef) => {
  const {
    id,
    label,
    paramsValue,
    activeTabKey,
    setCurrentTab,
    buttonProps,
    restprops,
  } = props;
  const tabButtonRef = useRef(null);
  function tabHandler() {
    setCurrentTab(paramsValue);
    if (
      buttonProps?.onTabChange &&
      typeof buttonProps?.onTabChange === "function"
    ) {
      buttonProps?.onTabChange(paramsValue);
    }
  }
  function slideBarHandler() {
    if (activeTabKey === paramsValue) {
      sliderRef.current.style.setProperty(
        "--slide-left",
        tabButtonRef.current.offsetLeft + "px"
      );
      sliderRef.current.style.setProperty(
        "--slide-width",
        tabButtonRef.current.offsetWidth + "px"
      );
    }
  }
  useEffect(() => {
    slideBarHandler();
  }, [activeTabKey]);
  return (
    <button
      ref={tabButtonRef}
      onClick={() => tabHandler()}
      type="button"
      role="tab"
      tabIndex={id}
      className={cn("px-4 py-2", buttonProps?.className)}
      {...restprops}
    >
      <div>
        <Text
          size="sm"
          variant="secondary"
          weight="md"
          className={activeTabKey === paramsValue ? "text-primary-500" : ""}
        >
          {label}
        </Text>
      </div>
    </button>
  );
});
export const LineTab = (props) => {
  const {
    tabsData = [],
    className,
    activeTabKey = DEFAULT_ACTIVE_TAB_KEY,
    setCurrentTab,
    extraNode,
    buttonProps,
    ...restProps
  } = props;
  const sliderRef = useRef(null);
  return (
    <div className={cn("t-flex justify-between flex-wrap", className)}>
      <div className="relative flex-wrap items-center border-b t-flex border-b-border-300">
        {tabsData.map((tab) => (
          <TabButton
            key={tab.id}
            {...tab}
            buttonProps={buttonProps}
            activeTabKey={activeTabKey}
            restProps={restProps}
            setCurrentTab={setCurrentTab}
            ref={sliderRef}
          />
        ))}

        <div
          className="absolute  bottom-0 left-0 w-[var(--slide-width)] transition-transform h-0.5 bg-primary-500 translate-x-[var(--slide-left)]"
          ref={sliderRef}
        ></div>
      </div>

      {extraNode}
    </div>
  );
};
export const Tabs = (props) => {
  const { className, variant, size, text, typeLineMetaData, type, ...prop } =
    props;
  const TABS = {
    [VALID_TAB_TYPES.BLOCK]: (
      <div className="group">
        <button
          {...prop}
          className={cn(tabLineVariants({ variant, size }), className)}
        >
          <span className="text-primaryText group-[&.active]:text-light">
            {text}
          </span>
        </button>
      </div>
    ),
    [VALID_TAB_TYPES.LINE]: <LineTab {...typeLineMetaData} />,
  };
  return TABS[type];
};
