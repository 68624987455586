import { includeUserFilterDropdown } from "../utils/constant";

export function restructureExportLeadsMetaData(metaData, filterData) {
  const dropdownDataClone = JSON.parse(JSON.stringify(filterData));
  for (let dropdownData of dropdownDataClone) {
    const metaOptions = metaData[dropdownData.dataKey];
    if (!metaOptions) continue;
    dropdownData.options = metaOptions;
  }
  return dropdownDataClone;
}

export function exportLeadsApiParams(searchParams) {
  const includeUsers = searchParams.has("include", "users");
  const includeLeads = searchParams.has("include", "leads");
  const resultIntersection = searchParams.has("resultType", "intersection");
  const resultUnion = searchParams.has("resultType", "union");
  const enrolledBatches = searchParams
    .getAll("enrolledBatches")
    .map((batch) => Number(batch.split("_")[1]));
  const enrolledBundles = searchParams
    .getAll("enrolledBundles")
    .map((bundle) => Number(bundle.split("_")[1]));
  const notEnrolledBatches = searchParams
    .getAll("notEnrolledBatches")
    .map((batch) => Number(batch.split("_")[1]));
  const notEnrolledBundles = searchParams
    .getAll("notEnrolledBundles")
    .map((bundle) => Number(bundle.split("_")[1]));
  const lastViewStartDate =
    searchParams.get("lastViewStartDate") &&
    new Date(searchParams.get("lastViewStartDate")).valueOf();
  const lastViewEndDate =
    searchParams.get("lastViewEndDate") &&
    new Date(searchParams.get("lastViewEndDate")).valueOf();
  const leadsTypes = searchParams.getAll("leadTypes");
  const leadBatchIds = searchParams
    .getAll("leadBatches")
    .map((batch) => Number(batch.split("_")[1]));
  const leadBundleIds = searchParams
    .getAll("leadBundles")
    .map((bundle) => Number(bundle.split("_")[1]));
  const hasPhoneNumber = Boolean(searchParams.get("includePhoneNumber"));
  return {
    primaryFilters: {
      includeUsers,
      includeLeads,
      resultIntersection,
      resultUnion,
    },
    secondaryFilters: {
      enrolledBatches,
      enrolledBundles,
      notEnrolledBatches,
      notEnrolledBundles,
      lastViewStartDate,
      lastViewEndDate,
      leadBatchIds,
      leadBundleIds,
      leadsTypes,
      hasPhoneNumber,
    },
  };
}
