import React from "react";
import { GrFormClose } from "react-icons/gr";
import "../styles/lead-details.scss";
import { Tooltip } from "antd";
const LeadDetails = ({ setIsModelActive, data, modelKey }) => {
  function modalHandler() {
    setIsModelActive((prev) => {
      return { ...prev, [modelKey]: false };
    });
  }
  return (
    <div className="flex admin__model__container">
      <div
        className="model__overlay"
        role="button"
        onClick={modalHandler}
      ></div>
      <div className="model__content__container">
        <div className="flex model__header">
          <div className="heading">
            <h2 className="admin__panel__secondary__heading">Lead Details</h2>
          </div>
          <button className="flex admin__hover__button" onClick={modalHandler}>
            <GrFormClose />
          </button>
        </div>
        <div className="model__body leads__modal__body">
          <div className="flex user__details">
            <div className="flex user__image">
              <img src={data?.photo} alt="user-profile" />
            </div>
            <div className="user__info">
              <h4 className="admin__panel__primary__text ">{data?.name}</h4>
              <p className="admin__panel__secondary__text">{data?.email}</p>
            </div>
          </div>
          <div className="order__details">
            <div className="heading">
              <h4 className="admin__panel__primary__heading">Lead Details :</h4>
            </div>
            <div className="order__details__inner__wrapper">
              <div className="batch__details detail__container">
                <div className="heading">
                  <h4 className="admin__panel__primary__text">Batch Details</h4>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Batch Name -</p>
                  <p className="admin__panel__primary__text">
                    {data.batchName}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Launched At -</p>
                  <p className="admin__panel__primary__text">
                    {data.batchLaunchDate}
                  </p>
                </div>
              </div>
              <div className="transaction__details detail__container">
                <div className="heading">
                  <h4 className="admin__panel__primary__text">Views Details</h4>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">Total Views -</p>
                  <p className="admin__panel__primary__text">
                    {data.views?.toLocaleString()}
                  </p>
                </div>
                <div className="flex detail__wrapper">
                  <p className="admin__panel__secondary__text">
                    Last View At -
                  </p>
                  <p className="admin__panel__primary__text">
                    {data.lastViewAt}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LeadStatus = ({ otherReason, status }) => {
  return otherReason ? (
    <Tooltip title={`Reason: ${otherReason}`}>
      <span className={`lead__status ${status}`}>{status}</span>
    </Tooltip>
  ) : (
    <span className={`lead__status ${status}`}>{status}</span>
  );
};

export const QueryMessage = ({ message }) => {
  return (
    <Tooltip title={`Message: ${message}`}>
      <span className="lead__status query-message-wrapper">Message</span>
    </Tooltip>
  );
};
export default LeadDetails;
