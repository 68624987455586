import { cva } from "class-variance-authority";
import React from "react";
import { cn } from "../../helpers/utils";

const skeletonVariant = cva(["bg-neutral-500 animate-pulse"], {
  variants: {
    variant: {
      input: ["rounded"],
      avatar: ["rounded-full"],
    },
    size: {
      sm: ["h-6 w-6"],
      md: ["h-8 w-8"],
      full: ["w-full h-6"],
      lg: ["h-10 w-10"],
    },
  },
  defaultVariants: {
    variant: "avatar",
    size: "sm",
  },
});

const Skeleton = (props) => {
  const { variant, size, className, ...resProps } = props;
  return (
    <div
      className={cn(skeletonVariant({ variant, size }), className)}
      {...resProps}
    ></div>
  );
};

export const generateSkeleton = (frequency, skeletonData) => {
  const skeletonArray = new Array(frequency);
  skeletonArray.fill(skeletonData);
  return skeletonArray;
};
export function mapGeneratedSkeletons(frequency, template) {
  return generateSkeleton(frequency, template).map((ele, i) => {
    return { ...ele, id: i + 1 };
  });
}
export default Skeleton;
