import React, { useRef, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { endLiveStream } from "../../../../../redux/slices/adminSlice";
import ConfirmModel from "../../../components/modal/ConfirmationModal";
import { tooglePopupState } from "../../../hooks/usePopupHandler";
import PopupLayout from "../../../layout/PopupLayout";
import ActionDropdown from "../../../user/lib/ui/ActionDropdown";
import { CONFIRM_TEXT, actionDropdownData } from "../../utils/constants";
import "../styles/action-button.scss";
import StreamDetails from "./StreamDetails";
const ActionButton = ({ data, id, type, targetName }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState({
    eventDetailsModel: false,
    streamEndModel: false,
    actionModel: false,
  });
  const dispatch = useDispatch();
  const { data: endLiveData, status } = useSelector(
    (state) => state.adminReducer.endLiveStream
  );
  const [endedLive, setEndedLive] = useState([]);

  async function deleteEntryHandler() {
    await dispatch(endLiveStream({ resourceId: id, resourceType: type }));
    setIsActive(false);
    setEndedLive([...endedLive, id]);
  }
  return (
    <div className="flex icon__button__wrapper" ref={dropdownRef}>
      <button
        className="flex icon__button admin__hover__button"
        onClick={() => tooglePopupState(setIsActive, isActive, "actionModel")}
      >
        <MdKeyboardArrowLeft />
      </button>

      <PopupLayout
        setIsPopupActive={setIsActive}
        isPopupActive={isActive}
        ref={dropdownRef}
        modelKey="actionModel"
      >
        <ActionDropdown
          dropdownData={actionDropdownData}
          setState={setIsActive}
        />
      </PopupLayout>
      <PopupLayout
        ref={dropdownRef}
        isPopupActive={isActive}
        setIsPopupActive={setIsActive}
        modelKey={"streamEndModel"}
      >
        <ConfirmModel
          onClick={deleteEntryHandler}
          setIsActive={setIsActive}
          status={status}
          isDisabled={endLiveData?.status === "ok" && endedLive.includes(id)}
          text={CONFIRM_TEXT}
          targetName={`"${targetName}"`}
          buttonText={"Delete"}
          iconType={"delete"}
        />
      </PopupLayout>
      <PopupLayout
        ref={dropdownRef}
        isPopupActive={isActive}
        setIsPopupActive={setIsActive}
        modelKey={"eventDetailsModel"}
      >
        <StreamDetails data={data} setIsModelActive={setIsActive} />
      </PopupLayout>
    </div>
  );
};

export default ActionButton;
