export default function isWithin15Minutes(date1, date2) {
  // Calculate the difference in milliseconds
  const diffInMilliseconds = Math.abs(date2 - date1);

  // Convert milliseconds to minutes
  const diffInMinutes = diffInMilliseconds / (1000 * 60);

  // Check if the difference is less than or equal to 15 minutes
  return diffInMinutes <= 15;
}

export function convertTime(type, value) {
  if (type === "mintoms") {
    return value * 60 * 1000;
  }
  if (type === "daystoms") {
    return value * 24 * 60 * 60 * 1000;
  }
  if (type === "sectominutes") {
    return (value / 60).toFixed(0);
  }
}
