import React, { useState } from "react";
import { CTAButton } from "../../../components/common/import";
import IconWrapper from "../../../components/job-portal/components/ui/IconWrapper";
import { DialogModal } from "../../../components/job-portal/components/ui/Modal";
import {
  Description,
  Title,
} from "../../../components/job-portal/components/ui/Typography";
const ConfirmationModal = ({
  onConfirm,
  isLoading,
  modalDescription,
  actionButtonText,
  confirmButtonText,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <div>
      <CTAButton variant="destructive" size="xs" onClick={openModal}>
        {actionButtonText}
      </CTAButton>

      <DialogModal
        isDisabled={isLoading}
        type="default"
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        overlayClassName="bg-opacity-40"
        wrapperClassName="max-w-[500px]"
      >
        <div className="w-[500px] p-4 bg-neutral-100 border border-border-500 shadow-lg rounded-lg ">
          <div className="flex-col gap-4 t-flex">
            <div className="flex-col gap-2 t-flex">
              <div>
                <IconWrapper
                  icon="alert"
                  size="45"
                  className="text-warning-400"
                />
              </div>
              <div className="flex-col gap-1 t-flex">
                <Title level="h3" size="md" weight="md">
                  Are you absolutely sure?
                </Title>
                <Description
                  size="sm"
                  weight="normal"
                  variant="secondary"
                  dangerouslySetInnerHTML={{ __html: modalDescription }}
                />
              </div>
            </div>
            <div className="justify-end gap-4 t-flex">
              <CTAButton
                disabled={isLoading}
                className="hover:bg-zinc-200 dark:hover:bg-zinc-800"
                variant="secondary"
                onClick={closeModal}
                size="md"
              >
                Discard
              </CTAButton>
              <CTAButton
                disabled={isLoading}
                variant="primary"
                size="md"
                isLoading={isLoading}
                onClick={() => onConfirm(closeModal)}
              >
                {confirmButtonText}
              </CTAButton>
            </div>
          </div>
        </div>
      </DialogModal>
    </div>
  );
};

export default ConfirmationModal;
