module.exports = {
  allProblemsTabKey: "TAB-allProblems",
  leaderboardTabKey: "TAB-leaderboard",
  notesTabKey: "TAB-notes",
  jobPortalTabKey: "TAB-jobPortal",
  webinarsTabKey: "TAB-webinars",
  constestTabKey: "TAB-contest",
  quickCompilerTabKey: "TAB-quickCompiler",
  rankScoreCardKey: "CARD-rankScore",
  nextProblemCardKey: "CARD-nextProblem",
  nextLectureCardKey: "CARD-nextLecture",
  batchStatsCardKey: "CARD/PROFILE-batchStats",
  potdCardKey: "CARD-potd",
  certificateCardKey: "CARD-certificate",
  dripDeadlineCardKey: "CARD-dripDeadline",
  dripUnlockCardKey: "CARD-dripUnlock",
  dailyScoreGraphProfileKey: "PROFILE-dailyScoreGraph",
  batchLedgerProfileKey: "PROFILE-batchLedgerProfileCard",
  badgesProfileKey: "PROFILE-badges",
  submissionsGraphProfileKey: "PROFILE-submissionsGraph",
  recentSubmissionsTableProfileKey: "PROFILE-recentSubmissionsTable",
  mockTestsTabKey: "TAB-mockTests",
};
