import { useEffect } from "react";

export default function useDisableScroll(isActive) {
  useEffect(() => {
    if (isActive) {
      document.body.setAttribute("data-scroll-lock", true);
      return;
    }
    document.body.setAttribute("data-scroll-lock", false);
  }, [isActive]);
}
