export function paginationChangeHandler(
  page,
  setCurrentPage,
  searchParams,
  setSearchParams
) {
  setCurrentPage(page);
  searchParamsHandler("page", page, setSearchParams, searchParams);
}

export function searchInputChangeHandler(value, searchParams, setSearchParams) {
  searchParamsHandler("search", value, setSearchParams, searchParams);
}

export function dropdownValueChangeHandler(
  setDropdownValue,
  setIsActive,
  item
) {
  setDropdownValue({
    name: item.name,
    id: item.id,
    value: item.value,
    type: item.type,
  });
  setTimeout(() => {
    setIsActive(false);
  });
}

export const searchParamsHandler = function (
  key,
  value,
  setSearchParams,
  searchParams
) {
  const params = new URLSearchParams(searchParams.toString()); // Retrieve current parameters
  if (!value) {
    params.delete(key);
  } else params.set(key, value); // Update specific parameter
  setSearchParams(params);
};

export function updateChatStatus(targetId, status, dataSet) {
  const cloneData = JSON.parse(JSON.stringify(dataSet));
  const updatedData = cloneData.map((data) => {
    if (targetId !== data.id) return data;
    data.isLiveChatBlocked = status;
    return data;
  });
  return updatedData;
}
