import React from "react";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import googleSignup from "../img/google-signup.svg";
import companyLogo from "../img/mask-logo.svg";
import useLoginAndRedirect from "../helper/useLoginAndRedirect";
const Login = () => {
  const loginAndRedirectHandler = useLoginAndRedirect();
  const loginStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isLoggedIn
  );
  if (loginStatus) {
    return <Navigate to="/classroom" replace />;
  }
  return (
    <div className="min-h-screen t-flex items-center justify-center bg-[#F3F4F7]">
      <div className="max-w-[380px] w-full p-8 bg-white shadow-sm rounded-2xl">
        <div className="flex-col items-center justify-center gap-2 t-flex">
          <div className="p-2 border rounded-md shadow-sm border-zinc-100">
            <img src={companyLogo} alt="coding shuttle" className="w-8" />
          </div>
          <div>
            <h1 className="text-2xl font-semibold text-neutral-900">
              Welcome Back
            </h1>
          </div>
        </div>
        <div className="flex-col items-center justify-center gap-4 mt-10 t-flex">
          <button onClick={loginAndRedirectHandler("/classroom")}>
            <img src={googleSignup} alt="google signup" />
          </button>
          <div className="relative w-full text-center after:top-1/2 after:-translate-y-1/2 after:left-0 after:absolute after:w-full after:border-t after:border-t-zinc-200">
            <span className="px-2 bg-white relative z-[1]">or</span>
          </div>
          <Link
            to={process.env.REACT_APP_LANDING_PAGE_URL}
            className="px-8 py-3 text-sm font-medium transition-colors duration-300 bg-indigo-700 rounded-md hover:bg-opacity-90 text-slate-50"
          >
            Go to home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
