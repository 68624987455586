import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { cn } from "../../helpers/utils";
import IconWrapper from "./IconWrapper";
import { Text } from "./Typography";

export const DrodownOption = (props) => {
  const { selected, value, hideSelectedIcon } = props;
  return (
    <>
      {selected && !hideSelectedIcon && (
        <IconWrapper
          icon="circleCheck"
          className="absolute left-2.5 text-primary-500 top-[9px]"
          size="18"
        />
      )}
      <Text size="sm" weight="md">
        {value}
      </Text>
    </>
  );
};
const ListDropdown = (props) => {
  const {
    TriggerComponent,
    optionsData = [],
    selectValue,
    onChange,
    triggerComponentProps,
    OptionComponent,
    className,
    hideSelectedIcon,
    disabled,
  } = props;
  const listOptions = React.useMemo(() => {
    return optionsData.map((option, i) => (
      <Listbox.Option as={Fragment} key={i} value={option}>
        {({ active, selected }) => {
          return (
            <li
              role="button"
              aria-disabled={disabled}
              className={cn(
                "w-full relative items-center t-flex py-2 aria-disabled:cursor-auto hover:bg-primary-500 gap-1 [&>span]:hover:text-white cursor-pointer [&>svg]:hover:text-white",
                selected
                  ? "bg-primary-500 [&>span]:text-white [&>svg]:text-white"
                  : "",
                hideSelectedIcon ? "px-1 justify-center" : "px-9"
              )}
            >
              <OptionComponent
                hideSelectedIcon={hideSelectedIcon}
                {...option}
                selected={selected}
              />
            </li>
          );
        }}
      </Listbox.Option>
    ));
  }, [selectValue]);
  return (
    <Listbox
      value={selectValue}
      onChange={onChange}
      className="relative"
      as={"div"}
      by={"value"}
      disabled={disabled}
    >
      <Listbox.Button className="w-full group">
        <TriggerComponent value={selectValue} {...triggerComponentProps} />
      </Listbox.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={cn(
          "absolute w-full top-[48px] border border-border-300 bg-neutral-100 shadow-xl rounded  overflow-y-auto z-[9] max-h-[350px]",
          className
        )}
      >
        <Listbox.Options>{listOptions}</Listbox.Options>
      </Transition>
    </Listbox>
  );
};

export default ListDropdown;
