import React, { useState } from "react";
import { EditProfileInput } from "../../pages/settings/components/EditProfileInput";
import { CTAButton, Description, IconWrapper, Text } from "../common/import";
import ListDropdown from "../job-portal/components/ui/ListDropdown";
import countryCodes from "./all-country-code.json";
import { sendOtp } from "./onboarding";
import { OTP_INPUT_SCREEN } from "./PhoneVerification";
import toast from "react-hot-toast";
import { cn } from "../classroomHeader/imports";
const CountryCodeTriggerNode = ({ value }) => {
  return (
    <div
      className="relative px-2 h-[50px] min-w-[86px] border rounded  bg-neutral-100 border-border-500  t-flex items-center justify-center"
      role="button"
    >
      <div className="items-center gap-1.5 shrink-0 t-flex">
        <Text size="md" weight="md">
          {value}
        </Text>
        <IconWrapper icon="dropdown" size="16" className="text-secondaryText" />
      </div>
    </div>
  );
};

export const CountryCodeDropdownOption = (props) => {
  const { value, code, selected } = props;
  return (
    <>
      {selected && (
        <IconWrapper
          icon="circleCheck"
          className="absolute left-2.5 text-primary-500 top-[9px]"
          size="18"
        />
      )}

      <Text size="sm" weight="md">
        {`${code} ${value}`}
      </Text>
    </>
  );
};
const PhoneNumberInputScreen = ({
  inputValue,
  setInputValue,
  setShowScreen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  function inputChangeHandler(key, value) {
    setInputValue((prev) => ({ ...prev, [key]: value, error: null }));
  }

  // check phone number length
  function validatePhoneNumber() {
    const { countryCode, phoneNumber } = inputValue;
    if (
      Array.isArray(countryCode.maxPhoneLength) &&
      !countryCode.maxPhoneLength.includes(phoneNumber.length)
    ) {
      return false;
    } else if (
      !Array.isArray(countryCode.maxPhoneLength) &&
      phoneNumber.length !== countryCode.maxPhoneLength
    ) {
      return false;
    } else {
      return true;
    }
  }

  // request for otp
  async function phoneNumberSubmitHandler() {
    try {
      setIsLoading(true);
      const { phoneNumber, countryCode } = inputValue;
      await sendOtp(countryCode.value, phoneNumber);
      setShowScreen(OTP_INPUT_SCREEN);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  // clear phone number input
  function clearPhoneInputHandler() {
    setInputValue((prev) => ({ ...prev, phoneNumber: "", error: null }));
  }
  const maxInputLength = Array.isArray(inputValue.countryCode.maxPhoneLength)
    ? inputValue.countryCode.maxPhoneLength[
        inputValue.countryCode.maxPhoneLength.length - 1
      ]
    : inputValue.countryCode.maxPhoneLength;
  const minInputLength = Array.isArray(inputValue.countryCode.maxPhoneLength)
    ? inputValue.countryCode.maxPhoneLength[0]
    : inputValue.countryCode.maxPhoneLength;

  const isPhoneValid = validatePhoneNumber();
  return (
    <React.Fragment>
      <div className="flex-col justify-between h-full t-flex">
        <div>
          <div className="items-start gap-2 basis-1/2 t-flex">
            <ListDropdown
              TriggerComponent={CountryCodeTriggerNode}
              optionsData={countryCodes}
              selectValue={inputValue.countryCode}
              onChange={({ value, phoneLength }) => {
                inputChangeHandler("countryCode", {
                  value,
                  maxPhoneLength: phoneLength,
                });
              }}
              triggerComponentProps={{
                value: inputValue.countryCode.value,
              }}
              OptionComponent={CountryCodeDropdownOption}
              className="w-max top-[54px]"
            />
            <div className="relative max-md:w-full">
              <EditProfileInput
                maxLength={maxInputLength}
                minLength={minInputLength}
                type="tel"
                value={inputValue.phoneNumber}
                className="text-base bg-neutral-100 pl-3 pr-8 border-border-500 h-[50px]"
                id="phoneNumber"
                placeholder="Enter phone number"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  inputChangeHandler("phoneNumber", value);
                }}
              />
              <button
                onClick={clearPhoneInputHandler}
                className={cn(
                  "absolute right-2 transition-opacity group top-4",
                  inputValue.phoneNumber.length > 0
                    ? "opacity-100"
                    : "opacity-0"
                )}
              >
                <IconWrapper
                  icon="close"
                  size="20"
                  className="transition-colors text-secondaryText group-hover:text-primaryText"
                />
              </button>
            </div>
          </div>
          {inputValue.error && (
            <Description size="sm" weight="md" className="mt-2 text-danger-400">
              {inputValue.error}
            </Description>
          )}
        </div>
        <CTAButton
          isLoading={isLoading}
          disabled={isLoading || !isPhoneValid}
          onClick={phoneNumberSubmitHandler}
          variant="primary"
          icon="phone"
          iconProps={{ size: 18 }}
          loaderSize="20"
          size="lg"
          className="flex-row-reverse w-full gap-1 py-3 rounded-lg"
        >
          Get OTP
        </CTAButton>
      </div>
    </React.Fragment>
  );
};

export default PhoneNumberInputScreen;
