import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardRedirect from "./components/DashboardRedirect.jsx";
import HeaderV3Wrapper from "./components/HeaderV3Wrapper";
import IsLoggedIn from "./components/IsLoggedIn";
import Loader from "./components/Loader/Loader";
import Login from "./components/Login.jsx";
import Classroom from "./pages/classroom/Classroom.jsx";
const Certificate = lazy(() => import("./pages/certificate/Certificate.jsx"));
const ClassroomProvider = lazy(() => import("./providers/ClassroomProvider"));
const ContestContentLayout = lazy(() =>
  import("./layouts/ContestContentLayout")
);
const ContestInstruction = lazy(() =>
  import("./pages/contest/ContestInstruction")
);
const ContestQuestions = lazy(() => import("./pages/contest/ContestQuestions"));
const ContestLeaderboard = lazy(() =>
  import("./pages/contest/ContestLeaderboard")
);
const ShowLeaderboard = lazy(() =>
  import("./components/Course/leaderboard/ShowLeaderboard")
);
const ExploreProblems = lazy(() =>
  import("./components/explore-problems/ExploreProblems")
);
const BatchLeaderboard = lazy(() =>
  import("./components/leaderboard/Leaderboard")
);
const PaymentStatus = lazy(() => import("./components/checkout/PaymentStatus"));
const Checkout = lazy(() => import("./pages/checkout/Checkout.jsx"));
const CourseItem = lazy(() => import("./components/Course/CourseItem"));
const LoginRedirect = lazy(() => import("./components/login/LoginRedirect"));
const VideoPlayer = lazy(() => import("./components/video player/VideoPlayer"));
const UserProfile = lazy(() => import("./pages/user-profile/UserProfile"));
const PublicEvents = lazy(() => import("./components/Events/PublicEvents"));
const EventDetails = lazy(() =>
  import("./components/Events/event-details/EventDetails")
);
const WebinarLive = lazy(() =>
  import("./components//Events/webinar-live/WebinarLive")
);
const WebinarRecording = lazy(() =>
  import("./components/Events/WebinarRecording")
);
const ObjectiveContainer = lazy(() =>
  import("./components/problems/Objectives/ObjectiveContainer")
);
const LiveClasses = lazy(() => import("./components/LiveClasses/LiveClasses"));
const ProblemsContainer = lazy(() =>
  import("./components/problems/ProblemsContainer")
);
const QuickCompiler = lazy(() =>
  import("./components/quick compiler/QuickCompiler")
);
const Settings = lazy(() => import("./pages/settings/Settings.jsx"));
const EditProfile = lazy(() => import("./pages/settings/EditProfile.jsx"));
const CertificateVerification = lazy(() =>
  import("./pages/certificate/CertificateVerification.jsx")
);
const NotFound = lazy(() => import("./components/notfound/NotFound"));
const Unauthorized = lazy(() =>
  import("./components/unauthorized/Unauthorized")
);
const PurchaseHistory = lazy(() => import("./pages/settings/PurchaseHistory"));
const WeekDrip = lazy(() => import("./components/week-drip/WeekDrip"));
const AllNotes = lazy(() => import("./pages/allNotes/AllNotes"));

// admin imports
const Admin = lazy(() => import("./components/admin/Admin"));
const Dashboard = lazy(() => import("./components/admin/dashboard/Dashboard"));
const Transaction = lazy(() =>
  import("./components/admin/transaction/Transactions")
);
const AllBatchEvents = lazy(() => import("./components/Events/AllBatchEvents"));
const Users = lazy(() => import("./components/admin/user/Users"));
const SalesLeads = lazy(() => import("./components/admin/leads/SalesLeads"));
const ExportLeads = lazy(() =>
  import("./components/admin/export-leads/ExportLeads.jsx")
);
const EventsAndLive = lazy(() =>
  import("./components/admin/event&live/EventsAndLive")
);
const RoleBasedRedirect = lazy(() =>
  import("./components/admin/layout/RoleBasedRedirect")
);
const JobPortal = lazy(() => import("./pages/job-portal/JobPortal"));
const JobDetails = lazy(() =>
  import("./pages/job-portal/components/JobDetails")
);
const ClassroomLayout = lazy(() =>
  import("./components/job-portal/layouts/ClassroomLayout")
);
const CheckJobPortalAccess = lazy(() =>
  import("./components/job-portal/layouts/CheckJobPortalAccess")
);
const Contest = lazy(() => import("./pages/contest/Contest"));
const ContestDetails = lazy(() => import("./pages/contest/ContestDetails"));
const Referral = lazy(() => import("./pages/referral/Referral.jsx"));
const AdminReferral = lazy(() =>
  import("./components/admin/referral/AdminReferral.jsx")
);
const MockSeries = lazy(() => import("./pages/mock-series/MockSeries.jsx"));
const MockSeriesLayout = lazy(() => import("./layouts/MockSeriesLayout.jsx"));
const MockSeriesDetails = lazy(() =>
  import("./pages/mock-series/MockSeriesDetails.jsx")
);

function AllRoutes() {
  const siteThemeStatus = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.isDarkMode
  );

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route index element={<Navigate to="/classroom" replace />} />
        <Route path="/" element={<HeaderV3Wrapper />}>
          <Route exact path="/forbidden" element={<Unauthorized />} />
          <Route path="*" exact element={<NotFound />} />
          <Route
            path="/certificate/verify/:uid"
            element={<CertificateVerification />}
          />
          <Route path="/events" element={<PublicEvents />} />
          <Route path="/events/:eventSlug" element={<EventDetails />} />
          <Route path="/checkout/:type/:batchSlug" element={<Checkout />} />

          <Route element={<IsLoggedIn />}>
            <Route path="account" element={<Settings />}>
              <Route index element={<Navigate to="edit-profile" replace />} />
              <Route path="edit-profile" element={<EditProfile />} />
              <Route path="purchase-history" element={<PurchaseHistory />} />
            </Route>
            <Route
              path="/checkout/status/:orderId"
              element={<PaymentStatus />}
            />
            <Route path="/refer" element={<Referral />} />
          </Route>
          <Route path="/profile/:usernameSlug" element={<UserProfile />} />
        </Route>
        <Route path="/quick-compiler/:uid?" element={<QuickCompiler />} />

        <Route
          exact
          path="/connect/:providerName/redirect"
          element={<LoginRedirect />}
        />

        <Route element={<IsLoggedIn />}>
          <Route element={<ClassroomProvider />}>
            <Route path="/classroom" element={<Classroom />} />

            <Route path="/classroom/:batchSlug/" element={<ClassroomLayout />}>
              <Route index element={<Navigate to="dashboard" />} />
              <Route path="dashboard" element={<DashboardRedirect />} />

              <Route path="problems" element={<ExploreProblems />} />
              <Route path="leaderboard" element={<ShowLeaderboard />}>
                <Route path="" element={<BatchLeaderboard />} />
              </Route>
              <Route path="events" element={<AllBatchEvents />} />
              <Route path="allnotes" element={<AllNotes />} />
              <Route path="contest" element={<Contest />} />
              <Route path="mock-series" element={<MockSeries />} />
              <Route
                path="mock-series/:mockTestSlug"
                element={<MockSeriesLayout />}
              >
                <Route index element={<MockSeriesDetails />} />
                <Route
                  path="problems/:offeringSlug"
                  element={<ProblemsContainer isMockTest />}
                />
              </Route>
              <Route path="contest/:contestSlug" element={<ContestDetails />} />
              <Route path="certificate" element={<Certificate />} />
              <Route element={<CheckJobPortalAccess />}>
                <Route path="jobs" element={<JobPortal />}>
                  <Route path=":jobSlug" element={<JobDetails />} />
                </Route>
              </Route>
              <Route path="w/:weekSlug" element={<WeekDrip />} />
              <Route
                path="problems/:offeringSlug"
                element={<ProblemsContainer />}
              />
              <Route
                path="mcq/:offeringSlug"
                element={<ObjectiveContainer />}
              />
              <Route
                path="w/:weekSlug/lecture/:offeringSlug"
                element={<VideoPlayer />}
              />
              <Route
                path="events/:eventSlug/:offeringSlug"
                element={<WebinarRecording />}
              />
            </Route>

            <Route
              path="/contest/:contestSlug"
              element={<ContestContentLayout />}
            >
              <Route index element={<Navigate to="instructions" replace />} />
              <Route path="instructions" element={<ContestInstruction />} />
              <Route path="problems" element={<ContestQuestions />} />
              <Route path="leaderboard" element={<ContestLeaderboard />} />
              <Route
                path="problems/:offeringSlug"
                element={<ProblemsContainer isContest />}
              />
            </Route>
          </Route>
          <Route
            path="/classroom/:batchSlug/live/:liveSlug"
            element={<LiveClasses />}
          />
          <Route
            path="/admin"
            element={
              <Admin className={!siteThemeStatus ? "white-theme" : ""} />
            }
          >
            <Route index element={<RoleBasedRedirect />} />
            <Route path="overview" element={<Dashboard />} />
            <Route path="transactions" element={<Transaction />} />
            <Route path="users" element={<Users />} />
            <Route path="leads" element={<SalesLeads />} />
            <Route path="leads/export" element={<ExportLeads />} />
            <Route path="eventsandlive" element={<EventsAndLive />} />
            <Route path="referral" element={<AdminReferral />} />
          </Route>
        </Route>
        <Route element={<IsLoggedIn />}>
          <Route path="/events/:eventSlug/watch" element={<WebinarLive />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default AllRoutes;
