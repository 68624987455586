import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../utils/axiosClient";

export default function useFetchData(endpoint, onSuccess, options = {}) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  async function fetchData() {
    try {
      setIsLoading(true);
      const response = await axiosClient(`${endpoint}`, options);
      if (typeof onSuccess === "function") onSuccess(response?.data, setData);
      else setData(response?.data);
    } catch (error) {
      setError(error);
      toast.error(error.message);
      if (error.status === 404) navigate("/404", { replace: true });
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return { data, isLoading, error, fetchData, setData };
}

export async function postData(endPoint, body, setLoading) {
  try {
    setLoading(true);
    const { data } = await axiosClient.post(endPoint, body);
    return data;
  } catch (error) {
    toast.error(error.message);
  } finally {
    setLoading(false);
  }
}
