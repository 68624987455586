import React, { useRef, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { tooglePopupState } from "../../../hooks/usePopupHandler";
import PopupLayout from "../../../layout/PopupLayout";
import ActionDropdown from "../../../user/lib/ui/ActionDropdown";
import { actionModelData } from "../../utils/constants";
import EditReferralStatus from "./EditReferralStatus";
import ReffereeDetails from "./ReffereeDetails";

const ActionButton = ({ data }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState({
    userModel: false,
    statusModel: false,
    actionModel: false,
  });
  return (
    <div className="flex icon__button__wrapper" ref={dropdownRef}>
      <button
        className="flex icon__button admin__hover__button"
        onClick={() => tooglePopupState(setIsActive, isActive, "actionModel")}
      >
        <MdKeyboardArrowLeft />
      </button>
      <PopupLayout
        setIsPopupActive={setIsActive}
        isPopupActive={isActive}
        ref={dropdownRef}
        modelKey="actionModel"
      >
        <ActionDropdown dropdownData={actionModelData} setState={setIsActive} />
      </PopupLayout>
      <PopupLayout
        setIsPopupActive={setIsActive}
        isPopupActive={isActive}
        ref={dropdownRef}
        modelKey={"userModel"}
      >
        <ReffereeDetails
          data={data.reffereeDetails}
          setIsModelActive={setIsActive}
          modelKey={"userModel"}
        />
      </PopupLayout>
      <PopupLayout
        setIsPopupActive={setIsActive}
        isPopupActive={isActive}
        ref={dropdownRef}
        modelKey={"statusModel"}
      >
        <EditReferralStatus
          setIsActive={setIsActive}
          modelKey={"statusModel"}
          referalId={data.id}
        />
      </PopupLayout>
    </div>
  );
};

export default ActionButton;
