import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import defaultProfile from "../../../../img/defaultprofile.png";
import { fetchReferrals } from "../../../../redux/slices/adminSlice";
import ActionButton from "../lib/ui/ActionButton";
import { dateFormatOptions } from "../utils/constants";
export function restructureReferrals(dataSet) {
  const restructuredData = dataSet.map((data) => {
    const dataTemplate = {
      id: data.id,
      photo: data.referer?.photo || defaultProfile,
      name: data.referer?.name,
      email: data.referer?.email,
      referalCode: data.referalCode,
      status: data.status,
      createdAt: new Date(data.createdAt).toLocaleString(
        "en-IN",
        dateFormatOptions
      ),
      reffereeDetails: data.transaction,
    };
    return {
      ...dataTemplate,
      action: <ActionButton data={dataTemplate} />,
    };
  });

  return restructuredData;
}
export function useFetchReferrals(filters) {
  let TIMEOUT_ID;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      searchParams.get("page") ||
      searchParams.get("search") ||
      filters.length > 0
    ) {
      TIMEOUT_ID = setTimeout(() => {
        dispatch(
          fetchReferrals({
            referalStatuses: searchParams.getAll("referralStatus"),

            startDate:
              searchParams.get("startDate") &&
              new Date(searchParams.get("startDate")).valueOf(),
            endDate:
              searchParams.get("endDate") &&
              new Date(searchParams.get("endDate")).valueOf(),

            page: searchParams.get("page"),
            search: searchParams.get("search"),
          })
        );
      }, 1000);
    } else {
      dispatch(fetchReferrals({}));
    }
    return () => clearTimeout(TIMEOUT_ID);
  }, [searchParams.get("page"), searchParams.get("search"), filters]);
}
