import React from "react";
import { Skeleton } from "../../../components/common/import";

const ContestLeaderboardCardSkeleton = () => {
  return (
    <div className="flex-col gap-3 p-4 border rounded-lg shadow-sm border-border-300 t-flex bg-neutral-100">
      <div>
        <div className="items-center justify-center rounded-full t-flex w-max">
          <Skeleton size="lg" className="w-12 h-12" />
        </div>
      </div>
      <div className="flex-col gap-2 t-flex">
        <div className="items-center gap-1 t-flex">
          <Skeleton variant="input" size="full" className="w-10/12 h-7" />
        </div>
        <div>
          <Skeleton variant="input" size="full" className="w-1/3 h-6" />
        </div>
      </div>
    </div>
  );
};

export default ContestLeaderboardCardSkeleton;
