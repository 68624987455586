export const DESCENDING_SORT = "desc";
export const ASCENDING_SORT = "asc";
export const tableHeader = [
  {
    label: "ID",
    dataIndex: "id",
    key: "id",
    id: 1,
  },
  {
    label: "Name",
    dataIndex: "name",
    key: "name",
    id: 2,
    withImage: true,
  },
  {
    label: "Contact",
    dataIndex: "contact",
    key: "contact",
    id: 3,
  },
  {
    label: "Graduation Year",
    dataIndex: "graduationYear",
    key: "graduationYear",
    id: 10,
  },
  {
    label: "Lead Type",
    dataIndex: "type",
    key: "type",
    id: 4,
  },
  {
    label: "Query",
    dataIndex: "querySubject",
    key: "querySubject",
    id: 5,
  },
  {
    label: "Status",
    dataIndex: "leadStatus",
    key: "leadStatus",
    id: 6,
  },
  {
    label: "Last View At",
    id: 7,
    dataIndex: "lastViewAt",
    key: "lastViewAt",
    sort: true,
    sortKey: "leadLastViewSortType",
  },
  {
    label: "Total Views",
    dataIndex: "views",
    key: "views",
    id: 8,
    sort: true,
    sortKey: "leadViewsSortType",
  },

  {
    label: "More",
    dataIndex: "action",
    id: 9,
  },
];

export const csvFileHeader = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Contact",
    key: "contact",
  },
  {
    label: "Batch",
    key: "batchName",
  },
  {
    label: "Status",
    key: "leadStatus",
  },
  {
    label: "Last View At",
    key: "lastViewAt",
  },
  {
    label: "Total Views",
    key: "views",
  },
];
export const dropdownDataset = [
  {
    id: 1,
    index: "name",
    title: "Course",
    label: "batches",
    options: [],
    isRadioInput: true,
  },
  {
    id: 2,
    index: "type",
    title: "Type",
    label: "leadTypes",
    options: [],
  },
  {
    id: 3,
    index: "name",
    title: "Status",
    label: "leadsStatus",
    options: [],
  },
  {
    id: 4,
    title: "Time period",
    isDate: true,
    filterBasis: "timePeriod",
    options: [
      {
        id: "startDate#1",
        label: "From :",
        name: "startDate",
        dataLabel: "timePeriod",
      },
      {
        id: "endDate#1",
        label: "To :",
        name: "endDate",
        dataLabel: "timePeriod",
      },
    ],
  },
  {
    id: 5,
    title: "Last view",
    isDate: true,
    filterBasis: "lastViewTime",
    options: [
      {
        id: "lastViewStartDate#1",
        label: "From :",
        name: "lastViewStartDate",
        dataLabel: "lastViewTime",
      },
      {
        id: "lastViewEndDate#1",
        label: "To :",
        name: "lastViewEndDate",
        dataLabel: "lastViewTime",
      },
    ],
  },
];

export const leadStatusOptions = [
  {
    id: 1,
    value: "converted",
    name: "Converted",
  },
  {
    id: 2,
    value: "denied",
    name: "Denied",
  },
  {
    id: 3,
    value: "try later",
    name: "Try Later",
  },
  {
    id: 4,
    value: "not connected",
    name: "Not Connected",
  },
  {
    id: 5,
    value: "other",
    name: "Other",
  },
];

export const leadsStatusDropdown = [
  {
    id: 1,
    label: "Status",
    placeholder: "Select Status",
    name: "status",
  },
];
export const INDEX = "name";
export const LABEL = "status";

export const actionModelData = [
  {
    id: 1,
    label: "User Details",
    onClick: (setState, key) =>
      setState((prev) => {
        return { ...prev, [key]: true };
      }),
    key: "userModel",
  },
  {
    id: 2,
    label: "Edit Status",
    onClick: (setState, key) =>
      setState((prev) => {
        return { ...prev, [key]: true };
      }),
    key: "statusModel",
  },
];

export const dateFormatOptions = {
  weekday: "short",
  day: "numeric",
  year: "numeric",
  hour: "2-digit",
  hour12: true,
  minute: "2-digit",
  month: "long",
};
