import React, { useEffect, useState } from "react";
import "../styles/streamDetails.scss";
import { GrFormClose } from "react-icons/gr";
import IconWrapper from "../../../../job-portal/components/ui/IconWrapper";

const ARN = "ARN";
const CHAT_ROOM_ARN = "chatRoomARN";
const PLAYBACK_URL = "playbackURL";
const STREAM_END_POINT = "streamEndPoint";
const STREAM_KEY = "streamKey";
let TIMEOUT_ID;
const StreamDetails = ({ data, setIsModelActive }) => {
  const [isCopied, setIsCopied] = useState({});
  async function copyToClipboardHandler(key, value) {
    if (TIMEOUT_ID) clearTimeout(TIMEOUT_ID);
    await navigator.clipboard.writeText(value);
    setIsCopied((prev) => {
      return prev[key] ? prev : { ...prev, [key]: value };
    });
    TIMEOUT_ID = setTimeout(() => {
      setIsCopied({});
    }, 10000);
  }

  return (
    <div className="flex admin__model__container">
      <div
        className="model__overlay"
        role="button"
        onClick={() => setIsModelActive(false)}
      ></div>
      <div className="model__content__container">
        <div className="flex model__header">
          <div className="heading">
            <h2 className="admin__panel__secondary__heading">
              Channel Details
            </h2>
          </div>
          <button
            className="flex admin__hover__button"
            onClick={() => setIsModelActive(false)}
          >
            <GrFormClose />
          </button>
        </div>
        <div className="model__body">
          <div className="stream__details">
            <div className="stream__details__inner__wrapper">
              <div className="flex detail__wrapper">
                <div className="flex inner__detail__wrapper">
                  <p className="admin__panel__secondary__heading">ARN -</p>
                  <button
                    className="flex copy-button-wrapper"
                    onClick={() =>
                      copyToClipboardHandler(ARN, data.channelDetails.arn)
                    }
                  >
                    <IconWrapper
                      icon={isCopied[ARN] ? "check" : "copy"}
                      size="18"
                      color={isCopied[ARN] && "#00be68"}
                    />
                  </button>
                </div>
                <p className="admin__panel__primary__text">
                  {data.channelDetails.arn}
                </p>
              </div>
              <div className="flex detail__wrapper">
                <div className="flex inner__detail__wrapper">
                  <p className="admin__panel__secondary__heading">
                    Chat Room ARN -
                  </p>
                  <button
                    className="flex copy-button-wrapper"
                    onClick={() =>
                      copyToClipboardHandler(
                        CHAT_ROOM_ARN,
                        data.channelDetails.chatRoomARN
                      )
                    }
                  >
                    <IconWrapper
                      icon={isCopied[CHAT_ROOM_ARN] ? "check" : "copy"}
                      size="18"
                      color={isCopied[CHAT_ROOM_ARN] && "#00be68"}
                    />
                  </button>
                </div>
                <p className="admin__panel__primary__text">
                  {data.channelDetails.chatRoomARN}
                </p>
              </div>
              <div className="flex detail__wrapper">
                <div className="flex inner__detail__wrapper">
                  <p className="admin__panel__secondary__heading">
                    {" "}
                    Playback URL -
                  </p>
                  <button
                    className="flex copy-button-wrapper"
                    onClick={() =>
                      copyToClipboardHandler(
                        PLAYBACK_URL,
                        data.channelDetails.playbackURL
                      )
                    }
                  >
                    <IconWrapper
                      icon={isCopied[PLAYBACK_URL] ? "check" : "copy"}
                      size="18"
                      color={isCopied[PLAYBACK_URL] && "#00be68"}
                    />
                  </button>
                </div>
                <p className="admin__panel__primary__text">
                  {data.channelDetails.playbackURL}
                </p>
              </div>
              <div className="flex detail__wrapper">
                <div className="flex inner__detail__wrapper">
                  <p className="admin__panel__secondary__heading">
                    {" "}
                    Stream End Point -
                  </p>
                  <button
                    className="flex copy-button-wrapper"
                    onClick={() =>
                      copyToClipboardHandler(
                        STREAM_END_POINT,
                        data.channelDetails.streamEndpoint
                      )
                    }
                  >
                    <IconWrapper
                      icon={isCopied[STREAM_END_POINT] ? "check" : "copy"}
                      size="18"
                      color={isCopied[STREAM_END_POINT] && "#00be68"}
                    />
                  </button>
                </div>
                <p className="admin__panel__primary__text">
                  {data.channelDetails.streamEndpoint}
                </p>
              </div>
              <div className="flex detail__wrapper">
                <div className="flex inner__detail__wrapper">
                  <p className="admin__panel__secondary__heading">
                    Stream Key -
                  </p>
                  <button
                    className="flex copy-button-wrapper"
                    onClick={() =>
                      copyToClipboardHandler(
                        STREAM_KEY,
                        data.channelDetails.streamKey
                      )
                    }
                  >
                    <IconWrapper
                      icon={isCopied[STREAM_KEY] ? "check" : "copy"}
                      size="18"
                      color={isCopied[STREAM_KEY] && "#00be68"}
                    />
                  </button>
                </div>

                <p className="admin__panel__primary__text">
                  {data.channelDetails.streamKey}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamDetails;
