import React from "react";
import IconWrapper from "../ui/IconWrapper";
import SpinnerLoader from "../ui/Loader";
import { InfoTooltip } from "../ui/Tooltip";

export const BookmarkButton = (props) => {
  const { loading, targetId, id, bookmarkHandler, hasBookmarked, size } = props;
  return loading && targetId === id ? (
    <SpinnerLoader size={size} />
  ) : (
    <InfoTooltip tooltipText={hasBookmarked ? "Bookmarked" : "Bookmark"}>
      <button onClick={bookmarkHandler}>
        <IconWrapper
          icon={"bookmark"}
          strokeWidth={1.2}
          size={size}
          className={`stroke-primaryText ${
            hasBookmarked ? "fill-primium-400 stroke-primium-400" : ""
          }`}
        />
      </button>
    </InfoTooltip>
  );
};
