import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BsStars } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { useSelector } from "react-redux";
import Dropdown from "../../../components/dropdown/Dropdown";
import { closepopupHandler } from "../../../hooks/usePopupHandler";
import {
  enrollUserInBatch,
  useFetchEnrolledBatches,
} from "../../hooks/useEnrollUser";
import { inputProps } from "../../utils/constantData";
import "../styles/user-enrollment-model.scss";

const BATCH_TYPE = "batch";
const BUNDLE_TYPE = "batchBundle";

const UserEnrollmentModel = ({ setIsActive, id, photo, email, name }) => {
  const [loading, setLoading] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("Select Batch");
  const [filteredBatches, setFilteredBatches] = useState([]);
  const userEnrolledBatches = useSelector(
    (state) => state.adminReducer.userEnrolledbatches.data
  );
  const status = useSelector(
    (state) => state.adminReducer.userEnrolledbatches.status
  );
  const batchesData = useSelector((state) => state.adminReducer.batchesList);
  const bundlesData = useSelector((state) => state.adminReducer.bundlesList);

  const batchDropDownList = [
    ...batchesData.map((batch) => {
      return { ...batch, type: BATCH_TYPE, key: `${batch.id}-${BATCH_TYPE}` };
    }),
    ...bundlesData.map((bundle) => {
      return {
        ...bundle,
        type: BUNDLE_TYPE,
        key: `${bundle.id}-${BUNDLE_TYPE}`,
      };
    }),
  ];
  useFetchEnrolledBatches(id);
  async function enrollUserHandler() {
    const response = await enrollUserInBatch(setLoading, {
      resourceId: dropdownValue.id,
      resourceType: dropdownValue.type,
      userId: id,
    });
    if (response === "ok") {
      setIsActive(false);
      toast.success("User Successfully Enrolled.");
    }
  }

  useEffect(() => {
    if (batchDropDownList) {
      const filteredData = batchDropDownList.filter(
        (batch) =>
          !(batch.id == dropdownValue.id && batch.type == dropdownValue.type)
      );

      setFilteredBatches(filteredData);
    }
  }, [dropdownValue]);
  return (
    <div
      className="enrollment__model__wrapper flex"
      onClick={() => closepopupHandler(setIsActive)}
    >
      <div
        className="enrollment__inner__container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="enrollment__model__header flex">
          <h1 className="admin__panel__secondary__heading">
            Create Enrollment
          </h1>
          <button
            className="close-model flex admin__hover__button"
            onClick={() => closepopupHandler(setIsActive)}
          >
            <GrFormClose />
          </button>
        </div>
        <div className="enrollment__model__body">
          <Skeleton paragraph={{ rows: 8 }} loading={status === "loading"}>
            <div className="user_details_wrapper flex">
              <div className="user__image">
                <img src={photo} alt="" />
              </div>
              <div className="user__name__email flex">
                <p className="admin__panel__primary__text">{name}</p>
                <p className="admin__panel__secondary__text">{email}</p>
              </div>
            </div>
            <div className="already__enrolled__info__wrapper">
              <div className="heading">
                <h2 className="admin__panel__primary__text">
                  Enrolled Batches
                </h2>
              </div>
              <div className="enrolled__batches__container">
                {userEnrolledBatches && userEnrolledBatches.length > 0 ? (
                  userEnrolledBatches.map((batch) => (
                    <div className="enrolled__batch flex" key={batch.id}>
                      <div className="icon__wrapper">
                        <BsStars />
                      </div>
                      <div className="batch__name">
                        <p className="admin__panel__primary__text">
                          {batch.name}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="admin__panel__primary__text">
                    This user is not enrolled in any course.
                  </p>
                )}
              </div>
            </div>
            <div className="enrollment__form flex">
              {inputProps.map((inputProp) => (
                <Dropdown
                  key={inputProp.id}
                  {...inputProp}
                  setDropdownValue={setDropdownValue}
                  dropdownValue={dropdownValue}
                  data={filteredBatches}
                />
              ))}
              <div className="submit__wrapper">
                <button
                  className="admin__panel__primary__text admin__hover__button flex"
                  onClick={enrollUserHandler}
                  disabled={loading || !dropdownValue.id}
                >
                  {loading ? (
                    <span className="spinner__wrapper"></span>
                  ) : (
                    "Create Enrollment"
                  )}
                </button>
              </div>
            </div>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default UserEnrollmentModel;
