import React, { useState } from "react";
import "../styles/edit-lead-status.scss";
import { GrFormClose } from "react-icons/gr";
import { IoAlertCircleOutline } from "react-icons/io5";
import Dropdown from "../../../components/dropdown/Dropdown";
import { leadStatusOptions, leadsStatusDropdown } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  postLeadStatus,
  setUpdatedLeadStatusData,
} from "../../../../../redux/slices/adminSlice";
const EditLeadsStatus = (props) => {
  const { setIsActive, leadId } = props;
  const dispatch = useDispatch();
  const leadUpdateStatus = useSelector(
    (state) => state.adminReducer.leadStatus.status
  );
  const [inputValue, setInputValue] = useState("");
  const [dropdownValue, setDropdownValue] = useState("Select Status");
  function inputHandler(e) {
    setInputValue(e.target.value);
  }
  function cancelHandler() {
    setIsActive(false);
  }
  async function submitHandler() {
    await dispatch(
      postLeadStatus({
        leadId: leadId,
        status: typeof dropdownValue === "object" ? dropdownValue.value : {},
        message:
          typeof dropdownValue === "object" && dropdownValue.value === "other"
            ? inputValue
            : "",
      })
    );
    setIsActive(false);
  }
  return (
    <div className="admin__model__container__message flex">
      <div className="model__content__container">
        <div className="model__header flex">
          <div className="heading">
            <h2 className="admin__panel__secondary__heading">Edit Status</h2>
          </div>
          <button className="flex admin__hover__button" onClick={cancelHandler}>
            <GrFormClose />
          </button>
        </div>
        <div className="model__body">
          {leadsStatusDropdown.map((inputProp) => (
            <Dropdown
              key={inputProp.id}
              {...inputProp}
              setDropdownValue={setDropdownValue}
              dropdownValue={dropdownValue}
              data={leadStatusOptions}
            />
          ))}
          {dropdownValue.id == 5 && (
            <div className="message__input__wrapper">
              <div className="sub__text">
                <p className="admin__panel__primary__text">Specify Reason</p>
              </div>
              <textarea
                name="otherReason"
                id="reason"
                placeholder="write a message here..."
                value={inputValue}
                onChange={inputHandler}
              ></textarea>
              {inputValue.length <= 0 && (
                <div className="alert__wrapper flex">
                  <IoAlertCircleOutline />
                  <p className="required__text">
                    A Specific Reason is Required!
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="model__footer">
          <div className="button__wrapper flex">
            <button className="cancel__button" onClick={cancelHandler}>
              <span>Cancel</span>
            </button>
            <button
              className="admin__hover__button submit__button flex"
              onClick={submitHandler}
              disabled={
                (dropdownValue.id === 5 && !inputValue.length) ||
                typeof dropdownValue !== "object" ||
                leadUpdateStatus === "loading"
              }
            >
              {leadUpdateStatus === "loading" && (
                <span className="loading__spinner"></span>
              )}
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLeadsStatus;
