import { isArray } from "lodash";

export default function getAttemptedQuestion(data) {
  if (!isArray(data) && data.length === 0) return 0;
  let attemptedQuestionsCount = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].counter > 0 || data[i].isAccepted) {
      attemptedQuestionsCount += 1;
    }
  }
  return attemptedQuestionsCount;
}
