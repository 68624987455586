import React, { useRef, useState, memo, forwardRef } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import "./button.scss";
import ActionDropdown from "../../user/lib/ui/ActionDropdown";
import PopupLayout from "../../layout/PopupLayout";
import { tooglePopupState } from "../../hooks/usePopupHandler";
import {
  CHAT_BLOCK_STATUS,
  CHAT_UNBLOCK_STATUS,
  actionButtonDropdownData,
} from "../../user/utils/constantData";
import ConfirmModel from "../modal/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { blockUnblockUser } from "../../../../redux/slices/adminSlice";
import { BsFiletypeCsv } from "react-icons/bs";
import { CSVLink } from "react-csv";
import UserEnrollmentModel from "../../user/lib/ui/UserEnrollmentModel";
const IconButton = ({ user }) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const { status } = useSelector(
    (state) => state.adminReducer.blockUnblockUser
  );
  const [isActive, setIsActive] = useState({
    userModel: false,
    actionModel: false,
    confirmModel: false,
    enrollModel: false,
  });
  async function blockUnblockHandler() {
    await dispatch(
      blockUnblockUser({
        newChatStatus: user.isChatBlocked
          ? CHAT_UNBLOCK_STATUS
          : CHAT_BLOCK_STATUS,
        userId: user.id,
      })
    );
    setIsActive(false);
  }
  return (
    <>
      <div className="flex icon__button__wrapper" ref={dropdownRef}>
        <button
          className="flex icon__button admin__hover__button"
          onClick={() => tooglePopupState(setIsActive, isActive, "actionModel")}
        >
          <MdKeyboardArrowLeft />
        </button>
        <PopupLayout
          setIsPopupActive={setIsActive}
          isPopupActive={isActive}
          modelKey={"actionModel"}
          ref={dropdownRef}
        >
          <ActionDropdown
            dropdownData={actionButtonDropdownData}
            setState={setIsActive}
          />
        </PopupLayout>
        <PopupLayout
          setIsPopupActive={setIsActive}
          isPopupActive={isActive}
          modelKey={"enrollModel"}
          ref={dropdownRef}
        >
          <UserEnrollmentModel {...user} setIsActive={setIsActive} />
        </PopupLayout>
        <PopupLayout
          setIsPopupActive={setIsActive}
          isPopupActive={isActive}
          modelKey={"confirmModel"}
          ref={dropdownRef}
        >
          <ConfirmModel
            isDisabled={status === "loading"}
            setIsActive={setIsActive}
            status={status}
            text={`Are you sure you want to ${
              user.isChatBlocked ? "UNBLOCK" : "BLOCK"
            } this user?`}
            onClick={blockUnblockHandler}
            targetName={`"${user.name}"`}
            buttonText={user.isChatBlocked ? "Unblock" : "Block"}
            iconType={user.isChatBlocked ? "unblock" : "block"}
          />
        </PopupLayout>
      </div>
    </>
  );
};

export const DownloadCSVFile = forwardRef(
  ({ data, header, onClick, isLoading, disabled }, ref) => {
    return (
      <div className="csv__download__button">
        <CSVLink
          data={data}
          filename="Leads Details.csv"
          target="_blank"
          headers={header}
          asyncOnClick={true}
          ref={ref}
        />
        <button
          className="flex admin__hover__button"
          onClick={onClick}
          disabled={isLoading || disabled}
        >
          {isLoading ? (
            <span className="spinner__wrapper"></span>
          ) : (
            <BsFiletypeCsv />
          )}

          <span>Export CSV</span>
        </button>
      </div>
    );
  }
);
export default memo(IconButton);
