import { cva } from "class-variance-authority";
import React, { forwardRef } from "react";
import { cn } from "../../helpers/utils";

const titleVariants = cva([""], {
  variants: {
    variant: {
      primary: ["text-primaryText"],
      secondary: ["text-secondaryText"],
    },
    size: {
      sm: ["text-sm"],
      md: ["text-base"],
      lg: ["text-lg"],
      xl: ["text-xl"],
      "2xl": ["text-[22px]"],
    },
    weight: {
      md: ["font-medium"],
      semibold: ["font-semibold"],
      bold: ["font-bold"],
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
    weight: "semibold",
  },
});

const VALID_LEVEL = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
};
const DEFAULT_LEVEL = VALID_LEVEL["h1"];
export const Title = (props) => {
  const { level, size, variant, className, children, weight, ...restProps } =
    props;
  const Component = VALID_LEVEL[level] || DEFAULT_LEVEL;
  return (
    <Component
      className={cn(titleVariants({ size, variant, weight }), className)}
      {...restProps}
    >
      {children}
    </Component>
  );
};

const descriptionVariants = cva(["max-md:text-xs"], {
  variants: {
    variant: {
      primary: ["text-secondaryText"],
      secondary: ["text-primaryText"],
    },
    size: {
      xs: ["text-xs"],
      sm: ["text-sm"],
      md: ["text-base"],
      lg: ["text-lg"],
    },
    weight: {
      normal: ["font-normal"],
      md: ["font-medium"],
      lg: ["font-semibold"],
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
    weight: "normal",
  },
});
export const Description = forwardRef((props, ref) => {
  const { size, variant, className, children, weight, ...restProps } = props;
  return (
    <p
      ref={ref}
      className={cn(descriptionVariants({ size, variant, weight }), className)}
      {...restProps}
    >
      {children}
    </p>
  );
});

const textVariants = cva(["t-flex items-center justify-center"], {
  variants: {
    variant: {
      primary: ["text-primaryText"],
      secondary: ["text-secondaryText"],
      destructive: ["text-danger"],
    },
    size: {
      xxs: ["text-[10px]"],
      xs: ["text-xs"],
      sm: ["text-sm"],
      md: ["text-base"],
      lg: ["text-lg"],
      xl: ["text-xl"],
      "2xl": ["text-2xl"],
    },
    weight: {
      normal: ["font-normal"],
      md: ["font-medium"],
      lg: ["font-semibold"],
      bold: ["font-bold"],
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
    weight: "normal",
  },
});
export const Text = (props) => {
  const { size, variant, className, children, weight, ...restProps } = props;
  return (
    <span
      className={cn(textVariants({ size, variant, weight }), className)}
      {...restProps}
    >
      {children}
    </span>
  );
};
