import React from "react";
import { Icons } from "../../helpers/Icons";
import { cn } from "../../helpers/utils";

const IconWrapper = (props) => {
  const defaultSize = 22;
  const { icon, size, color, className, ...restProps } = props;
  const Icon = Icons[icon || "location"];
  return Icon ? (
    <Icon
      color={color}
      size={size || defaultSize}
      className={cn("shrink-0", className)}
      {...restProps}
    />
  ) : null;
};

export default IconWrapper;
