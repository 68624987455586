import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { useSlugId } from "../../../helper/restructureData";
import { cn } from "../../classroomHeader/imports";
import { Description, IconWrapper, Text } from "../import";

const dateOptions = {
  day: "2-digit",
  month: "short",
  year: "numeric",
  weekday: "short",
};
const DripNavigation = (props) => {
  const { dripConfig, status } = props;
  const { weekSlug, batchSlug } = useParams();
  const weekId = useSlugId(weekSlug);
  return (
    <>
      <div className="relative group max-md:hidden">
        <Menu>
          <Menu.Button>
            <div
              role="button"
              className="items-center justify-center gap-2 p-2 transition-all border border-blue-500 rounded bg-neutral-100 hover:bg-neutral-200 t-flex"
            >
              <IconWrapper
                icon="calendar"
                size="18"
                className="text-primaryText"
              />
              <Text
                size="xs"
                weight="md"
                className="max-w-[200px] block truncate"
              >
                {`Week ${dripConfig?.header?.week} - ${dripConfig?.header?.topics}`}
              </Text>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top transition-all top-2/4 left-2/4 -translate-x-2/4 translate-y-5 w-[450px] absolute right-0 mt-2  bg-neutral-100 py-4 rounded shadow-2xl border border-border-300">
              <div className="">
                {status !== "loading" &&
                  dripConfig.navDrips &&
                  dripConfig.navDrips.map((drip) => (
                    <Menu.Item as={Fragment} key={drip.id}>
                      {() => (
                        <Link
                          className={
                            Number(weekId) === drip.id
                              ? "pointer-events-none"
                              : ""
                          }
                          to={`/classroom/${batchSlug}/w/${drip.slug}`}
                        >
                          <div
                            className={cn(
                              "flex-col gap-1 p-4 border-l-4 t-flex transition-colors",
                              Number(weekId) === drip.id
                                ? "border-primary-500 bg-primary-100"
                                : "border-transparent bg-neutral-100 hover:bg-neutral-400"
                            )}
                          >
                            <div className="items-center justify-between t-flex">
                              <div>
                                <Description weight="md" size="xs">
                                  {new Date(drip.unlockOn).toLocaleDateString(
                                    "en-IN",
                                    dateOptions
                                  )}
                                </Description>
                              </div>
                              <div>
                                <Description
                                  weight="md"
                                  size="xs"
                                  className="items-center justify-center t-flex"
                                >
                                  Progress -
                                  <Text
                                    weight="lg"
                                    size="sm"
                                    className="text-success-400 min-w-[40px]"
                                  >
                                    {drip.weekProgress}%
                                  </Text>
                                </Description>
                              </div>
                            </div>
                            <div>
                              <Description
                                weight="md"
                                size="sm"
                                variant="secondary"
                              >
                                {` Week ${drip.week} - ${drip.topics}`}
                              </Description>
                            </div>
                          </div>
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
              </div>
              <Menu.Item
                as={"div"}
                className="items-center justify-center mt-4 t-flex"
              >
                <Link
                  to={`/classroom/${batchSlug}/dashboard`}
                  className="items-center justify-center border-b-2 w-max t-flex group/view border-b-transparent hover:border-primary-500"
                >
                  <Text
                    size="xs"
                    weight="md"
                    className="transition-colors group-hover/view:text-primary-500"
                  >
                    View All Weeks
                  </Text>
                  <IconWrapper
                    className="transition-colors group-hover/view:text-primary-500"
                    icon="dropRight"
                    size="16"
                  />
                </Link>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

export default DripNavigation;
