import { Link } from "react-router-dom";
import {
  OFFERING_ICON_VARIANT,
  offeringTypeIcon,
} from "../../../components/common/constant";
import {
  CTAButton,
  IconWrapper,
  Skeleton,
} from "../../../components/common/import";
import ProblemTitle, {
  EllipseText,
} from "../../../components/explore-problems/components/ProblemTitle";
import {
  difficultyType,
  difficultyTypeColorVariant,
} from "../../../components/explore-problems/helpers/constant";
import { UserBadge } from "../../../components/job-portal/components/ui/Badges";
import { CTALink } from "../../../components/job-portal/components/ui/CTALink";
import { Badge, Description } from "../../user-profile/imports";
import { getCompletionTime } from "../ContestLeaderboard";

export function getProblemStatus(isAccepted, counter) {
  let status;
  if (isAccepted) {
    status = "solved";
  } else if (counter > 0) {
    status = "attempted";
  } else {
    status = "pending";
  }
  return status;
}
export function restructureContestOfferings(data, contestSlug) {
  return (
    data &&
    data.map((offering, index) => ({
      id: offering.id,
      index: (
        <Description weight="md" size="sm">
          {index + 1}
        </Description>
      ),
      title: (
        <div>
          <EllipseText title={offering.problem.title} />
        </div>
      ),

      type: (
        <div className="t-flex">
          <IconWrapper
            icon={offeringTypeIcon[offering.offeringType]}
            style={{
              color: `var(--t-${OFFERING_ICON_VARIANT[offering.offeringType]})`,
            }}
            strokeWidth="1"
            size="22"
          />
        </div>
      ),
      difficulty: (
        <Badge
          size="xs"
          variant="secondary"
          className={`${difficultyTypeColorVariant[offering.difficulty]} w-max`}
          border="normal"
        >
          {difficultyType[offering.difficulty]}
        </Badge>
      ),
      maxScore: (
        <Description weight="md" size="sm">
          {offering.maxScore}
        </Description>
      ),
      status: (
        <Badge
          size="xs"
          variant="secondary"
          className={`${
            difficultyTypeColorVariant[
              getProblemStatus(offering.isAccepted, offering.counter)
            ]
          } w-max capitalize`}
          border="normal"
        >
          {getProblemStatus(offering.isAccepted, offering.counter)}
        </Badge>
      ),
      actions: (
        <div className="items-center t-flex">
          <CTALink
            to={`/contest/${contestSlug}/problems/${offering.slug}`}
            variant="primary"
            size="xs"
            className="shrink-0 whitespace-nowrap"
          >
            Solve Now
          </CTALink>
        </div>
      ),
    }))
  );
}

export function getContestProblemsRoute({ contestSlug, offeringSlug }) {
  return `/contest/${contestSlug}/problems/${offeringSlug}`;
}

export function restructureContestLeaderboardData(
  leaderboardData = [],
  startTime
) {
  const restructuredData = [];
  for (let i = 0; i < leaderboardData.length; i++) {
    const {
      id,
      completionTime,
      scoreDuringContest,
      completionTimeWithPenalty,
      wrongAnswerAttempts,
      rank,
      user: { name, photo, username },
    } = leaderboardData[i];
    const data = {
      id: id,
      rank: (
        <div>
          <Badge
            size="xs"
            weight="semibold"
            variant="secondary"
            className="px-3 bg-neutral-400 w-max "
            border="rounded"
          >
            {rank || "_ _"}
          </Badge>
        </div>
      ),
      user: (
        <div>
          <Link to={`/profile/${username}`} className="group w-max">
            <UserBadge
              extraNode={
                <Description
                  className="group-hover:text-primary-500"
                  variant="primary"
                  size="xs"
                  weight="md"
                >{`@${username}`}</Description>
              }
              title={name}
              size="sm"
              weight="md"
              imageProps={{
                url: photo,
                variant: "circle",
                size: "md",
              }}
            />
          </Link>
        </div>
      ),
      score: (
        <div>
          <Badge
            size="xs"
            weight="semibold"
            variant="secondary"
            className="border-success-700 text-success-400 bg-success-600 w-max "
            border="rounded"
          >
            {`${
              scoreDuringContest
                ? scoreDuringContest.toLocaleString("en-IN")
                : "0"
            } Pts.`}
          </Badge>
        </div>
      ),
      completionTime: (
        <div>
          <Description
            size="sm"
            variant="secondary"
            weight="md"
            className="line-clamp-1"
          >
            {completionTime
              ? getCompletionTime(completionTime, startTime)
              : "_ _"}
          </Description>
        </div>
      ),
      completionTimeWithPenalty: (
        <div>
          <Description
            size="sm"
            variant="secondary"
            weight="md"
            className="line-clamp-1"
          >
            {completionTimeWithPenalty
              ? getCompletionTime(completionTimeWithPenalty, startTime)
              : "_ _"}
          </Description>
        </div>
      ),
      wrongAnswerAttempts: (
        <div>
          <Description
            size="sm"
            variant="secondary"
            weight="md"
            className="line-clamp-1"
          >
            {wrongAnswerAttempts !== null || wrongAnswerAttempts !== undefined
              ? wrongAnswerAttempts
              : "_ _"}
          </Description>
        </div>
      ),
    };
    restructuredData.push(data);
  }

  return restructuredData;
}
export const contestLeaderboardLoadingSkeletonTemplate = {
  id: <Skeleton variant="input" size="full" className="w-8 rounded-full" />,
  rank: <Skeleton variant="input" size="full" className="w-8 rounded-full" />,
  user: (
    <div className="items-center gap-2 t-flex">
      <Skeleton variant="avatar" size="md" />
      <Skeleton variant="input" size="full" className="w-[50%] h-4" />
    </div>
  ),
  score: <Skeleton variant="input" size="full" className="w-20 rounded-full" />,
  completionTime: <Skeleton variant="input" size="full" className="w-20" />,
  completionTimeWithPenalty: (
    <Skeleton variant="input" size="full" className="w-20" />
  ),
  wrongAnswerAttempts: (
    <Skeleton variant="input" size="full" className="w-20" />
  ),
};
