import { cva } from "class-variance-authority";
import React from "react";
import { cn } from "../../helpers/utils";
import IconWrapper from "./IconWrapper";
import { Title } from "./Typography";
import { Link } from "react-router-dom";
import fallbackProfile from "../../../../img/defaultprofile.png";
const badgeVariants = cva(
  [
    "border border-transparent select-none py-1 px-2 t-flex justify-center items-center text-primaryText",
  ],
  {
    variants: {
      variant: {
        primary: ["bg-neutral-400"],
        secondary: [""],
        outline: ["border-primary text-primary"],
      },
      size: {
        xxs: ["text-[10px] py-0.5 px-1"],
        xs: ["text-xs"],
      },
      weight: {
        sm: ["font-normal"],
        md: ["font-medium"],
        semibold: ["font-semibold"],
      },
      border: {
        rounded: ["rounded-full"],
        normal: ["rounded"],
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "xs",
      weight: "md",
    },
  }
);
const Badge = (props) => {
  const {
    variant,
    children,
    size,
    className,
    weight,
    icon,
    iconSize,
    border,
    onRemoveBadge,
    textProps,
    ...restprops
  } = props;
  return (
    <div
      className={cn(
        badgeVariants({ variant, size, weight, border }),
        className
      )}
      {...restprops}
    >
      <span {...textProps}>{children}</span>
      {icon && (
        <div className="transition-colors rounded-full p-[1px] hover:bg-danger hover:text-light">
          <button
            className="items-center justify-center t-flex"
            onClick={onRemoveBadge}
          >
            <IconWrapper icon={icon} size={iconSize} />
          </button>
        </div>
      )}
    </div>
  );
};

const iconBadgeVariants = cva(
  ["rounded-full t-flex justify-center items-center border border-transparent"],
  {
    variants: {
      variant: {
        primary: ["bg-lightHover text-light"],
        outline: ["border-borderColor text-primary"],
      },
      size: {
        sm: ["w-10 h-10"],
      },
    },
  }
);
export const IconBadge = (props) => {
  const { icon, variant, size, iconColor, className, iconAttributes, ...prop } =
    props;
  return (
    <div
      className={cn(iconBadgeVariants({ variant, size }), className)}
      {...prop}
    >
      <span className="items-center justify-center t-flex w-max">
        <IconWrapper icon={icon} stroke={iconColor} {...iconAttributes} />
      </span>
    </div>
  );
};

export const Tag = (props) => {
  const { children, variant, size, className, ...restProps } = props;
  return (
    <div
      className={cn(iconBadgeVariants({ variant, size }), className)}
      {...restProps}
    >
      <span>{children}</span>
    </div>
  );
};

const imageBadgeVariants = cva(["w-full h-full object-cover"], {
  variants: {
    variant: {
      circle: ["rounded-full"],
    },
    size: {
      sm: ["w-6 h-6"],
      md: ["w-8 h-8"],
    },
  },
});
export const ImageBadge = (props) => {
  const { url, alt, className, variant, size } = props;
  return (
    <div className="flex-shrink-0">
      <img
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackProfile;
        }}
        src={url}
        alt={alt}
        className={cn(imageBadgeVariants({ variant, size }), className)}
        loading="lazy"
      />
    </div>
  );
};
export const UserBadge = (props) => {
  const {
    title,
    size,
    textAsLink,
    href,
    imageProps,
    weight,
    extraNode,
    ...restProps
  } = props;
  return (
    <div className="items-center gap-2 t-flex w-max">
      <ImageBadge {...imageProps} alt={title} />
      <div>
        {textAsLink ? (
          <Link to={href} className="group">
            <Title level="h6" size={size} {...restProps} weight={weight}>
              {title}
            </Title>
            {extraNode}
          </Link>
        ) : (
          <>
            <Title level="h6" size={size} {...restProps} weight={weight}>
              {title}
            </Title>
            {extraNode}
          </>
        )}
      </div>
    </div>
  );
};
export default Badge;
