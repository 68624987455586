import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

export default function (dateStr) {
  if (dateStr == undefined || dateStr == null) return "";
  const date = new Date(dateStr);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  let dayStr = day.toString();
  if (day > 3 && day < 21) {
    dayStr += "th";
  } else if (day % 10 === 1) {
    dayStr += "st";
  } else if (day % 10 === 2) {
    dayStr += "nd";
  } else if (day % 10 === 3) {
    dayStr += "rd";
  } else {
    dayStr += "th";
  }

  return `${dayStr} ${month}`;
}

export function dateToAgo(date) {
  if (!date) return;
  return timeAgo.format(new Date(date));
}
export function nthConverter(d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
export function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}min`;
  } else if (minutes > 0) {
    return `${minutes}min`;
  } else {
    return `${seconds}sec`;
  }
}
