import { InfoIconWithTooltip } from "../import";

export const weekDripTabsData = [
  {
    label: "Lectures & Live",
    id: 0,
    paramsValue: "lectures",
  },
  {
    label: "Homework",
    id: 1,
    paramsValue: "homework",
  },
  {
    label: "Assignments",
    id: 2,
    paramsValue: "assignments",
  },
];

export const DEFAULT_ACTIVE_TAB = weekDripTabsData[0].paramsValue;
export const HOMEWORK_TAB = weekDripTabsData[1].paramsValue;
export const ASSIGNMENT_TAB = weekDripTabsData[2].paramsValue;
export const weekProblemsHeader = [
  {
    id: 0,
    label: "Status",
    dataIndex: "status",
    size: "7%",
  },
  {
    id: 1,
    label: "Name of Problem",
    dataIndex: "title",
    size: "350px",
  },
  {
    id: 2,
    label: "Type",
    dataIndex: "type",
    size: "10%",
  },
  {
    id: 4,
    label: "Difficulty",
    dataIndex: "difficulty",
    size: "12%",
  },
  {
    id: 5,
    label: "Your Score",
    dataIndex: "score",
    size: "12%",
  },
  {
    id: 6,
    label: (
      <InfoIconWithTooltip
        text="SolvedBy"
        tooltipText="The SolvedBy count gets updated every 30 minutes."
      />
    ),
    dataIndex: "solvedBy",
    size: "10%",
  },
  {
    id: 7,
    label: "Actions",
    dataIndex: "actions",
    size: "10%",
  },
];

export const weekLecturesHeader = [
  {
    id: 0,
    label: "Status",
    dataIndex: "status",
    size: "7%",
  },
  {
    id: 1,
    label: "Name of Lecture",
    dataIndex: "title",
    size: "350px",
  },
  {
    id: 2,
    label: "Type",
    dataIndex: "type",
    size: "10%",
  },
  {
    id: 3,
    label: "Duration",
    dataIndex: "duration",
    size: "12%",
  },
];
export const liveSessionHeader = [
  {
    id: 0,
    label: "Name of Live Session",
    dataIndex: "title",
    size: "350px",
  },
  {
    id: 1,
    label: "Type",
    dataIndex: "type",
    size: "8%",
  },
  {
    id: 2,
    label: "Timing",
    dataIndex: "timing",
    size: "27%",
  },
  {
    id: 3,
    label: "Duration",
    dataIndex: "duration",
    size: "12%",
  },
  {
    id: 4,
    label: "Actions",
    dataIndex: "actions",
    size: "15%",
  },
];

export const currentTabTableHeader = {
  [DEFAULT_ACTIVE_TAB]: weekLecturesHeader,
  [HOMEWORK_TAB]: weekProblemsHeader,
  [ASSIGNMENT_TAB]: weekProblemsHeader,
};
const LIVE_RESOURCE_TYPE = "live";
const HOMEWORK_RESOURCE_TYPE = "homework";
const ASSIGNMENT_RESOURCE_TYPE = "assignment";
const LECTURE_RESOURCE_TYPE = "lectures";
export const resourceTypes = {
  [DEFAULT_ACTIVE_TAB]: [LECTURE_RESOURCE_TYPE, LIVE_RESOURCE_TYPE],
  [HOMEWORK_TAB]: [HOMEWORK_RESOURCE_TYPE],
  [ASSIGNMENT_TAB]: [ASSIGNMENT_RESOURCE_TYPE],
};
const PARAMS_TABS = {
  LECTURE_KEY: "lectures",
  ASSIGNMENT_KEY: "assignments",
  HOMEWORK_KEY: "homework",
  LIVE_KEY: "live",
};
export const VALID_PARAMS = Object.values(PARAMS_TABS);
export const BOOKMARK_WEEK_DRIP_TYPE = "weekDrips";
