import { forwardRef, useEffect } from "react";
import { handlePopupOutsideClick } from "../hooks/usePopupHandler";
import useDisableScroll from "../../../helper/useDisableScroll";

const PopupLayout = forwardRef(
  ({ isPopupActive, setIsPopupActive, children, modelKey }, ref) => {
    useEffect(() => {
      let TIMEOUT_ID;
      TIMEOUT_ID = setTimeout(() => {
        ref.current &&
          ref.current.setAttribute(
            "aria-expanded",
            modelKey ? isPopupActive[modelKey] : isPopupActive
          );
      });
      return () => clearTimeout(TIMEOUT_ID);
    }, [modelKey ? isPopupActive[modelKey] : isPopupActive]);

    useEffect(() => {
      document.addEventListener("click", (e) =>
        handlePopupOutsideClick(e, ref, setIsPopupActive, modelKey)
      );
      return () =>
        document.removeEventListener("click", (e) =>
          handlePopupOutsideClick(e, ref, setIsPopupActive, modelKey)
        );
    }, []);
    useDisableScroll(modelKey ? isPopupActive[modelKey] : isPopupActive);
    return (modelKey ? isPopupActive[modelKey] : isPopupActive) && children;
  }
);

export default PopupLayout;
