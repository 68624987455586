import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { BOOKMARK_STATUS } from "../../components/job-portal/helpers/constant";
import { updateExistingJobData } from "../../components/job-portal/hooks/updateExistingJobData";
import { axiosClient } from "../../utils/axiosClient";

export const fetchAllJobs = createAsyncThunk(
  "fetch/allJobs",
  async ({
    jobTypes,
    jobLevels,
    sort,
    page,
    resourceType,
    search,
    locations,
    limit,
    batchId,
    locationTypes,
  }) => {
    const data = await axiosClient.post(
      `/classroom/${batchId}/jobs/all`,
      {
        jobTypes,
        jobLevels,
        locationTypes,
        locations,
      },
      {
        params: {
          resourceType,
          search,
          sort,
          page: page || 1,
          limit,
        },
      }
    );
    return data;
  }
);

export const updateBookmarkOrAppliedStatus = createAsyncThunk(
  "update/bookmarkStatus",
  async ({ jobId, updateStatus, resourceType, batchId }) => {
    const data = await axiosClient.put(
      `/classroom/${batchId}/job/${jobId}/jobLedgerStatus/${resourceType}/update`,
      {
        updateStatus,
      }
    );
    return data;
  }
);

const allJobsState = {
  jobsData: [],
  totalEntries: 0,
};
const jobPortalSlice = createSlice({
  name: "jobPortalSlice",
  initialState: {
    allJobs: {
      data: allJobsState,
      status: "idle",
      error: null,
    },
    bookmark: {
      data: null,
      status: "idle",
      error: null,
    },
  },
  reducers: {
    resetAllJobsState: (state) => {
      state.allJobs.data = allJobsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllJobs.pending, (state) => {
        state.allJobs.status = "loading";
      })
      .addCase(fetchAllJobs.fulfilled, (state, action) => {
        const currentPage = action.meta.arg.page;
        const data = action.payload.data.jobsData;
        state.allJobs.data.jobsData =
          currentPage !== 1 ? [...state.allJobs.data.jobsData, ...data] : data;
        state.allJobs.data.totalEntries = action.payload.data.totalEntries;
        state.allJobs.status = "idle";
      })
      .addCase(fetchAllJobs.rejected, (state, action) => {
        state.allJobs.status = "error";
        state.allJobs.error = action.error;
        toast.error(action.error.message);
      })
      .addCase(updateBookmarkOrAppliedStatus.pending, (state) => {
        state.bookmark.status = "loading";
      })
      .addCase(updateBookmarkOrAppliedStatus.fulfilled, (state, action) => {
        const resourceType = action.meta.arg.resourceType;

        if (
          action.payload.data.status === "ok" &&
          resourceType === BOOKMARK_STATUS
        ) {
          const existingData = state.allJobs.data.jobsData;
          const updatedStatus = action.meta.arg.updateStatus;
          const targetId = action.meta.arg.jobId;
          state.allJobs.data.jobsData = updateExistingJobData(
            existingData,
            targetId,
            updatedStatus
          );
        }
        state.bookmark.data = action.payload.data;
        state.bookmark.status = "idle";
      })
      .addCase(updateBookmarkOrAppliedStatus.rejected, (state, action) => {
        state.bookmark.status = "error";
        state.bookmark.error = action.error;
        toast.error(action.error.message);
      });
  },
});

export default jobPortalSlice.reducer;
export const { resetAllJobsState } = jobPortalSlice.actions;
