import React, { Fragment, useState } from "react";
import PopupLayout from "../../../admin/layout/PopupLayout";
import IconWrapper from "./IconWrapper";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import ThemeProvider from "../../../../providers/ThemeProvider";
import { cn } from "../../helpers/utils";
const Modal = (props) => {
  const {
    ModalContent,
    setIsModalOpen,
    modalTriggerRef,
    isModalOpen,
    modalContentProps,
    showCloseButton,
    closeOnOutsideClick,
    onModalClose,
  } = props;

  function modalCloseHandler() {
    setIsModalOpen(false);
    if (typeof onModalClose === "function") {
      onModalClose();
    }
  }

  return (
    <>
      <PopupLayout
        ref={modalTriggerRef}
        isPopupActive={isModalOpen}
        setIsPopupActive={setIsModalOpen}
      >
        <div
          className="fixed inset-0 bg-black opacity-60 z-[998]"
          onClick={closeOnOutsideClick ? modalCloseHandler : null}
        ></div>
        <div className="group-aria-[expanded=true]:scale-100 scale-75 transition-transform fixed left-[50%] top-[50%] z-[999] -translate-x-[50%] -translate-y-[50%] max-md:w-11/12">
          <ModalContent
            setIsModalOpen={setIsModalOpen}
            {...modalContentProps}
          />
        </div>
        {showCloseButton && (
          <div className="absolute z-[1000] right-10 top-10">
            <button
              className="p-1 hover:bg-[#282828] rounded-full"
              onClick={modalCloseHandler}
            >
              <IconWrapper icon="close" size="35" className="text-white" />
            </button>
          </div>
        )}
      </PopupLayout>
    </>
  );
};

const DialogModal = (props) => {
  const {
    isOpen,
    setIsOpen,
    type = "default",
    overlayClassName,
    isDisabled,
    isDispatcher,
    dispatchFunction,
    children,
    hasTheme,
    wrapperClassName,
  } = props;
  const siteThemeStatus = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isDarkMode
  );
  const ModalType = {
    sidebar: {
      overlayEnterTranisition: "ease-in duration-500",
      overlayLeaveTranisition: "ease-out duration-500",
      contentWrapperClassName: "justify-start p-0 w-max",
      enterFrom: "-translate-x-full",
      enterTo: "translate-x-0",
      leaveFrom: "translate-x-0",
      leaveTo: "-translate-x-full",
      transition: "ease-[cubic-bezier(.4,0,.2,1)] duration-300 delay-75",
    },
    default: {
      overlayEnterTranisition: "ease-out duration-150",
      overlayLeaveTranisition: "ease-in duration-150",
      contentWrapperClassName:
        "sm:items-center justify-center items-end sm:p-0",
      enterFrom: "scale-95 opacity-0",
      enterTo: "scale-100 opacity-100",
      leaveFrom: "scale-100 opacity-100",
      leaveTo: "scale-95 opacity-0",
      transition: "duration-200 ease-out",
    },
  };
  function closeHandler() {
    if (isDisabled) return;
    if (isDispatcher) {
      dispatchFunction();
    } else {
      setIsOpen(false);
    }
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        onClose={closeHandler}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <Transition.Child
          as={Fragment}
          enter={ModalType[type].overlayEnterTranisition}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={ModalType[type].overlayLeaveTranisition}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className={cn(
              "fixed  inset-0 bg-black bg-opacity-50 z-[998]",
              overlayClassName
            )}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-[998] w-screen overflow-y-auto">
          <div
            className={cn(
              "t-flex  min-h-full p-4 ",
              ModalType[type].contentWrapperClassName
            )}
          >
            <Transition.Child
              as={Fragment}
              enter={ModalType[type].transition}
              enterFrom={ModalType[type].enterFrom}
              enterTo={ModalType[type].enterTo}
              leave={ModalType[type].transition}
              leaveFrom={ModalType[type].leaveFrom}
              leaveTo={ModalType[type].leaveTo}
            >
              <Dialog.Panel
                className={cn(
                  `z-[999] w-full relative h-max`,
                  wrapperClassName
                )}
              >
                <ThemeProvider
                  className="items-center min-h-max t-flex"
                  theme={
                    hasTheme
                      ? !siteThemeStatus
                        ? "white-theme"
                        : "dark"
                      : "white-theme"
                  }
                >
                  {children}
                </ThemeProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>{" "}
      </Dialog>
    </Transition>
  );
};

const DialogContent = (props) => {
  const { children, className } = props;
  return (
    <div
      className={cn(
        "w-full p-4 border rounded-lg shadow-lg bg-neutral-100 border-border-500",
        className
      )}
    >
      {children}
    </div>
  );
};
const CloseDialogButton = (props) => {
  const { close, className, ...restProps } = props;
  return (
    <button
      {...restProps}
      onClick={close}
      className={cn(
        "items-center p-1.5 rounded-full transition-colors duration-500 t-flex hover:bg-neutral-500",
        className
      )}
    >
      <IconWrapper icon="close" size="18" className="text-primaryText" />
    </button>
  );
};
DialogModal.Content = DialogContent;
DialogModal.CloseButton = CloseDialogButton;
export { DialogModal };
export default Modal;
