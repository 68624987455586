export const liveFilterDropdown = [
  {
    id: 2,
    title: "Time period",
    isDate: true,
    filterBasis: "timePeriod",
    options: [
      {
        id: "startDate#1",
        label: "From :",
        name: "startDate",
        dataLabel: "timePeriod",
      },
      {
        id: "endDate#1",
        label: "To :",
        name: "endDate",
        dataLabel: "timePeriod",
      },
    ],
  },
];

export const tableHeader = [
  {
    label: "Title",
    dataIndex: "title",
    id: 1,
  },
  {
    label: "Start Time",
    dataIndex: "startTime",
    id: 2,
  },
  {
    label: "End Time",
    dataIndex: "endTime",
    id: 3,
  },
  {
    label: "Action",
    dataIndex: "action",
    id: 4,
  },
];

export const toggleData = [
  {
    label: "Events",
    uid: "events",
    value: "events",
  },
  {
    label: "Live Sessions",
    uid: "liveSessions",
    value: "liveSession",
  },
];

export const TYPE_LIVE = "Live";
export const TYPE_EVENT = "Event";
export const EVENT_TAB = "events";
export const LIVE_TAB = "events";

export const CONFIRM_TEXT = "Are you sure you want to DELETE this entry?";

export const actionDropdownData = [
  {
    id: 1,
    label: "Event Details",
    onClick: (setState, key) =>
      setState((prev) => {
        return { ...prev, [key]: true };
      }),
    key: "eventDetailsModel",
  },
  {
    id: 2,
    label: "End Stream",
    onClick: (setState, key) =>
      setState((prev) => {
        return { ...prev, [key]: true };
      }),
    key: "streamEndModel",
  },
];
