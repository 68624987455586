import React from "react";
import { Skeleton } from "../imports";

const SearchResultSkeleton = () => {
  return new Array(5).fill(0).map((_, i) => (
    <div key={i} className="w-full p-2 py-3 rounded ">
      <div className="items-start gap-2 t-flex">
        <div className="mt-1">
          <Skeleton variant="avatar" size="sm" />
        </div>

        <div className="flex-col w-full gap-1 t-flex">
          <Skeleton variant="input" size="full" className="w-[90%] h-4" />
          <Skeleton variant="input" size="full" className="w-[60%] h-4" />
        </div>
      </div>
    </div>
  ));
};

export default SearchResultSkeleton;
