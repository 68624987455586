import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axiosClient";
import { setBatchConfigState } from "./appConfigSlice";
import { restructureSearchResults } from "../../components/classroomHeader/helpers/restructureSearchResults";

export const setBatchConfig = createAsyncThunk(
  "fetch/batchConfig",
  async ({ batchId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosClient.get(
        `/classroom/getBatchConfig/${batchId}`
      );
      dispatch(
        setBatchConfigState({
          currentBatchResources: response.data.batchResources,
        })
      );
      return response;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const fetchUnacknowledgeBadges = createAsyncThunk(
  "fetch/unacknowledgeBadges",
  async () => {
    const response = await axiosClient(`/classroom/badges/unacknowledged`);
    return response;
  }
);
export const fetchSearchResults = createAsyncThunk(
  "fetch/search",
  async ({ batchId, offeringTypes, page, search }) => {
    const response = await axiosClient.post(
      `/classroom/classes/${batchId}/offering/search`,
      {
        offeringTypes,
      },
      {
        params: {
          page: 1,
          search,
        },
      }
    );
    return response;
  }
);
const searchResultState = { status: "idle", error: null, data: [] };
const batchConfigSlice = createSlice({
  name: "batchConfigSlice",
  initialState: {
    data: null,
    status: "loading",
    error: null,
    searchResults: searchResultState,
    unacknowledgeBadges: {
      data: [],
      status: "idle",
      error: null,
    },
  },

  reducers: {
    cleanPrevBatchConfig: (state) => {
      state.data = null;
      state.error = null;
    },

    setCertificate: (state, action) => {
      state.data.certificate.certificateData = action.payload;
    },
    resetSearchResult: (state) => {
      state.searchResults = searchResultState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(setBatchConfig.pending, (state, action) => {
        state.error = null;
        state.status = "loading";
      })
      .addCase(setBatchConfig.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(setBatchConfig.rejected, (state, action) => {
        state.status = "error";
        state.error = {
          status: action.meta.status,
          message: action.meta.message,
          name: action.meta.name,
        };
      })
      .addCase(fetchSearchResults.pending, (state, action) => {
        state.searchResults.status = "loading";
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.searchResults.data = restructureSearchResults(
          action.payload.data.offerings
        );
        if (action.payload.data.offerings.length === 0) {
          state.searchResults.error = "No Search Results Found.";
        }
        state.searchResults.status = "idle";
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.searchResults.status = "error";
      })
      .addCase(fetchUnacknowledgeBadges.pending, (state, action) => {
        state.unacknowledgeBadges.status = "loading";
      })
      .addCase(fetchUnacknowledgeBadges.fulfilled, (state, action) => {
        state.unacknowledgeBadges.data = action.payload;
        state.unacknowledgeBadges.status = "idle";
      })
      .addCase(fetchUnacknowledgeBadges.rejected, (state, action) => {
        state.unacknowledgeBadges.status = "error";
        state.unacknowledgeBadges.error = action.error.message;
      });
  },
});

export default batchConfigSlice.reducer;

export const { cleanPrevBatchConfig, setCertificate, resetSearchResult } =
  batchConfigSlice.actions;
