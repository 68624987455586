import { useDispatch, useSelector } from "react-redux";
import { updateRedirectionUrl } from "../redux/slices/appConfigSlice";
import { useNavigate } from "react-router-dom";

function byPassLoginCheck(redirectUrl) {
  //add all bypass conditions here
  if (
    redirectUrl.includes("/checkout") &&
    !redirectUrl.includes("/checkout/status")
  )
    return true;

  return false;
}

function useLoginAndRedirect() {
  const backendUrl = process.env.REACT_APP_SERVER_BASE_URL;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector(
    (state) => state.persistedReducer.appConfigReducer.isLoggedIn
  );

  return (redirectUrl) => {
    return function () {
      if (isLoggedIn || byPassLoginCheck(redirectUrl)) {
        navigate(redirectUrl);
      } else {
        dispatch(updateRedirectionUrl(redirectUrl));
        window.location.replace(`${backendUrl}/connect/google`);
      }
    };
  };
}

export default useLoginAndRedirect;
