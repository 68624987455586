import React, { forwardRef, useEffect, useState } from "react";
import { HiBars3 } from "react-icons/hi2";
import { IoIosLogOut, IoMdClose } from "react-icons/io";
import { IoDiamondSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import useLoginAndRedirect from "../../../helper/useLoginAndRedirect";
import crownImg from "../../../img/LandingPageV3/crown.svg";
import googleIcon from "../../../img/LandingPageV3/google-icon.png";
import { logout } from "../../../redux/slices/appConfigSlice";
import { resetPersistedValues } from "../../../redux/slices/quickCompilerSlice";
import {
  KEY_JWT,
  getItem,
  removeItem,
} from "../../../utils/localStorageManager";
import UserNavigationMenu from "../../UserNavigationMenu";
import { Skeleton } from "../../common/import";
import CompanyLogo from "../../job-portal/companyLogo";
import { Banner, FloatingButton } from "../global/global";
import defaultProfileImage from "../../../img/defaultprofile.png";
import "./Header.scss";
import GoogleSignin from "../../login/GoogleSignin";
import { CTALink } from "../../job-portal/components/ui/CTALink";
import { cn } from "../../classroomHeader/imports";
import HomeLayout from "../../../layouts/HomeLayout";
const backendUrl = process.env.REACT_APP_SERVER_BASE_URL;
const ResponsiveNavbar = ({ isLoggedIn, setIsActive }) => {
  const user = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.user
  );
  const dispatch = useDispatch();

  function handleLogout() {
    removeItem(KEY_JWT);
    dispatch(logout());
    dispatch(resetPersistedValues());
    setIsActive(false);
  }

  return (
    <>
      <ul className={`hamburger-menu-items flex`}>
        <NavigationMenu setIsActive={setIsActive} />
        <li className="flex">
          <Link
            aria-label="events"
            to="/events"
            className="flex events__btn"
            onClick={() => setIsActive(false)}
          >
            <IoDiamondSharp />
            <span className="link">Events</span>
            <p className="new__tag">New</p>
          </Link>
        </li>
        {isLoggedIn ? (
          <>
            <li>
              <Link
                aria-label="redirect to classroom page"
                to="/classroom"
                onClick={() => setIsActive(false)}
                className="flex classroom-btn"
              >
                <img src={crownImg} alt="" />
                Classroom
              </Link>
            </li>
            <li>
              <Link
                aria-label="redirect to profile page"
                to="/account"
                onClick={() => setIsActive(false)}
                className="flex"
              >
                <img
                  src={user?.photo ? user.photo : defaultProfileImage}
                  alt="profile"
                />
                My Profile
              </Link>
            </li>

            <li onClick={handleLogout}>
              <Link aria-label="logout-button" className="flex logout-btn">
                <IoIosLogOut size={18} />
                Logout
              </Link>
            </li>
          </>
        ) : (
          <li>
            <a
              href={`${backendUrl}/connect/google`}
              aria-label="login-button"
              className="flex login-btn"
            >
              <img src={googleIcon} alt="google" />
              Login with Google
            </a>
          </li>
        )}
      </ul>
    </>
  );
};
const NavigationMenu = ({ setIsActive }) => {
  const location = useLocation();
  function modelCloseHandler() {
    setIsActive && setIsActive(false);
  }
  return (
    <>
      {location.pathname === "/" && (
        <>
          <div className="navigation-menu-wrapper">
            <ul className="flex">
              <li>
                <a
                  aria-label="scroll to testimonials"
                  href="#testimonials"
                  onClick={modelCloseHandler}
                >
                  <span className="link">Testimonials</span>
                </a>
              </li>
              <li>
                <a
                  aria-label="scroll to curriculum"
                  href="#curriculum"
                  onClick={modelCloseHandler}
                >
                  <span className="link">Curriculum</span>
                </a>
              </li>
              <li>
                <a
                  aria-label="scroll to faqs"
                  href="#faq"
                  onClick={modelCloseHandler}
                >
                  <span className="link">FAQs</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="underline"></div>
        </>
      )}
    </>
  );
};
// const Header = (props, ref) => {
//   const { usernameSlug } = useParams();
//   const { pathname } = useLocation();
//   const BLACKLIST_ROUTES =
//     pathname.includes("/account") || pathname.includes("/profile");
//   const redirectHandler = useLoginAndRedirect();
//   const [isNavbarActive, setIsNavbarActive] = useState(false);
//   const [isSpacerActve, setIsSpaceActive] = useState(false);
//   const isLoggedIn = useSelector(
//     (state) => state?.persistedReducer?.appConfigReducer?.isLoggedIn
//   );

//   const upcomingBatch = useSelector(
//     (state) => state?.persistedReducer?.appConfigReducer?.upcomingBatch
//   );

//   const user = useSelector(
//     (state) => state?.persistedReducer?.appConfigReducer?.user
//   );
//   function scrollHandler() {
//     if (window.scrollY > 60) {
//       setIsSpaceActive(true);
//     } else {
//       setIsSpaceActive(false);
//     }
//   }
//   useEffect(() => {
//     if (isNavbarActive) {
//       document.body.setAttribute("data-scroll-lock", true);
//     } else document.body.setAttribute("data-scroll-lock", false);
//     return () => document.body.setAttribute("data-scroll-lock", false);
//   }, [isNavbarActive]);
//   useEffect(() => {
//     window.addEventListener("scroll", scrollHandler);
//     return () => window.removeEventListener("scroll", scrollHandler);
//   }, []);

//   return (
//     <>
//       {isSpacerActve && (
//         <div
//           style={{
//             height: ref?.current?.scrollHeight || 0 + 50,
//           }}
//         ></div>
//       )}
//       <div className={isSpacerActve ? "navbar-fixed" : ""}>
//         {upcomingBatch && upcomingBatch?.bannerText && !BLACKLIST_ROUTES && (
//           <Banner
//             ref={ref}
//             bannerText={upcomingBatch?.bannerText}
//             countdown={upcomingBatch?.discountEndCountdown}
//           />
//         )}

//         <div
//           className={`header-wrapper ${
//             usernameSlug ? "dark-theme-header" : ""
//           }`}
//         >
//           <header className="flex container-wrapper">
//             <div className="flex navbar-left-side">
//               <div className="company-logo">
//                 <Link to="/" aria-label="redirect to home page">
//                   <CompanyLogo islight={!usernameSlug} />
//                 </Link>
//               </div>
//               <NavigationMenu />
//             </div>
//             <nav>
//               <ul className="flex nav-items">
//                 <li className="flex">
//                   <Link
//                     aria-label="events"
//                     to="/events"
//                     className="events__btn"
//                   >
//                     <span className="link">Events</span>
//                     <p className="new__tag">New</p>
//                   </Link>
//                 </li>
//                 {isLoggedIn && (
//                   <>
//                     <li>
//                       <NavLink
//                         to="/classroom"
//                         aria-label="redirect to classroom page"
//                         className="flex classroom-btn"
//                       >
//                         <img src={crownImg} alt="crown" />
//                         Classroom
//                       </NavLink>
//                     </li>
//                     <li>
//                       {user ? (
//                         <UserNavigationMenu user={user} />
//                       ) : (
//                         <Skeleton variant="avatar" className="w-8 h-8" />
//                       )}
//                     </li>
//                   </>
//                 )}

//                 {!upcomingBatch || upcomingBatch.length == 0 ? (
//                   <li className="flex">
//                     <Link
//                       role="button"
//                       className="primary-btn enroll-now-btn"
//                       to="/"
//                     >
//                       Enrollment full
//                     </Link>
//                   </li>
//                 ) : isLoggedIn && upcomingBatch?.isUserEnrolled ? (
//                   <li>
//                     <Link
//                       role="button"
//                       className="primary-btn enroll-now-btn"
//                       to={"/classroom"}
//                     >
//                       Already Enrolled
//                     </Link>
//                   </li>
//                 ) : (
//                   <li
//                     className="flex"
//                     onClick={redirectHandler(
//                       `/checkout/${upcomingBatch?.slug}`
//                     )}
//                   >
//                     <button
//                       role="button"
//                       className="primary-btn enroll-now-btn"
//                     >
//                       Enroll Now
//                     </button>
//                   </li>
//                 )}
//                 {!isLoggedIn && (
//                   <li>
//                     <a
//                       href={`${backendUrl}/connect/google`}
//                       data-button="login"
//                       role="button"
//                       className="flex login-btn"
//                     >
//                       <img src={googleIcon} alt="google" />
//                       Login with Google
//                     </a>
//                   </li>
//                 )}
//               </ul>
//               <div className="hamburger-menu-button">
//                 <button onClick={() => setIsNavbarActive(!isNavbarActive)}>
//                   {isNavbarActive ? (
//                     <IoMdClose size={30} />
//                   ) : (
//                     <HiBars3 size={30} />
//                   )}
//                 </button>
//               </div>
//             </nav>
//           </header>
//           <div
//             className={`responsive-navbar-wrapper flex ${
//               isNavbarActive ? "navbar-active" : ""
//             }`}
//           >
//             <ResponsiveNavbar
//               setIsActive={setIsNavbarActive}
//               isActive={isNavbarActive}
//               isLoggedIn={isLoggedIn}
//             />
//             <div
//               className="nav-overlay"
//               onClick={() => setIsNavbarActive(false)}
//             ></div>
//           </div>
//         </div>
//       </div>
//       <FloatingButton />
//     </>
//   );
// };

const Header = () => {
  const { usernameSlug } = useParams();
  const location = useLocation();
  const nonLoggedinRoutes =
    usernameSlug ||
    location.pathname.includes("/checkout") ||
    location.pathname.includes("/login");
  const isLoggedIn = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.isLoggedIn
  );
  const user = useSelector(
    (state) => state?.persistedReducer?.appConfigReducer?.user
  );
  return (
    <div
      className={cn(
        "sticky z-50 py-3 bg-white top-0 flex items-center  min-h-[64px] shadow-[0px_2px_4px_rgba(0,_0,_0,_.15)]",
        usernameSlug && "bg-neutral-400"
      )}
    >
      <HomeLayout>
        <header className="flex items-center justify-between">
          <div className="flex items-center gap-10">
            <Link
              to={
                nonLoggedinRoutes
                  ? process.env.REACT_APP_LANDING_PAGE_URL
                  : "/classroom"
              }
              className="shrink-0"
            >
              <CompanyLogo islight={!usernameSlug} />
            </Link>
          </div>
          {!isLoggedIn && (
            <div className="flex items-center">
              <GoogleSignin isDarkTheme={usernameSlug} />
            </div>
          )}
          {isLoggedIn && user && (
            <div className="flex items-center gap-8 max-[475px]:gap-4">
              <CTALink
                to={"/classroom"}
                icon="redirect2"
                variant="outline"
                className={cn(
                  "flex-row-reverse px-4 py-2 rounded-md gap-1 text-sm h-10  text-[#18181B] border-[#18181B]  hover:!bg-black hover:!text-white",
                  usernameSlug &&
                    "text-slate-50 border-zinc-600 hover:!bg-white hover:!text-[#18181B]"
                )}
                iconProps={{ size: 20 }}
              >
                My Classroom
              </CTALink>
              <UserNavigationMenu user={user} />
            </div>
          )}
        </header>
      </HomeLayout>
    </div>
  );
};
export default Header;
export { Banner };
