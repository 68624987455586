import React from "react";
import { useSelector } from "react-redux";
import WelcomeHeader from "../../components/job-portal/layouts/WelcomeHeader";
import HomeLayout from "../../layouts/HomeLayout";
import { SpinnerLoadingPage } from "../contest/components/ContestLoading";
import ErrorContent from "../contest/components/ErrorContent";
import {
  EnrolledCourseCard,
  UpcomingCourseCard,
} from "./components/CourseCard";
import NotEnrolled from "./components/NotEnrolled";

const Classroom = () => {
  const upcomingCourses = useSelector(
    (state) => state.persistedReducer.appConfigReducer.upcomingBatches
  );
  const enrolledBatches = useSelector(
    (state) => state.persistedReducer.appConfigReducer.enrolledBatches
  );

  const appConfigStatus = useSelector(
    (state) => state.persistedReducer?.appConfigReducer?.status
  );
  const appConfigError = useSelector(
    (state) => state.persistedReducer?.appConfigReducer?.error
  );
  return (
    <div className="min-h-screen bg-neutral-100">
      <WelcomeHeader />
      {appConfigStatus === "loading" && <SpinnerLoadingPage />}
      {appConfigError && <ErrorContent error={appConfigError} />}
      {appConfigStatus === "idle" && (
        <HomeLayout>
          <div className="py-8">
            {enrolledBatches && enrolledBatches.length === 0 && <NotEnrolled />}
            {enrolledBatches && enrolledBatches.length > 0 && (
              <section className="mb-8">
                <div className="mb-3">
                  <h2 className="text-lg font-bold text-primaryText">
                    My Courses
                  </h2>
                </div>
                <div className="grid gap-6 grid-cols-[repeat(auto-fill,_minmax(320px,1fr))]">
                  {enrolledBatches.map((enrollment) => {
                    return (
                      <EnrolledCourseCard {...enrollment} key={enrollment.id} />
                    );
                  })}
                </div>
              </section>
            )}
            {upcomingCourses && upcomingCourses.length > 0 && (
              <section>
                <div className="mb-3">
                  <h2 className="text-lg font-bold text-primaryText">
                    {`Checkout ${
                      enrolledBatches.length > 0 ? "more" : "our"
                    } courses`}
                  </h2>
                </div>
                <div className="grid gap-6 grid-cols-[repeat(auto-fill,_minmax(320px,1fr))]">
                  {upcomingCourses.map((batch) => (
                    <UpcomingCourseCard {...batch} key={batch.slug} />
                  ))}
                </div>
              </section>
            )}
          </div>
        </HomeLayout>
      )}
    </div>
  );
};

export default Classroom;
