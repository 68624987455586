import React from "react";
import { HEADER_HEIGHT } from "../../../components/job-portal/layouts/ClassroomLayout";
import errorImage from "../../../img/api-error.png";
import { Description, Title } from "../../user-profile/imports";
import { cn } from "../../../components/classroomHeader/imports";
const ErrorContent = (props) => {
  const { error, className } = props;
  let errorName;
  if (error.name === "ForbiddenError") {
    errorName = "Forbidden Error";
  } else {
    errorName = error.name;
  }

  return (
    <div
      className={cn(
        `h-[calc(100vh-${HEADER_HEIGHT}px)] t-flex flex-col justify-center items-center gap-2`,
        className
      )}
    >
      <div>
        <img className="w-80 t-flex" src={errorImage} alt="error" />
      </div>
      <div className="flex-col items-center justify-center gap-0.5 t-flex">
        <Title className="text-danger-400" weight="bold" size="2xl">
          {errorName || "Oops! Error Occurred"}
        </Title>
        <Description size="md" weight="md" variant="secondary">
          {error.message || "Something went wrong!"}
        </Description>
      </div>
    </div>
  );
};

export default ErrorContent;
