export const offeringTypeIcon = {
  mcq: "mcq",
  problem: "coding",
  lecture: "lecture",
  recording: "recording",
  live: "live",
  externalProblem: "redirect",
};
export const OFFERING_ICON_VARIANT = {
  problem: "primary-500",
  externalProblem: "primary-500",
  mcq: "secondary-500",
  lecture: "teal-500",
  recording: "danger-400",
  live: "danger-400",
};

export const BATCH_TYPE_SELF_PACED = "selfPaced";
export const BATCH_TYPE_DRIP_WISE = "dripWise";
